export const height_xxs = "16px";
export const height_xs = "24px";
export const height_s = "32px";
export const height_m = "40px";
export const height_l = "48px";
export const height_xl = "56px";
export const height_xxl = "72px";
export const height_big = "120px";

export const height = {
  xxs: "16px",
  xs: "24px",
  s: "32px",
  m: "40px",
  l: "48px",
  xl: "56px",
  xxl: "72px",
  big: "120px",
};
