import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import * as COLOR from "./../color";
import * as CONST from "./../const";

const OverlayContainer = styled.div<{
  $zIndex: number;
  $color: OverlayColorType;
  tabIndex: number;
}>`
  background: ${(props) =>
    props.$color === "light"
      ? COLOR.modal_background_light
      : COLOR.modal_background_dark};

  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
  z-index: ${(props) => props.$zIndex};
`;

const OverlayBody = styled.div<{ $zIndex: number }>`
  z-index: ${(props) => props.$zIndex + 1};
  height: 100%;
`;

type OverlayColorType = "light" | "dark";

export interface IOverlay {
  /** The content that will be displayed above the layer */
  children: React.ReactNode;
  /** Optional, but if given it will set the z-index value of this layer */
  zIndex?: number;
  /** Two values are possible (dark or light) */
  color?: OverlayColorType;
  /** Action to trigger when you click on the layer */
  onClick?: () => void;
  /** Action to trigger when you press the 'Escape' key */
  onEscape?: () => void;
}

export const Overlay = ({
  children,
  zIndex = CONST.zindex_lowest_backdrop,
  onClick,
  onEscape,
  color = "dark",
}: IOverlay) => {
  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onEscape && onEscape();
      }
    },
    [onEscape]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [handleEscape]);

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <OverlayContainer
      $color={color}
      $zIndex={zIndex}
      onClick={handleClick}
      tabIndex={zIndex}
    >
      <OverlayBody $zIndex={zIndex}>{children}</OverlayBody>
    </OverlayContainer>
  );
};
