import { apolloClient } from "./../index";
import { ISelectMenuOption } from "./../components/Select/interfaces";
import { gql } from "@apollo/client";

export type templateType = {
  value: string;
  label: string;
  message: string;
  headerImgUrl: string;
  footerImgUrl: string;
};

export const getTemplates = async (): Promise<ISelectMenuOption[]> => {
  const query = gql`
    query {
      templates(pagination: { limit: -1 }) {
        data {
          id
          attributes {
            title
            message
            header_img {
              data {
                attributes {
                  url
                }
              }
            }
            footer_img {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({ query });
    if (error) throw error;

    //else
    const _data = data?.templates?.data;
    const result = _data?.map((x: any) => {
      const headerImgUrl = x.attributes?.header_img?.data?.attributes?.url;
      const footerImgUrl = x.attributes?.footer_img?.data?.attributes?.url;
      // if (headerImgUrl) {
      //   headerImgUrl = `${process.env.REACT_APP_API_BASE_URL}${headerImgUrl}`;
      // }
      // if (footerImgUrl) {
      //   footerImgUrl = `${process.env.REACT_APP_API_BASE_URL}${footerImgUrl}`;
      // }
      return {
        value: x.id,
        label: x.attributes?.title,
        data: {
          message: x.attributes?.message,
          headerImgUrl,
          footerImgUrl,
        },
      };
    });

    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchTemplateIdByName = async (name: string): Promise<string> => {
  const query = gql`
    query getId($name: String!) {
      templates(filters: { title: { eq: $name } }) {
        data {
          id
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { name },
    });
    if (error) throw error;

    //else
    const _data = data?.templates?.data;
    return !_data?.length ? null : _data[0].id;
  } catch (error) {
    throw error;
  }
};
