import React from "react";
import styled from "styled-components";
import { PlacementType } from "../Drop";
import { Drop } from "./../Drop";

const ToolTipBody = styled.div`
  padding: 16px 22px;
  line-height: 1.2;
`;

export interface ITooltip {
  children: React.ReactNode;
  body: React.ReactNode;
  placement: PlacementType;
  mode?: "dark" | "light";
  trigger?: "click" | "hover";
  /**The container element of the drop element. Its default value is the "root" element and it is often what is needed.*/
  dropContainer?: React.ReactElement;
}

export function Tooltip({
  children,
  body,
  placement,
  mode = "light",
  trigger = "hover",
  dropContainer,
}: ITooltip) {
  return (
    <Drop
      children={children}
      body={<ToolTipBody>{body}</ToolTipBody>}
      placement={placement}
      arrow={true}
      background={mode === "light" ? "black" : "white"}
      show={trigger === "click" ? "onclick" : "onhover"}
      dropContainer={dropContainer}
    />
  );
}
