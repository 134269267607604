// @ts-nocheck

import React from "react";
import { IButton } from ".";

export interface ILinkOrButton extends IButton {
  className?: string;
}

type RefType = HTMLButtonElement | HTMLAnchorElement;

export const LinkOrButton = React.forwardRef<RefType, ILinkOrButton>(
  (props, ref) =>
    props.href ? (
      <a ref={ref} {...props} className={props.className} />
    ) : (
      <button ref={ref} {...props} className={props.className} />
    )
);
