import styled from "styled-components";
import * as COLOR from "./../../../color";
import * as CONST from "./../../../const";

type HelperMessageWrapProps = {
  state?: "error" | "success" | "default";
};

export const HelperMessageWrap = styled.div<HelperMessageWrapProps>`
  display: flex;
  align-items: center;
  gap: ${CONST.spacing_xs};
  margin: 5px 8px;
  line-height: 1.3em;

  color: ${(props) =>
    props.state === "error"
      ? COLOR.brand
      : props.state === "success"
      ? COLOR.interaction
      : COLOR.ashes};

  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};
  font-size: 12px;
`;

export const BottomMessages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const RightBottomMessage = styled.div`
  color: ${COLOR.ashes};
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};
  font-size: 12px;
  padding: 4px 8px;
  white-space: nowrap;
`;
