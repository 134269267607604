import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgInbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M21.6 8h-11L4 17v5a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-5zm.865 9.023-2.052 3.002-8.588-.02-2.202-2.994-4.086-.024L11.2 9.013H21l5.6 7.975z" />
  </svg>
);
const Memo = memo(SvgInbox);
export default Memo;
