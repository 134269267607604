//@ts-nocheck
import React from "react";
import * as S from "./badge.style";

export interface BadgeProps {
  /** what will be inside the badge, could be a component */
  children?: React.ReactNode;
  /** What to display on badge. If defined will hide childre field. */
  caption?: string;
  /** Variant of the badge*/
  variant?: "primary" | "primaryInverted" | "important";
  /** Size of the badge */
  size?: "xxs" | "xs";
}

export const Badge = ({
  children,
  caption,
  variant = undefined,
  size = "xs",
}: BadgeProps) => {
  return (
    <S.Container $caption={caption} $size={size} $variant={variant}>
      <S.Caption $size={size} $variant={variant}>
        {caption || children}
      </S.Caption>
    </S.Container>
  );
};
