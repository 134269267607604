import React from "react";
import { Badge } from "./../Badge";
import { Icon } from "./../Icon";
import { FullFilterProps } from "./../../types";
import { formatDate } from "./../utils/date";
import styles from "./style.module.scss";

export interface SelectedFilterProps {
  filter?: FullFilterProps;
  onChange: (v?: FullFilterProps) => void;
}

export const SelectedFilter: React.FC<SelectedFilterProps> = ({
  filter,
  onChange,
}) => {
  const isEmpty = (f: FullFilterProps) => {
    return (
      !f?.authorCountries?.length &&
      !f?.authorDepartments?.length &&
      !f?.authors?.length &&
      //
      !f?.recipientCountries?.length &&
      !f?.recipientDepartments?.length &&
      !f?.primoRecipients?.length &&
      !f?.extRecipients?.length &&
      !f?.recipientsType &&
      !f?.recipientsScope &&
      //
      !f?.startDate &&
      !f?.endDate &&
      !f?.categories?.length &&
      !f?.managerIncluded &&
      !f?.pushedToFeed
    );
  };
  const handleChange = (newFilter: FullFilterProps) => {
    onChange(isEmpty(newFilter) ? undefined : newFilter);
  };
  return (
    <div className={styles.badgeContainer}>
      {/* Author properties */}
      {!!filter?.authorCountries?.length &&
        filter.authorCountries.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  authorCountries: filter.authorCountries?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>from: {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.authorDepartments?.length &&
        filter.authorDepartments.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  authorDepartments: filter.authorDepartments?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>from: {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.authors?.length &&
        filter.authors.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  authors: filter.authors?.filter((_, j) => i !== j),
                })
              }
            >
              <span>from : {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {/* Recipient properties */}
      {!!filter?.recipientCountries?.length &&
        filter.recipientCountries.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  recipientCountries: filter.recipientCountries?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>to: {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.recipientDepartments?.length &&
        filter.recipientDepartments.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  recipientDepartments: filter.recipientDepartments?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>to: {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.primoRecipients?.length &&
        filter.primoRecipients.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  primoRecipients: filter.primoRecipients?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>to : {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.extRecipients?.length &&
        filter.extRecipients.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  extRecipients: filter.extRecipients?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>to : {x}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.recipientsType && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                recipientsType: undefined,
              })
            }
          >
            <span>to {filter.recipientsType}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}

      {!!filter?.recipientsScope && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                recipientsScope: undefined,
              })
            }
          >
            <span>to {filter.recipientsScope}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}

      {/* Other properties */}
      {!!filter?.startDate && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                startDate: undefined,
              })
            }
          >
            <span>from : {formatDate(filter.startDate)}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}

      {!!filter?.endDate && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                endDate: undefined,
              })
            }
          >
            <span>to : {formatDate(filter.endDate)}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}

      {!!filter?.categories?.length &&
        filter.categories.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  categories: filter.categories?.filter((_, j) => i !== j),
                })
              }
            >
              <span>category: {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.managerIncluded && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                managerIncluded: undefined,
              })
            }
          >
            <span>{filter.managerIncluded}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}

      {!!filter?.pushedToFeed && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                pushedToFeed: undefined,
              })
            }
          >
            <span>{filter.pushedToFeed}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}
    </div>
  );
};
