import * as COLOR from "./../../color";
import * as CONST from "./../../const";

const Types = {
  S: "s",
  M: "m",
  L: "l",
  XL: "xl",
  Primary: "primary",
  Secondary: "secondary",
  Tertiary: "tertiary",
} as const;

type SizeTypes =
  | typeof Types.S
  | typeof Types.M
  | typeof Types.L
  | typeof Types.XL;
type VariantTypes =
  | typeof Types.Primary
  | typeof Types.Secondary
  | typeof Types.Tertiary;

export const WIDTH: Record<SizeTypes, string> = {
  [Types.S]: `width:${CONST.height_s};`,
  [Types.M]: `width:${CONST.height_m};`,
  [Types.L]: `width:${CONST.height_l};`,
  [Types.XL]: `width:${CONST.height_xl};`,
};

export const PADDING: Record<SizeTypes, string> = {
  [Types.S]: `padding: 0 20px;`,
  [Types.M]: `padding: 0 24px;`,
  [Types.L]: `padding: 0 29px;`,
  [Types.XL]: `padding: 0 34px;`,
};

export const SIZE_PROPERTIES: Record<SizeTypes, string> = {
  [Types.S]: `height: ${CONST.height_s};
    line-height:20px;
    font-size:12px;
    `,
  [Types.M]: `height: ${CONST.height_m};
    line-height:20px;
    font-size:14px;
    `,
  [Types.L]: `height: ${CONST.height_l};
    line-height:24px;
    font-size:16px;
    `,
  [Types.XL]: `height: ${CONST.height_xl};
    line-height:24px;
    font-size:16px;
    `,
};

// export const VARIANT_PROPERTIES: Record<VariantTypes, string> = {
//   [Types.Primary]: `background-color: ${COLOR.interaction};
//     border: none;
//     color: ${COLOR.white};
//     transition: all .2s ease-in-out;
//     &:hover {
//       background-color: ${COLOR.interaction_650};
//     }
//     `,
//   [Types.Secondary]: `background-color: transparent;
//     border: ${CONST.border_single_caribe};
//     color: ${COLOR.interaction};
//     transition: all .2s ease-in-out;
//     &:hover {
//       background-color: ${COLOR.interaction};
//       color: ${COLOR.white};
//     }`,
//   [Types.Tertiary]: `background-color: transparent;
//     border: ${CONST.border_single_silver};
//     color: ${COLOR.carbon};
//     transition: all .2s ease-in-out;
//     background-color: ${COLOR.white_opacity80};
//     &:hover {
//       background-color: ${COLOR.silver};
//       border: ${CONST.border_single_silver};
//     }`,
// };
export const VARIANT_PROPERTIES = (
  variant: VariantTypes,
  color?: string
): string => {
  switch (variant) {
    case Types.Primary:
      return `
        background-color: ${
          color ? COLOR.colorVariants(color) : COLOR.interaction
        };
        border: none; 
        color: ${COLOR.white};
        transition: all .2s ease-in-out;
        &:hover {
          background-color: ${
            color ? COLOR.colorVariants(color, 650) : COLOR.interaction_650
          };
        }
      `;
    case Types.Secondary:
      return `
        background-color: transparent;
        border: 1px solid ${
          color ? COLOR.colorVariants(color) : COLOR.interaction
        };
        color: ${color ? COLOR.colorVariants(color) : COLOR.interaction};
        transition: all .2s ease-in-out;
        &:hover {
          background-color: ${
            color ? COLOR.colorVariants(color) : COLOR.interaction
          };
          color: ${COLOR.white};
        }
      `;
    case Types.Tertiary:
      return `
        border: ${CONST.border_single_silver}; 
        color: ${COLOR.carbon};
        transition: all .2s ease-in-out;
        background-color: ${COLOR.white_opacity80};
        &:hover {
          background-color: ${COLOR.silver};
          border: ${CONST.border_single_silver};
        }
      `;
    default:
      throw new Error(`Invalid variant type: ${variant}`);
  }
};

export const FONT_SIZE: Record<SizeTypes, string> = {
  [Types.S]: "14px",
  [Types.M]: "14px",
  [Types.L]: "16px",
  [Types.XL]: "16px",
};

export const ICON_VARIANT_PROPERTIES: Record<VariantTypes, string> = {
  [Types.Primary]: `
     fill: ${COLOR.white};
  `,
  [Types.Secondary]: `
     fill: ${COLOR.white};
  `,
  [Types.Tertiary]: `
     fill: ${COLOR.carbon};
  `,
};
