import React from "react";
//@ts-ignore
import * as S from "./withLabel.style";
import { IWithLabel } from "./../interfaces";

interface ILabelWithChildren extends IWithLabel {
  children: React.ReactNode;
}
export const WithLabel = (
  {
    children,
    empty,
    label, //IWithLabel
    hasPlaceholder = false,
    size = "l", //IWithLabel
    alertState = undefined, //IWithHelperMessage & IWithLabel
    textArea = false,
  }: ILabelWithChildren & { empty: boolean } //the input has value.lenght>0
) => {
  return (
    <S.InputContainer
      $alertState={alertState}
      $textArea={textArea}
      $size={size}
      $labelOnTop={!empty || hasPlaceholder || textArea}
      $label={label}
    >
      {label?.length && <span className="form-control-label">{label}</span>}
      {textArea ? <S.TextAreaWrap>{children}</S.TextAreaWrap> : children}
    </S.InputContainer>
  );
};
