import React from "react";
import styles from "./style.module.scss";

export default function NotFound() {
  return (
    <div className={styles.notFoundPageContainer}>
      <h1 className={styles.title}>404 - Page Not Found</h1>
      <p className={styles.text}>
        Oops! The page you're looking for doesn't exist.
      </p>
      <a href="/home" className={styles.link}>
        Go back to the homepage
      </a>
    </div>
  );
}
