import React, { useEffect, useState } from "react";
import { CheerDetailsProps, getCheerDetailsById } from "./../../api/cheers";
import { LabelButton } from "./../Button";
import { Spinner } from "./../Spinner";
import { Icon } from "./../Icon";
import { CheersPostcard } from "./../CheersPostcard";
import { PageWithImage } from "./../CheersPageWithImage";
import { primoProps } from "../../api/primos";
import { getFetchedUser } from "./../../auth/utils";
import { formatDate } from "./../utils/date";
import { PrintablePostCard } from "./PrintablePostCard";
import { MAIN_COLOR } from "./../../config";
import styles from "./style.module.scss";

export interface CheersLocalDetailsProps extends CheerDetailsProps {
  closeMe?: () => void;
}

export const CheerDetails: React.FC<{
  cheerId: string;
  closeMe: () => void;
}> = ({ cheerId, closeMe }) => {
  const [details, setDetails] = useState<CheerDetailsProps>();
  const [postCardVisible, setPostCardVisible] = useState<boolean>(false);

  useEffect(() => {
    getCheerDetailsById(cheerId)
      .then((v) => {
        setDetails(v);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }, [cheerId]);

  return !details ? (
    <Spinner size="xl" variant="primary" />
  ) : (
    <PageWithImage iconName="ArrowLeft" onIconClick={closeMe} helpButton>
      <div className={styles.detailsWrap}>
        <div className={styles.columnPart}>
          <CheersDetailsHeader
            toGroup={details.toGroup}
            groupeName={details.groupeName}
            nickName={details.nickName}
            from={details.from}
            to={details.to}
            toCc={details.toCc}
          />
          <div className={styles.detailsShort}>
            <div className={styles.timedate}>
              <Icon name="Time" size={20} />
              <span> {formatDate(details.createdAt)}</span>
            </div>
            <div className={styles.category}>{details.category}</div>
          </div>
          <div className={styles.detailsDesc}>
            {details.title && (
              <div className={styles.detailsTitle}>{details.title}</div>
            )}
            {details.message && (
              <div className={styles.detailsText}>{details.message}</div>
            )}
          </div>
        </div>
        {details?.template && (
          <PrintablePostCard
            visible={postCardVisible}
            onCloseMe={() => setPostCardVisible(false)}
          >
            <CheersPostcard
              template={details.template}
              title={details.title}
              message={details.message}
              authorName={details.from?.name}
            />
          </PrintablePostCard>
        )}

        <div className={styles.columnPart}>
          {details.to?.length > 0 && details.from && (
            <div>
              <div>From</div>
              <b>{details.from?.name}</b>
            </div>
          )}
          <LabelButton
            icon={<Icon name="PdfFile" />}
            label="Show Postcard"
            onClick={() => setPostCardVisible(true)}
            variant="primary"
            size="xl"
            corners="squared"
            auto
            color={MAIN_COLOR}
          />
        </div>
      </div>
    </PageWithImage>
  );
};

interface CheersDetailsHeaderProps {
  toGroup: boolean;
  groupeName: string;
  nickName: string;
  from: primoProps;
  to: primoProps[];
  toCc?: primoProps[];
}
const CheersDetailsHeader: React.FC<CheersDetailsHeaderProps> = ({
  toGroup,
  groupeName,
  nickName,
  from,
  to,
  toCc,
}) => {
  // const receivedByMe = to?.find((x) => x.azureId === me.azurId);
  //If it is received by me => then to is undefined
  const imgUrl =
    (!!to?.length && to?.length > 0
      ? getFetchedUser(to[0].azureId)
      : getFetchedUser(from?.azureId)) ?? "/img/default-avatar.jpg";

  const _to = toGroup
    ? groupeName || to?.map((x) => x.name)?.join(", ")
    : to?.map((x) => x.name)?.join(", ") || nickName;
  return (
    <div className={styles.detailsHeader}>
      <div className={styles.detailsHeaderContainer}>
        <div className={styles.avatarContainer}>
          {imgUrl && (
            <img className={styles.avatar} src={imgUrl} alt={from?.name} />
          )}
          {to && <Icon className={styles.avatarIcon} name="Send" size={20} />}
          {!to && <Icon className={styles.avatarIcon} name="Inbox" size={20} />}
        </div>
        <div className={styles.overflowHidden}>
          {to && (
            <>
              <div>Sent to</div>
              <b className={styles.nowrap}>{_to}</b>
            </>
          )}
          {!to && from && (
            <>
              <div>From</div>
              <b className={styles.nowrap}>{from?.name}</b>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
