import React, { useState, useEffect } from "react";
import IconQuestionCircle from "./../../../Icon/tsx/QuestionHelpCircle";
import { Tooltip } from "./../../../Tooltip";
import { min_width_on_laptop } from "./../../../const";
import { IWithInformation } from "./../interfaces";
//@ts-ignore
import * as S from "./withInformation.style";

interface IInfoWrap extends IWithInformation {
  children: React.ReactNode;
}

export const WithInformation = ({
  children,
  informationMessage = undefined,
  informationPosition = "top",
  alignCenter = true,
}: IInfoWrap) => {
  const [trigger, setTrigger] = useState<"hover" | "click">("hover");

  useEffect(() => {
    const updateWindowDimensions = () => {
      setTrigger(window.innerWidth < min_width_on_laptop ? "click" : "hover");
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return informationMessage?.length ? (
    <S.Wrapper $alignCenter={alignCenter}>
      <S.Inner>{children}</S.Inner>
      <Tooltip
        body={informationMessage}
        placement={informationPosition}
        trigger={trigger}
      >
        <S.Handle>
          <IconQuestionCircle />
        </S.Handle>
      </Tooltip>
    </S.Wrapper>
  ) : (
    <>{children}</>
  );
};
