import React from "react";
import { SideSheetModal } from "../SideSheet";
import { PageWithImage } from "../CheersPageWithImage";
import styles from "./styles.module.scss";

interface HelpSheetProps {
  open: boolean;
  closeMe: () => void;
}
export const HelpSheet: React.FC<HelpSheetProps> = ({ open, closeMe }) => {
  return (
    <SideSheetModal
      // scrollableContent={false}
      open={open}
      closeMe={closeMe}
      position="left"
      fullscreen={true}
      closeOnSwip={false}
    >
      {open && (
        <PageWithImage iconName="ArrowLeft" onIconClick={closeMe}>
          <div className={styles.container}>
            <div className={styles.pageTitle}>Help</div>

            <div className={styles.section}>
              If you need support or have an idea to <b>#makethingsbetter</b>,
              email us here:{" "}
              <a className={styles.mail} href="mailto:cheers@bacardi.com">
                cheers@bacardi.com
              </a>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                How is Bacardi's culture expressed through Cheers?
              </div>
              <div className={styles.subSection}>
                <img
                  src="/img/family.jpg"
                  alt="family"
                  className={styles.fullWithImg}
                />
                <p>
                  We treat each other, and our communities, like family….
                  always.
                </p>
              </div>
              <div className={styles.subSection}>
                <img
                  src="/img/founders.jpg"
                  alt="family"
                  className={styles.fullWithImg}
                />
                <p>
                  We are empowered to question, challenge and innovate our
                  business and the way we work. Nothing gets in our way!
                </p>
              </div>
              <div className={styles.subSection}>
                <img
                  src="/img/fearless.jpg"
                  alt="family"
                  className={styles.fullWithImg}
                />
                <p>
                  We see the organization as if it’s our own. We do what’s right
                  for the business and we all take accountability for our work.{" "}
                </p>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                How can I access the tool ?
              </div>
              <div className={styles.subSectionHorizental}>
                <img
                  className={styles.iconImg}
                  src="/img/icon-one-bacardi.png"
                  alt="icon-descktop"
                />
                <div className={styles.desc}>
                  Go to <b>cheers.onebacardi.com</b> using your favorite web
                  browser from your desktop
                </div>
              </div>

              <div className={styles.subSectionHorizental}>
                <img
                  className={styles.iconImg}
                  src="/img/icon-descktop.png"
                  alt="icon-descktop"
                />
                <div className={styles.desc}>
                  Double click the Cheers shortcut in your desktop
                </div>
              </div>
            </div>
          </div>
        </PageWithImage>
      )}
    </SideSheetModal>
  );
};
