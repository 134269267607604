import { css } from "styled-components";

/* This could be shared by more components if is the common scrollbare style */

const sharedCode = css`
  overflow-y: auto;
  overscroll-behavior: contain;

  // &::-webkit-scrollbar,
  // &::-webkit-scrollbar-thumb {
  //   background-clip: padding-box;
  //   border: 8px solid transparent;
  //   width: 24px;
  //   //border: 4px solid transparent;
  //   //width: 12px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 12px;
  //   background-color: transparent;
  // }
`;

export const scrollBar = css`
  ${sharedCode}
  ::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
  }
`;

export const onHoverScrollBar = css`
  ${sharedCode}
  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
    }
  }
`;
