import React from "react";
import { Button, IButton } from "../Button";

// Deprecated  component:
// We can use Button component directly - it's syntactic sugar not to break existing components

type RefType = HTMLButtonElement | HTMLAnchorElement;

export const BasicButton = React.forwardRef<RefType, IButton>((props, ref) => (
  <Button ref={ref} {...props} />
));
