import React, { useCallback, useState } from "react";
// @ts-ignore
import * as S from "./loadingIndicator.style";
// @ts-ignore
import { WIDTH_RATIO } from "./loadingIndicator.constants";

// export const WIDTH_RATIO = 6;

export const LoadingIndicator = () => {
  const [innerBarWidth, seInnerBartWidth] = useState(0);

  const measureWidth = useCallback((node: any) => {
    if (node !== null) {
      seInnerBartWidth(node.clientWidth / WIDTH_RATIO);
    }
  }, []);

  return <S.Container ref={measureWidth} $innerBarWidth={innerBarWidth} />;
};
