import styled, { css } from "styled-components";
import IconArrowRight from "./../../Icon/tsx/BoldArrowsNavigateRightThin";
import IconCalendar from "./../../Icon/tsx/Calendar";

interface IconProps {
  disabled?: boolean;
}

const iconCss = css<IconProps>`
  z-index: 3;
  ${(props) => props.disabled && `opacity: 0.4;`}
  font-size: 18px;
  flex-shrink: 0;
`;

export const ArrowIcon = styled(IconArrowRight)<IconProps>`
  ${iconCss}
  padding: 0 12px;
`;

export const CalendarIcon = styled(IconCalendar)<IconProps>`
  ${iconCss}
  padding-left: 1.5em;
`;

export const DoubleDateInputContainer = styled.div`
  position: relative;
`;

export const DoubleInputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;
