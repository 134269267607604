import React from "react";
// @ts-ignore
import * as S from "./avatar.style";
import nameIntials from "../utils/nameIntials";
// import { Types as SizeType } from "./avatar.constants";

interface IAvatarBasic {
  /** Accesibilty image description */
  alt?: string;
  /** Fired on click */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /** Avatar size */
  size?: "xs" | "s" | "m" | "l" | "xl";
}
export interface IAvatarImg extends IAvatarBasic {
  /** Image source url */
  source?: string;
  /** Employee or user name */
  userName?: never;
}
export interface IAvatarInitials extends IAvatarBasic {
  /** Image source url */
  source?: never;
  /** Employee or user name */
  userName?: string;
}
export interface IAvatarIcon extends IAvatarBasic {
  /** Image source url */
  source?: never;
  /** Employee or user name */
  userName?: never;
}
type IAvatar = IAvatarImg | IAvatarInitials | IAvatarIcon;

export const Avatar = ({
  source = undefined,
  alt = undefined,
  userName = undefined,
  onClick = undefined,
  size = "m",
}: IAvatar) => {
  const initials = !source && userName?.length ? nameIntials(userName) : null;

  return (
    <S.Avatar onClick={onClick} size={size}>
      {source ? (
        <S.Image alt={alt} $size={size} $source={source} />
      ) : initials ? (
        <S.Initials $size={size}>{initials}</S.Initials>
      ) : (
        <S.Initials $size={size}>
          <S.UserIcon />
        </S.Initials>
      )}
    </S.Avatar>
  );
};
