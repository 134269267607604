import React from "react";
import "./errorPage.scss"; // Import the CSS file for styling

const AuthErrorPage = () => {
  return (
    <div className="unknown-error-container">
      <h1 className="unknown-error-heading">Oops!</h1>
      <h2 className="unknown-error-title">Authentication error</h2>
      <p className="unknown-error-message">
        We apologize for the inconvenience. Please try again later or contact
        the webmaster for assistance.
      </p>
    </div>
  );
};

export default AuthErrorPage;
