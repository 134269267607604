import React from "react";
import { Icon } from "../../Icon";
import { BasicButton } from "../../Button";
import { RecipientScope } from "./../../../types";
import { MAIN_COLOR } from "./../../../config";
import styles from "./stepsStyle.module.scss";

interface SendStep2BodyProps {
  recipientType: RecipientScope;
  toGroup: boolean;
  closeMe: () => void;
  goBack: () => void;
  goNext: () => void;
  onChange: (recipientType: RecipientScope) => void;
}
export const SendStep2Body: React.FC<SendStep2BodyProps> = ({
  recipientType,
  toGroup,
  closeMe,
  goBack,
  goNext,
  onChange,
}) => {
  return (
    <div className={styles.firstStepWrapper}>
      <div className={styles.closeIcon} onClick={closeMe}>
        <Icon name="Cross" size={32} />
      </div>
      <div className={styles.title}>
        <div>I want to send</div>
        <div>
          <b>{`${toGroup ? "Group" : "Individual"}`} Cheers!</b> to...
        </div>
      </div>
      <div className={styles.step2type}>
        <div
          onClick={() => onChange("Primo")}
          className={`${styles.imageRecipient} ${styles.primoImg} ${
            toGroup === true ? styles.groupe : ""
          } ${recipientType === "Primo" ? styles.active : ""}`}
        ></div>
        <div
          onClick={() => onChange("External")}
          className={`${styles.imageRecipient} ${styles.externalImg} ${
            toGroup === true ? styles.groupe : ""
          } ${recipientType === "External" ? styles.active : ""}`}
        ></div>
        {toGroup && (
          <div
            onClick={() => onChange("Mixed")}
            className={`${styles.imageRecipient} ${styles.mixedImg}  ${
              recipientType === "Mixed" ? styles.active : ""
            }`}
          ></div>
        )}
      </div>
      <div className={styles.step2buttonsWrap}>
        <div className={styles.step2buttons}>
          <BasicButton
            onClick={goBack}
            label="Back"
            variant="tertiary"
            size="xl"
            corners="squared"
            auto
            color={MAIN_COLOR}
          />
          <BasicButton
            onClick={goNext}
            label="Next"
            variant="primary"
            size="xl"
            corners="squared"
            auto
            color={MAIN_COLOR}
          />
        </div>
      </div>
    </div>
  );
};
