// sideSheetContainer.styles.ts
import styled, { css } from "styled-components";
import { onHoverScrollBar } from "./../../utils/scrollBar.style";
import * as C from "./sideSheetContainer.constants";
import { shadow_highlighted } from "./../../const";

// Define interfaces for the props used in styled components
interface SideSheetContainerProps {
  $position?: string; // optional, as it's not always used
}

interface SideSheetProps {
  $position: string;
  $open: boolean;
  $rail?: boolean;
  $maxWidth?: string;
  $maxHeight?: string;
}

// Styled component definitions
export const SideSheetContainerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

export const SideSheetContainer =
  styled.div <
  SideSheetContainerProps >
  `
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  height: 100%;
  ${(props) => props.$position && C.SIDE_SEET_POSITION[props.$position]}
`;

interface ContentProps {
  $scrollable: boolean;
}

export const Content = styled.div<ContentProps>`
  position: relative;
  order: 0;
  flex-grow: 1;

  ${(props) =>
    props.$scrollable &&
    css`
      ${onHoverScrollBar}
    `}
  overflow-x: hidden;
`;

export const SideSheet =  styled.div <SideSheetProps>`
  order: -1;
  flex-shrink: 0;
  box-shadow: ${shadow_highlighted};
  visibility: ${(props) => (props.$open ? "visible" : "hidden")};
  opacity: ${(props) => (props.$open ? "1" : "0")};

  ${(props) =>
    props.$rail ? C.RAIL_SIZE[props.$position] : C.SS_SIZE[props.$position]}
  /* height: 100%; */

  ${(props) => props.$maxWidth && `max-width:${props.$maxWidth};`}
  ${(props) => props.$maxHeight && `max-height:${props.$maxHeight};`}
  ${(props) => !props.$open && C.SIDE_SEET_NULL_SIZE[props.$position]}
  transition: all 0.5s ease;
  z-index: 1;
  ${onHoverScrollBar}
`;
