import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgInterfaceSettingMenuVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 10 10"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M3.75 8.75a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 1 0-2.5 0m0-3.75a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 1 0-2.5 0m0-3.75a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 1 0-2.5 0" />
  </svg>
);
const Memo = memo(SvgInterfaceSettingMenuVertical);
export default Memo;
