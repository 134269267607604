//@ts-nocheck
import React, { useState, useImperativeHandle, useEffect } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import * as S from "./style";

export type PlacementType =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end";

export interface IDrop {
  /**The element to which the drop is attached. */
  children: React.ReactNode;
  /**A react node that could be a string or a more complex element. */
  body: React.ReactNode;
  /**The desired placement of the drop relative to the element. */
  placement?: PlacementType;
  /**If true an arrow will be added to the drop. */
  arrow?: boolean;
  /**If true, the drop will have the same width as the element */
  sameWidth?: boolean;
  /**Must be set to true if the concerned element width should be the container width. */
  autoWidth?: boolean;
  /**The color of the drop background. */
  background?: "white" | "black";
  /**The max heigh of the drop. A scroll bar will be added otherwise. */
  maxHeight?: string;
  /**The max width of the drop. */
  maxWidth?: string;
  /**Since the "open" state is inside of the drop, it will informe the outside with the function onChange */
  onChange?: (v: boolean) => void;
  /**If controlled, it will be controlled from outside using open() & close() methods */
  show?: "always" | "controlled" | "onhover" | "onclick";
  /**To use if we want to change the distance between the drop and the element */
  offset?: number;
  /**The inner ref inside the drop/popper div. */
  dropInnerRef?: React.MutableRefObject<any>;
  /**The inner ref inside the element div. */
  elementInnerRef?: React.MutableRefObject<any>;
  /**The container element of the drop element. Its default value is the "root" element and it is often what is needed.*/
  dropContainer?: React.ReactElement;
  /**If true the element will keep the focus when the drop is opened. This is not the default behavior. */
  keepFocusOnElement?: boolean;
}

const getShortPlacement = (place: string) => {
  let result: string = undefined;
  if (place?.length > 0) {
    result = place.split("-")[0];
  }
  return result;
};

// export const Drop = React.forwardRef<HTMLElement, IDrop>(
export const Drop = React.forwardRef(
  (
    {
      children,
      body,
      placement = "bottom",
      arrow = false,
      sameWidth = false,
      autoWidth = false,
      background = "white",
      maxHeight = undefined,
      maxWidth = undefined,
      show = "controlled",
      onChange = undefined,
      offset = 0,
      dropInnerRef,
      elementInnerRef,
      dropContainer = undefined,
      keepFocusOnElement = false,
    }: IDrop,
    ref
  ) => {
    const [elementRef, setElementRef] = useState();
    const [popperRef, setPopperRef] = useState();
    const [bodyRef, setBodyRef] = useState();
    const [arrowRef, setArrowRef] = useState(null);
    const [open, setOpen] = useState(show === "always");
    const [_dropContainer, setDropContainer] = useState(
      dropContainer ?? document.body //document.getElementById("root")
    );

    useEffect(() => {
      setDropContainer(
        dropContainer ??
          document.getElementById("__next") ?? //for next application
          document.getElementById("_portal") ?? //for spa react application ??
          document.body //document.getElementById("root")
      );
    }, [dropContainer]);

    //_dropContainer = dropContainer ?? document.getElementById("root");

    const modifiers = [];
    if (arrow) {
      modifiers.push({ name: "arrow", options: { element: arrowRef } });
      modifiers.push({ name: "offset", options: { offset: [0, 6] } });
    } else if (offset > 0) {
      modifiers.push({ name: "offset", options: { offset: [0, offset] } });
    }

    const { styles, attributes, state, update } = usePopper(
      elementRef,
      popperRef,
      {
        placement,
        modifiers,
      }
    );

    const shortPlacement = sameWidth && getShortPlacement(state?.placement); //top, bottom, left or right: ;

    const changeOpenState = (opn) => {
      if (opn && update) {
        update();
      }
      setOpen(opn);
      onChange && onChange(opn);
    };

    useImperativeHandle(ref, () => ({
      //...ref,
      focus: () => {
        bodyRef?.focus();
      },
      open: (e) => {
        e && e.stopPropagation();
        changeOpenState(true);
      },
      close: (e) => {
        e && e.stopPropagation();
        changeOpenState(false);
      },
      toggle: (e) => {
        e && e.stopPropagation();
        changeOpenState(!open);
      },
      updateLocation: (e) => {
        !!update && update();
      },
      getBoundingClientRect: () => bodyRef?.getBoundingClientRect(),
      isOpen: open,
      placement: state?.placement,
      shortPlacement: shortPlacement,
    }));
    //
    const handleMouseOver = () => {
      if (show === "onhover") {
        changeOpenState(true);
      }
    };
    const handleMouseOut = () => {
      if (show === "onhover") {
        changeOpenState(false);
      }
    };
    const handleClick = (e) => {
      if (show === "onclick") {
        e.stopPropagation();
        changeOpenState(!open);
      }
    };

    useEffect(() => {
      if (open && !keepFocusOnElement) {
        if (dropInnerRef?.current) {
          dropInnerRef.current.focus();
        } else if (bodyRef) {
          bodyRef.focus();
        }
      } else {
        if (elementInnerRef?.current) {
          elementInnerRef.current.focus();
        } else if (elementRef) {
          elementRef.focus();
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    //To close the drop when show === onclick : clicking out of the drop of press Escape
    useEffect(() => {
      if (show === "always") return;
      //else

      const keyPress = (e) => {
        if (e.key === "Escape") {
          changeOpenState(false);
        }
      };
      const handleClickOutside = (e) => {
        if (
          popperRef &&
          !popperRef.contains(e.target) &&
          !elementRef.contains(e.target)
        ) {
          changeOpenState(false);
        }
      };

      document.addEventListener("keydown", keyPress);
      document.addEventListener("mousedown", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", keyPress);
        document.removeEventListener("mousedown", handleClickOutside, true);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popperRef]);

    //Update the position of the drop on the element size changing
    useEffect(() => {
      if (!elementRef) {
        return;
      }

      const debounceUpdate = () => {
        let timeoutId = null;
        return () => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            update && update();
          }, 100); // délai de 100 ms, ajustable
        };
      };

      let RO = new ResizeObserver(debounceUpdate());

      return () => {
        RO.disconnect();
        RO = null;
      };
    }, [elementRef, update]);

    // //Update the position of the drop on the element place changing
    // useEffect(() => {
    //   if (!elementRef || !update) {
    //     return;
    //   }

    //   let previousRect = elementRef.getBoundingClientRect();

    //   const checkPosition = () => {
    //     const currentRect = elementRef.getBoundingClientRect();
    //     if (
    //       currentRect.left !== previousRect.left ||
    //       currentRect.top !== previousRect.top
    //     ) {
    //       update(); // Utilise la fonction debounce
    //       previousRect = currentRect;
    //     }
    //     requestAnimationFrame(checkPosition);
    //   };
    //   requestAnimationFrame(checkPosition);

    //   return () => {
    //     cancelAnimationFrame(checkPosition);
    //   };
    // }, [elementRef, update]);

    return (
      <S.Container>
        <S.ElementDiv
          ref={setElementRef}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleClick}
          $autoWidth={autoWidth}
        >
          {children}
        </S.ElementDiv>

        {!_dropContainer
          ? null
          : ReactDOM.createPortal(
              <S.PopperDiv
                $open={open}
                ref={setPopperRef}
                key="dropComponent"
                style={
                  sameWidth
                    ? {
                        ...styles.popper,
                        minWidth: elementRef?.scrollWidth,
                        maxWidth: elementRef?.scrollWidth,
                      }
                    : { ...styles.popper }
                }
                {...attributes.popper}
                $background={background}
                $shortPlacement={false && shortPlacement} //disabling this feature for the moment (border-reduis the same even with same width)
              >
                <S.PopperBody
                  ref={setBodyRef}
                  tabIndex={-1}
                  $maxHeight={maxHeight}
                  $maxWidth={maxWidth}
                >
                  {body}
                </S.PopperBody>
                {arrow && open && (
                  <S.PopperArrow
                    ref={setArrowRef}
                    $popperPlacement={
                      attributes?.popper &&
                      attributes?.popper["data-popper-placement"]
                    }
                    style={styles.arrow}
                    {...attributes.arrow}
                    $background={background}
                  />
                )}
              </S.PopperDiv>,
              _dropContainer
            )}
      </S.Container>
    );
  }
);
