import styled from "styled-components";
import * as C from "./inputContainer.constants";
import * as COLOR from "./../../../color";
import * as CONST from "./../../../const";

type SizeTypes = "l" | "xl";
type PositionTypes = "top" | "bottom" | "left" | "right";

interface ContainerProps {
  $textArea?: boolean;
  $size?: SizeTypes;
  $stuckPlacement?: PositionTypes;
  $noMaxHeight?: boolean;
  $minTextAreaHeight?: string;
  $maxTextAreaHeight?: string;
  $error?: boolean;
  disabled?: boolean;
  $rounded?: boolean;
}

export const SideNode = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const Inner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.5em;
  overflow-x: auto;
`;

export const Container = styled.div<ContainerProps>`
  outline: none;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  background-color: #ffffff;
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};
  font-size: ${(props) =>
    props.$textArea ? "16px" : C.FONT_SIZE[props.$size || "l"]};
  border: ${CONST.border_single_smoke};
  border-radius: ${(props) =>
    props.$rounded ? CONST.radius_rounded : CONST.radius_px4};
  padding: 0 0.5em;
  ${(props) => props.$textArea && "padding:0 0 0.5em 0.5em;"}
  ${(props) =>
    props.$stuckPlacement && C.CUSTOM_BORDER_RADIUS[props.$stuckPlacement]}
  ${(props) =>
    props.$noMaxHeight || props.$textArea
      ? `min-height: ${CONST.height[props.$size || "l"]};`
      : `height: ${CONST.height[props.$size || "l"]};`}
  ${(props) =>
    props.$textArea &&
    props.$minTextAreaHeight &&
    `min-height:${props.$minTextAreaHeight};`}
  ${(props) =>
    props.$textArea &&
    props.$maxTextAreaHeight &&
    `max-height:${props.$maxTextAreaHeight};`}
  width: 100%;
  box-sizing: border-box;

  ${(props) =>
    props.$error &&
    `box-shadow: ${CONST.border_double_danger};
    border: 2px solid transparent;
    caret-color: ${COLOR.brand};
    outline: none;`}

  ${(props) =>
    props.disabled &&
    `background: ${COLOR.silver};
    border: ${CONST.border_single_silver};
    pointer-events: none;`}

  &:hover {
    ${(props) => !props.$error && `border: ${CONST.border_single_ashes};`}
  }
  &:focus-within {
    ${(props) =>
      !props.$error &&
      `
        box-shadow: inset 0px 0px 0px 2px ${COLOR.interaction};
        border: none;
        ${props.$rounded ? CONST.radius_rounded : CONST.radius_px4};
        // border-radius: 6px;
        transition: all .2s ease-in-out;`}
  }
  transition: all 0.2s ease-in-out;

  & textarea,
  & input {
    outline: none;
    border: none transparent;
    background: transparent;
    box-sizing: border-box;
    line-height: 1.5em;
    height: 100%;
    width: 100%;
    padding: 0;
    font-family: ${CONST.font_family_body};
    font-weight: ${CONST.weight_regular};
    font-size: ${(props) => C.FONT_SIZE[props.$size || "l"]};
    caret-color: ${(props) => (props.$error ? COLOR.ashes : COLOR.interaction)};
    cursor: pointer;
    flex-grow: 1;
    color: ${COLOR.carbon};

    ::placeholder {
      letter-spacing: normal;
      ${(props) => props.disabled && `opacity: 0.4;`}

      &:focus::placeholder {
        color: transparent;
      }
    }
    ::-webkit-inner-spin-button {
      display: none;
    }
    ::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;
