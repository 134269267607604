import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

// Define types for the context
interface EscapeContextType {
  register: (callback: () => void) => void;
  unregister: (callback: () => void) => void;
}

// Default value for the context
const EscapeContext = createContext<EscapeContextType>({
  register: () => {},
  unregister: () => {},
});

interface EscapeProviderProps {
  children: ReactNode;
}

export const EscapeProvider: React.FC<EscapeProviderProps> = ({ children }) => {
  const [callbacks, setCallbacks] = useState<Array<() => void>>([]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && callbacks.length > 0) {
        callbacks[callbacks.length - 1](); // Call the last registered callback
        setCallbacks((prev) => prev.slice(0, -1)); // Remove the callback from the stack after execution
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [callbacks]);

  const register = (callback: () => void) => {
    setCallbacks((prev) => [...prev, callback]);
  };

  const unregister = (callback: () => void) => {
    setCallbacks((prev) => prev.filter((cb) => cb !== callback));
  };

  return (
    <EscapeContext.Provider value={{ register, unregister }}>
      {children}
    </EscapeContext.Provider>
  );
};

export const useEscape = (): EscapeContextType => {
  return useContext(EscapeContext);
};
