import React, { useEffect, useState, useContext } from "react";
import { SideSheetModal } from "./../../SideSheet";
import { Spinner } from "./../../Spinner";
import { Overlay } from "./../../Overlay";
import { Modal } from "./../../Modal";
import { SendStep1Body } from "./SendStep1Body";
import { SendStep2Body } from "./SendStep2Body";
import { SendStep3Body, form1Props } from "./SendStep3Body";
import { SendStep4Body, form2Props } from "./SendStep4Body";
import { SentCongrats } from "./SentCongrats";
import { SentErrorOccured } from "./SentErrorOccured";
import { getOrAddPrimoId } from "./../../../api/primos";
import { addCheer } from "./../../../api/cheers";
import { fetchManager } from "../../../auth/utils";
import { AuthContext } from "../../../auth/AuthProvider";
import { RecipientScope } from "./../../../types";
import { ISelectMenuOption } from "../../Select/interfaces";
import { useMobileSizeCheck } from "./../../utils/useMediaSizeCheck";

import { getHtmlMessage } from "../../CheersPostcard";

interface SindingSheetProps {
  open: boolean;
  closeMe: () => void;
}
interface optionsProps extends form1Props, form2Props {
  toGroup: boolean;
  recipientType: RecipientScope;
}

const defaultOptions: optionsProps = {
  toGroup: false,
  recipientType: "Primo",

  //Form 1
  includeManager: true,
  primoRecipients: [],
  primoCopies: [],
  nickname: "",
  groupName: "",
  extRecipients: [],
  extCopies: [],

  //Form 2
  title: "",
  message: "",
  category: undefined,
  template: undefined,
  publishToFeed: true,
};

type stepType =
  | "recipientIsGroup"
  | "recipientIsPrimo"
  | "form1"
  | "form2"
  | "congrats"
  | "errorOccured"
  | null; //null meens closed

export const SindingSheet: React.FC<SindingSheetProps> = ({
  open,
  closeMe,
}) => {
  const [step, setStep] = useState<stepType>(null);
  const [options, setOptions] = useState<optionsProps>(defaultOptions);
  const [sendingCheer, setSendingCheer] = useState<boolean>(false);

  const me = useContext(AuthContext);
  const narrowMedia = useMobileSizeCheck();

  useEffect(() => {
    setStep(open ? "recipientIsGroup" : null); //Empty when hidden
  }, [open]);

  const handleCloseSending = () => {
    setStep(null);
    setOptions(defaultOptions);
    closeMe();
  };

  const handleSubmit = async () => {
    const primo_recipients = await Promise.all(
      options.primoRecipients?.map(async (p: ISelectMenuOption) => {
        const strapiId = await getOrAddPrimoId({
          azureId: p.value,
          name: p.label,
          mail: p.subLabel,
          country: p.data?.country,
          department: p.data?.department,
        });
        return { strapiId, email: p.subLabel };
      })
    );
    const primo_copies = await Promise.all(
      options.primoCopies?.map(async (p) => {
        const strapiId = await getOrAddPrimoId({
          azureId: p.value,
          name: p.label,
          mail: p.subLabel,
          country: p.data?.country,
          department: p.data?.department,
        });
        return { strapiId, email: p.subLabel };
      })
    );
    const html = await getHtmlMessage({
      template: options?.template?.data, //options.template,
      title: options.title,
      message: options.message,
      authorName: me!.name,
    });

    const managers = [];
    if (options.includeManager) {
      const azureManagerCache: string[] = []; //If several primos has same manager, his Id will be added once to Manager
      for (const x of options.primoRecipients) {
        if (!azureManagerCache.includes(x.value)) {
          const managerAzureInfo = await fetchManager(x.value);
          azureManagerCache.push(x.value); //To not do it twice
          if (managerAzureInfo) {
            const strapiId = await getOrAddPrimoId({
              azureId: managerAzureInfo.value,
              name: managerAzureInfo.label,
              mail: managerAzureInfo.subLabel,
              country: managerAzureInfo.data?.country,
              department: managerAzureInfo.data?.department,
            });
            managers.push({ strapiId, email: managerAzureInfo.subLabel });
          }
        }
      }
    }
    const email_draft: any = {
      ///THIS EMAIL INFO WILL BE ADDED BY THE BACKEND
      // from: "cheers@bacardi.com", // e.g. single sender verification in SendGrid
      // replyTo: "no-reply@bacardi.com",
      to: [
        ...primo_recipients.map((x) => x.email),
        ...options.extRecipients,
      ].join(";"),
      cc: [
        me!.mail, //author
        ...primo_copies.map((x) => x.email),
        ...options.extCopies,
        ...managers.map((x) => x.email),
      ].join(";"),
      // bcc:,
      subject: `Cheers! ${options.title}`,
      text: options.message,
      html,
      attachments: [
        {
          filename: "header.png",
          path: options?.template?.data?.headerImgUrl, // path to the image on the server
          cid: "headerImage", // unique id used in HTML
        },
        {
          filename: "footer.png",
          path: options?.template?.data?.footerImgUrl, // path to the image on the server
          cid: "footerImage", // unique id used in HTML
        },
      ],
    };

    const data = {
      title: options.title,
      recipient_groupe: options.toGroup,
      recipient_type: options.recipientType,
      include_manager: options.includeManager,
      publish_to_feed: options.publishToFeed,
      nickname: options.nickname,
      group_name: options.groupName,
      author: me!.strapiId,
      primo_recipients: primo_recipients?.map((x) => x.strapiId) ?? [],
      primo_copies: primo_copies?.map((x) => x.strapiId) ?? [], //Computed up
      managers_copies: managers?.map((x) => x.strapiId) ?? [],
      ext_recipients: options.extRecipients?.join(";") ?? "",
      ext_copies: options.extCopies?.join(";") ?? "",
      message: options.message,
      category: options.category?.value,
      template: options.template?.value,
      email_sent: false, //to be modified
      email_draft,
    };

    try {
      setSendingCheer(true);
      await addCheer(data);
      setStep("congrats"); //Congratulations: the email is send and data saved on server
    } catch (error) {
      console.error(error);
      //TODO : better handling of the error
      setStep("errorOccured"); //Try later
    } finally {
      setSendingCheer(false);
    }
  };

  const is2FirstSteps =
    step === "recipientIsGroup" || step === "recipientIsPrimo";

  const modal = is2FirstSteps && !narrowMedia;
  return step && modal ? (
    <Modal onClose={handleCloseSending}>
      {step === "recipientIsGroup" && (
        <SendStep1Body
          toGroup={options?.toGroup}
          onChange={(v) => setOptions({ ...options, toGroup: v })}
          goNext={() => setStep("recipientIsPrimo")}
          closeMe={handleCloseSending}
        />
      )}
      {step === "recipientIsPrimo" && (
        <SendStep2Body
          recipientType={options?.recipientType}
          toGroup={options?.toGroup}
          closeMe={() => handleCloseSending()}
          goBack={() => {
            setStep("recipientIsGroup");
            setOptions({ ...options, recipientType: "Primo" }); //on going back we re-init recipientType
          }}
          goNext={() => setStep("form1")}
          onChange={(v) => setOptions({ ...options, recipientType: v })}
        />
      )}
    </Modal>
  ) : (
    <SideSheetModal
      scrollableContent={false}
      open={open}
      closeMe={() => handleCloseSending()}
      // position={is2FirstSteps ? "bottom" : "left"}
      position="bottom"
      fullscreen={!is2FirstSteps}
      roundedCorners={is2FirstSteps}
      closeOnSwip={false}
    >
      {step === "recipientIsGroup" && (
        <SendStep1Body
          toGroup={options?.toGroup}
          onChange={(v) => setOptions({ ...options, toGroup: v })}
          goNext={() => setStep("recipientIsPrimo")}
          closeMe={handleCloseSending}
        />
      )}
      {step === "recipientIsPrimo" && (
        <SendStep2Body
          recipientType={options?.recipientType}
          toGroup={options?.toGroup}
          closeMe={() => handleCloseSending()}
          goBack={() => {
            setStep("recipientIsGroup");
            setOptions({ ...options, recipientType: "Primo" }); //on going back we re-init recipientType
          }}
          goNext={() => setStep("form1")}
          onChange={(v) => setOptions({ ...options, recipientType: v })}
        />
      )}
      {step === "form1" && (
        <SendStep3Body
          {...options}
          //Setters
          setIncludeManager={(v) =>
            setOptions({ ...options, includeManager: v })
          }
          setPrimoRecipients={(v) =>
            setOptions({ ...options, primoRecipients: v })
          }
          setPrimoCopies={(v) => setOptions({ ...options, primoCopies: v })}
          setNickname={(v) => setOptions({ ...options, nickname: v })}
          setGroupName={(v) => setOptions({ ...options, groupName: v })}
          setExtRecipients={(v) => setOptions({ ...options, extRecipients: v })}
          setExtCopies={(v) => setOptions({ ...options, extCopies: v })}
          //Others
          goNext={() => setStep("form2")}
          closeMe={() => handleCloseSending()}
        />
      )}
      {step === "form2" && (
        <>
          {sendingCheer && (
            <Overlay>
              <Spinner variant="primary" size="xl" />
            </Overlay>
          )}
          <SendStep4Body
            {...options}
            //Setters
            setTitle={(v) => setOptions({ ...options, title: v })}
            setMessage={(v) => setOptions({ ...options, message: v })}
            setCategory={(v) => setOptions({ ...options, category: v })}
            setTemplate={(v) => setOptions({ ...options, template: v })}
            setPublishToFeed={(v) =>
              setOptions({ ...options, publishToFeed: v })
            }
            //Others
            goBack={() => setStep("form1")}
            goNext={() => handleSubmit()}
            closeMe={() => handleCloseSending()}
          />
        </>
      )}
      {step === "congrats" && <SentCongrats goBack={handleCloseSending} />}
      {step === "errorOccured" && (
        <SentErrorOccured
          goBack={() => setStep("form2")}
          closeMe={handleCloseSending}
        />
      )}
    </SideSheetModal>
  );
};
