import styled from "styled-components";
import * as C from "./avatar.constants";
import IconUser from "./../Icon/tsx/BoldBasicsUsersSingle";
import * as COLOR from "./../color";
import * as CONST from "./../const";

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  size: keyof typeof C.Types;
}

interface ImageProps extends React.HTMLAttributes<HTMLDivElement> {
  $size: keyof typeof C.Types;
  $source: string;
  alt?: string; // Optional text for accessibility
}

interface InitialsProps extends React.HTMLAttributes<HTMLSpanElement> {
  $size: keyof typeof C.Types;
}

export const Avatar = styled.div<AvatarProps>`
  align-items: center;
  background-color: ${COLOR.snow};
  border-radius: ${CONST.radius_circle};
  cursor: pointer;
  display: flex;
  justify-content: center;
  ${(props) => C.AVATAR_SIZE[props.size]};
`;

export const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.$source});
  background-size: cover;
  background-position: center center;
  border-radius: ${CONST.radius_circle};
  ${(props) => C.AVATAR_SIZE[props.$size]};
`;

export const Initials = styled.span<InitialsProps>`
  display: flex;
  align-items: center;
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_bold};
  text-align: center;
  ${(props) => C.AVATAR_INITIALS_SIZE_PROPERTIES[props.$size]};
`;

export const UserIcon = styled(IconUser)`
  font-size: 1.5em;
`;
