import * as COLOR from "./../color";

const Types = {
  Black: "black",
  White: "white",
};

export const CONTAINER_MODE_PROPERTIES = {
  [Types.White]: `
  background-color: ${COLOR.white};
  color: ${COLOR.nightsky_650};
  `,
  [Types.Black]: `
  background-color: ${COLOR.nightsky_650};
  color: ${COLOR.white};
  `,
};

const Position = {
  Top: "top",
  Bottom: "bottom",
  Left: "left",
  Right: "right",
};
export const DROP_CORNERS = {
  [Position.Top]: `border-radius: 4px 4px 0 0;`,
  [Position.Bottom]: `border-radius: 0 0 4px 4px ;`,
  [Position.Left]: `border-radius: 4px 0 0 4px;`,
  [Position.Right]: `border-radius: 0 4px 4px 0;`,
};
