import { gql } from "@apollo/client";
import { apolloClient } from "./../index";
import { primoProps } from "./primos";
import {
  IFilterProperties,
  FullFilterProps,
  RecipientScope,
  AnalysisPointProps,
} from "../types";
import { fetchSetOfPhotos } from "./../auth/utils";
import { PAGE_SIZE } from "./../config";

export const addCheer = async (data: any) => {
  const CREATE_PRIMO = gql`
    mutation createCheer($data: CheerInput!) {
      createCheer(data: $data) {
        data {
          id
        }
      }
    }
  `;
  try {
    const result = await apolloClient.mutate({
      mutation: CREATE_PRIMO,
      variables: { data },
    });
    // if (result.error) throw result.errors;
    return result?.data?.createCheer?.data?.id;
  } catch (error) {
    throw error;
  }
};

export interface CheerShortProps {
  id: string;
  toGroup: boolean;
  recipientType: RecipientScope;
  groupeName: string;
  nickName: string;
  category: string; //category-name
  from: primoProps; //From Name
  to: primoProps[];
  toCc: primoProps[];
  createdAt: Date;
  sentByMe?: boolean;
}

export const getSentBy = async ({
  primoId, //From
  page,
  pageSize = PAGE_SIZE,
}: {
  primoId: string;
  page: number;
  pageSize?: number;
}): Promise<{
  data: CheerShortProps[];
  meta: { total: number; pageCount: number };
}> => {
  const query = gql`
    query getSentByPrimo($primoId: ID!, $page: Int!, $pageSize: Int!) {
      cheers(
        filters: { author: { id: { eq: $primoId } } }
        pagination: { page: $page, pageSize: $pageSize }
        sort: "createdAt:desc"
      ) {
        data {
          id
          attributes {
            title
            recipient_groupe
            recipient_type
            include_manager
            publish_to_feed
            nickname
            group_name
            ext_recipients
            ext_copies
            category {
              data {
                attributes {
                  name
                }
              }
            }
            primo_recipients {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            primo_copies {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            author {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            createdAt
          }
        }
        meta {
          pagination {
            total
            page
            pageSize
            pageCount
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { primoId, page, pageSize },
    });
    if (error) throw error;

    //else
    let _data = data?.cheers?.data?.map((x: any) => ({
      id: x.id,
      toGroup: x.attributes?.recipient_groupe,
      recipientType: x.attributes?.recipient_type,
      groupeName: x.attributes?.group_name,
      nickName: x.attributes?.nickname,
      category: x.attributes?.category?.data?.attributes?.name, //category-name
      from: {
        name: x.attributes?.author?.data?.attributes?.name,
        azureId: x.attributes?.author?.data?.attributes?.azure_id,
        // imgUrl: //Not usefull : Sent by me,
      }, //From Name
      to: x.attributes?.primo_recipients?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
        // imgUrl: fetchUserPhoto(y.attributes?.azure_id),
      })),
      toCc: x.attributes?.primo_copies?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
      })),
      createdAt: new Date(x.attributes?.createdAt),
    }));
    //add image urls
    if (_data) {
      const azureIdOfPhotoToFetch: string[] = [];
      _data.forEach((x: any) =>
        x.to.forEach((y: any) => {
          azureIdOfPhotoToFetch.push(y.azureId);
        })
      );
      await fetchSetOfPhotos(azureIdOfPhotoToFetch);
    }
    const meta = {
      total: data?.cheers?.meta?.pagination?.total,
      pageCount: data?.cheers?.meta?.pagination?.pageCount,
    };
    return { data: _data ?? [], meta };
  } catch (error) {
    throw error;
  }
};

export const getRecievedBy = async ({
  primoId, //From
  page,
  pageSize = PAGE_SIZE,
}: {
  primoId: string;
  page: number;
  pageSize?: number;
}): Promise<{
  data: CheerShortProps[];
  meta: { total: number; pageCount: number };
}> => {
  const query = gql`
    query getSentByPrimo($primoId: ID!, $page: Int!, $pageSize: Int!) {
      cheers(
        filters: { primo_recipients: { id: { eq: $primoId } } }
        pagination: { page: $page, pageSize: $pageSize }
        sort: "createdAt:desc"
      ) {
        data {
          id
          attributes {
            title
            recipient_groupe
            recipient_type
            include_manager
            publish_to_feed
            nickname
            group_name
            ext_recipients
            ext_copies
            category {
              data {
                attributes {
                  name
                }
              }
            }
            primo_recipients {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            primo_copies {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            author {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            createdAt
          }
        }
        meta {
          pagination {
            total
            page
            pageSize
            pageCount
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { primoId, page, pageSize },
    });
    if (error) throw error;

    //else
    const _data = data?.cheers?.data?.map((x: any) => ({
      id: x.id,
      toGroup: x.attributes?.recipient_groupe,
      recipientType: x.attributes?.recipient_type,
      category: x.attributes?.category?.data?.attributes?.name, //category-name
      from: {
        azureId: x.attributes?.author?.data?.attributes?.azure_id,
        name: x.attributes?.author?.data?.attributes?.name,
        // imgUrl: await fetchUserPhoto(
        //   x.attributes?.author?.data?.attributes?.azure_id
        // ),
      }, //From Name
      to: x.attributes?.primo_recipients?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
      })),
      toCc: x.attributes?.primo_copies?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
      })),
      createdAt: new Date(x.attributes?.createdAt),
    }));
    //add image urls
    if (_data) {
      const azureIdOfPhotoToFetch: string[] = [];
      _data.forEach((x: any) => {
        azureIdOfPhotoToFetch.push(x.from?.azureId);
      });
      await fetchSetOfPhotos(azureIdOfPhotoToFetch);
    }

    const meta = {
      total: data?.cheers?.meta?.pagination?.total,
      pageCount: data?.cheers?.meta?.pagination?.pageCount,
    };
    return { data: _data ?? [], meta };
  } catch (error) {
    throw error;
  }
};

export const getFeedsCards = async ({
  filter,
  page,
  pageSize = PAGE_SIZE,
}: {
  filter?: IFilterProperties;
  page: number;
  pageSize?: number;
}): Promise<{
  data: CheerShortProps[];
  meta: { total: number; pageCount: number };
}> => {
  const filterStrs = ["{publish_to_feed: { eq: true }}"]; //basic filter
  if (filter) {
    //add authors filter
    if (!!filter?.authors?.length) {
      const ids = filter.authors.map((x) => x.value);
      filterStrs.push(`{author:{azure_id: { in:["${ids?.join('","')}"]}}}`);
    }
    //add primo-recipients filter
    if (!!filter?.primoRecipients?.length) {
      const ids = filter.primoRecipients.map((x) => x.value);
      filterStrs.push(
        `{primo_recipients:{azure_id: { in:["${ids.join('","')}"]}}}`
      );
    }
    //add ext-recipients filter
    if (!!filter?.extRecipients?.length) {
      //emails
      filterStrs.push(
        `{or:[${filter?.extRecipients.map(
          (x) => `{ext_recipients:{contains:"${x}"}}`
        )}]}`
      );
    }
    if (filter?.startDate) {
      filterStrs.push(`{createdAt:{gte:"${filter.startDate.toISOString()}"}}`);
    }
    if (filter?.endDate) {
      filterStrs.push(`{createdAt:{gte:"${filter.endDate.toISOString()}"}}`);
    }
  }
  const allFilters = filterStrs.join(",");
  const query = gql`
    query getFeedsCards($page: Int!, $pageSize: Int!) {
      cheers(
        pagination: { page: $page, pageSize: $pageSize }
        filters: {and:[${allFilters}]}
        sort: "createdAt:desc"
      ) {
        data {
          id
          attributes {
            title
            recipient_groupe
            recipient_type
            include_manager
            publish_to_feed
            nickname
            group_name
            ext_recipients
            ext_copies
            category {
              data {
                attributes {
                  name
                }
              }
            }
            primo_recipients {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            primo_copies {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            author {
              data {
                attributes {
                  azure_id
                  name
                }
              }
            }
            createdAt
          }
        }
        meta {
          pagination {
            total
            page
            pageSize
            pageCount
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { page, pageSize },
    });
    if (error) throw error;

    //else
    const _data = data?.cheers?.data?.map((x: any) => ({
      id: x.id,
      toGroup: x.attributes?.recipient_groupe,
      recipientType: x.attributes?.recipient_type,
      groupeName: x.attributes?.group_name,
      nickName: x.attributes?.nickname,
      category: x.attributes?.category?.data?.attributes?.name, //category-name
      from: {
        azureId: x.attributes?.author?.data?.attributes?.azure_id,
        name: x.attributes?.author?.data?.attributes?.name,
      }, //From Name
      to: x.attributes?.primo_recipients?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
        // imgUrl: await fetchUserPhoto(y.attributes?.azure_id),
      })),
      toCc: x.attributes?.primo_copies?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
      })),
      createdAt: new Date(x.attributes?.createdAt),
    }));

    //add image urls
    if (_data) {
      const azureIdsOfPhotoToFetch: string[] = [];
      _data.forEach((x: any) =>
        x.to.forEach((y: any) => {
          azureIdsOfPhotoToFetch.push(y.azureId);
        })
      );
      await fetchSetOfPhotos(azureIdsOfPhotoToFetch);
    }
    const meta = {
      total: data?.cheers?.meta?.pagination?.total,
      pageCount: data?.cheers?.meta?.pagination?.pageCount,
    };
    return { data: _data ?? [], meta };
  } catch (error) {
    throw error;
  }
};

/// DETAILS

export interface TemplateProps {
  headerImgUrl?: string;
  footerImgUrl?: string;
  message?: string;
}
export interface CheerDetailsProps extends CheerShortProps {
  title?: string;
  message?: string;
  template?: TemplateProps;
}

export const getCheerDetailsById = async (
  cheerId: string
): Promise<CheerDetailsProps> => {
  const query = gql`
    query getCheerById($id: ID!) {
      cheer(id: $id) {
        data {
          id
          attributes {
            title
            recipient_groupe
            recipient_type
            include_manager
            publish_to_feed
            nickname
            group_name
            ext_recipients
            ext_copies
            message
            email_sent
            category {
              data {
                id
                attributes {
                  name
                }
              }
            }
            template {
              data {
                id
                attributes {
                  title
                  header_img {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  footer_img {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  message
                }
              }
            }
            primo_recipients {
              data {
                id
                attributes {
                  azure_id
                  name
                  country
                  department
                }
              }
            }
            primo_copies {
              data {
                id
                attributes {
                  azure_id
                  name
                  country
                  department
                }
              }
            }
            author {
              data {
                id
                attributes {
                  azure_id
                  name
                  country
                  department
                }
              }
            }
            createdAt
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { id: cheerId },
    });
    if (error) throw error;

    //else
    const _data = data?.cheer?.data?.attributes;
    const _from = _data?.author?.data?.attributes;
    const _to = _data?.primo_recipients?.data;
    const _toCc = _data?.primo_copies?.data;
    const _template = _data?.template;
    const templateHeaderImg =
      _template?.data?.attributes.header_img?.data?.attributes?.url;
    const templateFooterImg =
      _template?.data?.attributes?.footer_img?.data?.attributes?.url;
    const templateMessage = _template?.data?.attributes.message;
    //
    // if (templateHeaderImg) {
    //   templateHeaderImg = `${process.env.REACT_APP_API_BASE_URL}${templateHeaderImg}`;
    // }
    // if (templateFooterImg) {
    //   templateFooterImg = `${process.env.REACT_APP_API_BASE_URL}${templateFooterImg}`;
    // }

    const _details = {
      id: cheerId,
      toGroup: _data?.recipient_groupe,
      recipientType: _data?.recipient_type,
      groupeName: _data?.group_name,
      nickName: _data?.nickname,
      category: _data?.category?.data?.attributes?.name, //category-name
      template: _template && {
        headerImgUrl: templateHeaderImg,
        footerImgUrl: templateFooterImg,
        message: templateMessage,
      },
      from: {
        azureId: _from?.azure_id,
        name: _from?.name,
        country: _from?.country,
        department: _from?.department,
      },
      to: _to?.map((x: any) => ({
        azureId: x?.attributes?.azure_id,
        name: x?.attributes?.name,
        country: x?.attributes?.country,
        department: x?.attributes?.department,
        // imgUrl: fetchUserPhoto(x?.attributes?.azure_id),
      })),
      toCc: _toCc?.map((x: any) => ({
        name: x?.attributes?.name,
        country: x?.attributes?.country,
        department: x?.attributes?.department,
      })),
      createdAt: _data?.createdAt && new Date(_data?.createdAt),
      title: _data?.title,
      message: _data?.message,
    };
    return _details;
  } catch (error) {
    throw error;
  }
};

/// For Trend Analysis
export interface Cheers4analysisProps {}

const getFilterStr = (filter: FullFilterProps | undefined) => {
  const filterStrs = []; //basic filter

  // const published2feeds = "{publish_to_feed: { eq: true }}";//TODO

  if (filter) {
    //AUTHORS FILTERS
    //1/ Authors countries
    if (!!filter.authorCountries?.length) {
      const names = filter.authorCountries.map((x) => x.value);
      filterStrs.push(`{author:{country: { in:["${names?.join('","')}"]}}}`);
    }
    //2/ Authors departments
    if (!!filter.authorDepartments?.length) {
      const names = filter.authorDepartments.map((x) => x.value);
      filterStrs.push(`{author:{department: { in:["${names?.join('","')}"]}}}`);
    }
    //3/ Authors names filter
    if (!!filter?.authors?.length) {
      const ids = filter.authors.map((x) => x.value);
      filterStrs.push(`{author:{azure_id: { in:["${ids?.join('","')}"]}}}`);
    }

    //RECIPIENTS FILTERS
    //1/ Recipients countries
    if (!!filter.recipientCountries?.length) {
      const names = filter.recipientCountries.map((x) => x.value);
      filterStrs.push(
        `{primo_recipients:{country: { in:["${names?.join('","')}"]}}}`
      );
    }
    //2/ Recipients departments
    if (!!filter.recipientDepartments?.length) {
      const names = filter.recipientDepartments.map((x) => x.value);
      filterStrs.push(
        `{primo_recipients:{department: { in:["${names?.join('","')}"]}}}`
      );
    }
    //3/ Recipients names filter
    if (!!filter?.primoRecipients?.length) {
      const ids = filter.primoRecipients.map((x) => x.value);
      filterStrs.push(
        `{primo_recipients:{azure_id: { in:["${ids?.join('","')}"]}}}`
      );
    }
    //4/ ext-recipients filter
    if (!!filter?.extRecipients?.length) {
      //emails
      filterStrs.push(
        `{or:[${filter?.extRecipients.map(
          (x) => `{ext_recipients:{contains:"${x}"}}`
        )}]}`
      );
    }
    //5/ Recipients is Group ?
    if (!!filter?.recipientsType?.length) {
      filterStrs.push(
        `{recipient_groupe:{ eq:${
          filter.recipientsType === "Group" ? "true" : "false"
        }}}`
      );
    }
    //6/ Recipients scope : "primo" "external" "mixed"
    if (!!filter?.recipientsScope?.length) {
      filterStrs.push(`{recipient_type:{ eq:"${filter.recipientsScope}"}}`);
    }

    //OTHER PROPERTIES
    //1/ Start Date
    if (filter?.startDate) {
      filterStrs.push(`{createdAt:{gte:"${filter.startDate.toISOString()}"}}`);
    }
    //2/ End Date
    if (filter?.endDate) {
      filterStrs.push(`{createdAt:{lte:"${filter.endDate.toISOString()}"}}`);
    }
    //Categories
    if (!!filter.categories?.length) {
      const names = filter.categories.map((x) => x.value);
      filterStrs.push(`{category:{name: { in:["${names?.join('","')}"]}}}`);
    }
    //Manager Included
    if (filter.managerIncluded === true || filter.managerIncluded === false) {
      filterStrs.push(`{include_manager:{ eq:${filter.managerIncluded}}}`);
    }
    //Pushed To Feed
    if (filter.pushedToFeed === true || filter.pushedToFeed === false) {
      filterStrs.push(`{publish_to_feed:{ eq:${filter.pushedToFeed}}}`);
    }
  }
  const allFilters = filterStrs.join(",");
  return `{and:[${allFilters}]}`;
};

interface getCheers4analysisProps {
  items: AnalysisPointProps[];
  pagesCount: number;
}

export const getCheers4analysis = async (
  filter: FullFilterProps,
  page?: number,
  pageSize?: number
): Promise<getCheers4analysisProps> => {
  const filtersStr = getFilterStr(filter);
  const paginationStr =
    page && pageSize
      ? `{ page: ${page}, pageSize: ${pageSize} }`
      : "{ limit: -1 }";

  const query = gql`
    query getFeedsCards {
      cheers(
        pagination: ${paginationStr}
        filters: ${filtersStr}
        sort: "createdAt:desc"
      ) {
        data {
          attributes {
            createdAt
            author {
              data {
                attributes {
                  azure_id
                  name 
                  country
                  department
                }
              }
            }
            recipient_groupe
            recipient_type
            include_manager
            publish_to_feed
            primo_recipients {
              data {
                attributes {
                  azure_id
                  name
                  country
                  department
                }
              }
            }
            ext_recipients
            category {
              data {
                attributes {
                  name
                }
              }
            }
          }
        }
        meta {
          pagination {
            total
            page
            pageSize
            pageCount
          }
        }
      }
    }

    
  `;

  try {
    const { error, data } = await apolloClient.query({
      query,
    });
    if (error) throw error;

    const _pagination = data?.cheers?.meta?.pagination;
    //else
    const _data: AnalysisPointProps[] = data?.cheers?.data?.map((x: any) => ({
      date: new Date(x.attributes?.createdAt),
      from: {
        azureId: x.attributes?.author?.data?.attributes?.azure_id,
        name: x.attributes?.author?.data?.attributes?.name,
        country: x.attributes?.author?.data?.attributes?.country,
        department: x.attributes?.author?.data?.attributes?.department,
      }, //From Name
      toPrimo: x.attributes?.primo_recipients?.data?.map((y: any) => ({
        azureId: y.attributes?.azure_id,
        name: y.attributes?.name,
        country: y.attributes?.country,
        department: y.attributes?.department,
        // imgUrl: await fetchUserPhoto(y.attributes?.azure_id),
      })),
      toExternal: x.attributes?.ext_recipients?.split(";"),
      recipientType: x.attributes?.recipient_groupe ? "Group" : "Individual",
      recipientScope: x.attributes?.recipient_type,
      includedManager: x.attributes?.include_manager,
      publishedToFeed: x.attributes?.publish_to_feed,
      category: x.attributes?.category?.data?.attributes?.name, //category-name
    }));

    return { items: _data ?? [], pagesCount: _pagination?.pageCount };
  } catch (error) {
    throw error;
  }
};
