import styled, { css } from "styled-components";
import IconButtonDown from "./../../../Icon/tsx/InterfaceArrowsButtonDown";
import * as COLOR from "./../../../color";
import * as CONST from "./../../../const";

// Define the props types
interface InfiniteScrollLoopInnerProps {
  $displayed?: number;
  $itemHeight: number;
}

interface ItemRowProps {
  active?: boolean;
  $itemHeight: number;
}

const noselect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export const Container = styled.div``;

export const InfiniteScrollLoopOuter = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      #fff 0%,
      rgba(255, 255, 255, 0) calc(15%),
      rgba(255, 255, 255, 0) calc(85%),
      #fff 100%
    );
    pointer-events: none;
  }
`;

export const InfiniteScrollLoopInner = styled.div<InfiniteScrollLoopInnerProps>`
  overflow-y: scroll;
  /* scroll-behavior: smooth; */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.$displayed &&
    `height: calc(${props.$displayed} * ${props.$itemHeight}px);`}

  width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemRow = styled.div<ItemRowProps>`
  ${noselect}
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: ${COLOR.ashes};
  font-weight: ${CONST.weight_semi_bold};

  ${(props) =>
    props.active &&
    `font-family: ${CONST.font_family_body}; 
    font-weight: ${CONST.weight_semi_bold};
    background-color: ${COLOR.interaction};
    color: ${COLOR.white};`}

  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  border-radius: 50%;

  :focus {
    font-family: ${CONST.font_family_body};
    font-weight: ${CONST.weight_semi_bold};
    background-color: ${COLOR.interaction};
    color: ${COLOR.white};
  }

  height: ${(props) => `${props.$itemHeight}px`};
  width: ${(props) => `${props.$itemHeight}px`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const arrowIconCss = css`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  height: 16px;
  font-size: 0.5em;

  &:focus {
    outline: none;
  }
`;

export const ArrowIconNext = styled(IconButtonDown)`
  ${arrowIconCss}
`;

export const ArrowIconPrev = styled(IconButtonDown)`
  ${arrowIconCss}
  transform: rotate(180deg);
`;

export const CircularNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
