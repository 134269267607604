import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgBoldBasicsUsersSingle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    {...props}
  >
    <path d="M21.85 17.683c-.626-1.225-2.768-1.992-5.73-3.056-.565-.2-.92-.284-1.079-.731a1.4 1.4 0 0 1 .133-1.324c1.174-1.239 1.75-3.094 1.75-5.489 0-3.166-2.22-4.583-4.424-4.583S8.075 3.917 8.075 7.083c0 2.395.577 4.25 1.751 5.489.266.393.315.888.133 1.324-.159.447-.513.53-1.078.731-2.963 1.064-5.105 1.834-5.732 3.056a8.6 8.6 0 0 0-.899 3.567c0 .23.191.417.427.417h19.646a.42.42 0 0 0 .427-.417 8.6 8.6 0 0 0-.9-3.567" />
  </svg>
);
const Memo = memo(SvgBoldBasicsUsersSingle);
export default Memo;
