export const spacing_xxs = "3px";
export const spacing_xs = "4px";
export const spacing_s = "8px";
export const spacing_m = "24px";
export const spacing_l = "32px";
export const spacing_xl = "40px";
export const spacing_status_xxs = "3px";
export const spacing_status_xs = "4px";
export const spacing_status_s = "6px";
export const spacing_status_m = "8px";
