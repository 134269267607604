//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import * as S from "./toggle.style";

//children: React.ReactNode; //could be label or Icon, or other (as a react component)
type IToggleOption = {
  value: string;
  label: React.ReactNode;
  onClick?: (v: string) => void;
};
export interface IToggle {
  /** Screen width occupancy. If 'auto', the component will have the width of the container */
  auto?: boolean;

  /** The button will have the same widh */
  sameWidth?: boolean;

  /** Two variants are possible */
  variant?: "default" | "primary";

  /** The size (height) of the component */
  size?: "s" | "m" | "l";

  /** If true, at least one should be choosen */
  required?: boolean;

  /** The selected option */
  value?: string;

  /** Event to be fired when option is selected */
  onChange: (v: string) => void;

  /** An array of options:[{value, label}].
   * value: a string value to return onChange.
   * label: a string or a React.Node (could be an icon or other content)
   */
  options: IToggleOption[];
}

export const Toggle = ({
  auto = false,
  sameWidth = false,
  variant = "default",
  size = "l",
  required = true,
  value,
  onChange,
  options,
}: IToggle) => {
  const [currentOptionIndex, setCurrentOptionIndex] = useState(-1);
  const [indicatorLeft, setIndicatorLeft] = useState(null);
  const [indicatorWidth, setIndicatorWidth] = useState(null);
  const optionRefs = useRef({});

  useEffect(() => {
    let index = options.findIndex((op) => op.value === value);
    if (required && index < 0) {
      index = 0;
      if (options?.length > 0) {
        onChange(options[0].value);
      }
    }
    if (index < 0) {
      setCurrentOptionIndex(-1);
      setIndicatorWidth(null);
      setIndicatorLeft(null);
    } else {
      const optionRef = optionRefs?.current[index];
      setCurrentOptionIndex(index);
      setIndicatorWidth(`${optionRef.offsetWidth}px`);
      setIndicatorLeft(`${optionRef.offsetLeft}px`);
    }
  }, [value]);

  const handleClick = (val) => {
    const currValue =
      currentOptionIndex < 0 ? undefined : options[currentOptionIndex].value;

    if (val === currValue) {
      if (!required) return onChange(undefined);
      //else nothing
    } else {
      onChange(val);
    }
  };

  return (
    <S.Container $auto={auto} $size={size} $variant={variant}>
      <S.Options size={size} $variant={variant}>
        {currentOptionIndex >= 0 && (
          <S.ActiveIndicator
            $size={size}
            $variant={variant}
            $offset={{ width: indicatorWidth, left: indicatorLeft }}
          />
        )}
        {options.map((option, i) => {
          return (
            <S.Option
              key={i}
              ref={(ref) => (optionRefs.current[i] = ref)}
              onClick={(e) => {
                option.onClick && option.onClick(e);
                handleClick(option.value);
              }}
              value={option.value}
              $sameWidth={sameWidth}
              $sizevariant={variant}
              $size={size}
              $isActive={currentOptionIndex === i}
              $noValue={!value}
            >
              <S.OptionLabel>{option.label}</S.OptionLabel>
            </S.Option>
          );
        })}
      </S.Options>
    </S.Container>
  );
};
