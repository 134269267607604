//@ts-nocheck
import React, { useState, useEffect } from "react";
import {
  InputContainer,
  WithLabel,
  WithHelperMessage,
  WithInformation,
} from "./../utils/inputCommon";

import {
  ICommonInput,
  IWithInformation,
  IWithHelperMessage,
  IInputContainer,
  IWithLabel,
} from "./../utils/inputCommon/interfaces";

export interface ITextArea
  extends IWithLabel,
    IWithHelperMessage,
    IWithInformation,
    IInputContainer,
    ICommonInput {
  /**The maximum number of characters (UTF-16 code units) that the user can enter. If this value isn't specified, the user can enter an unlimited number of characters. */
  maxLength?: number;
  /**The minimum number of characters (UTF-16 code units) required that the user should enter. */
  minLength?: number;
  /**If true, the length of the text will be shown. */
  showLength?: boolean;
  /**Fire on input change. */
  onChange?: (val: string) => void;
  /**The value of the input. */
  value: string;
  /**Fire on input click. */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /**The min height of the textarea. So usefull only when textarea. */
  minHeight?: string;
  /**The max height of the textarea. So usefull only when textarea. */
  maxHeight?: string;
}

export const TextArea = React.forwardRef<HTMLInputElement, ITextArea>(
  (
    {
      onChange,
      value = "",
      name = null,
      minHeight = "90px",
      maxHeight,
      //
      maxLength,
      minLength,
      showLength = maxLength || minLength,
      //
      disabled = false, //InputContainer
      size = "l", //IWithLabel & InputContainer
      onClick,
      //
      informationMessage = "", //IWithInformation
      informationPosition = "bottom", //IWithInformation
      //
      helperMessage = null, //IWithHelperMessage
      alertState = undefined, //IWithHelperMessage & IWithLabel & InputContainer
      label = undefined, //IWithLabel
      placeholder = undefined, //ItWithLabel
      rightSideNode = undefined, //InputContainer
      leftSideNode = undefined, //InputContainer
      stuckPlacement = undefined, //InputContainer
    },
    ref
  ) => {
    const [countMessage, setCountMessage] = useState(undefined);

    useEffect(() => {
      if (!showLength) return;
      //else
      let _length = showLength
        ? value
          ? value.length.toString()
          : "0"
        : undefined;

      if (_length !== undefined && maxLength) {
        _length += ` / ${maxLength}`;
      }
      setCountMessage(_length);
    }, [showLength, maxLength, value]);

    function HandleTextAreaHeight(e) {
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
      // In case you have a limitation
      // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }

    return (
      <WithHelperMessage
        alertState={alertState}
        helperMessage={helperMessage}
        rightBottomMessage={countMessage}
      >
        <WithInformation
          informationMessage={informationMessage}
          informationPosition={informationPosition}
          alignCenter={false}
        >
          <InputContainer
            textArea
            minTextAreaHeight={minHeight}
            maxTextAreaHeight={maxHeight}
            alertState={alertState}
            disabled={disabled}
            rightSideNode={rightSideNode}
            leftSideNode={leftSideNode}
            stuckPlacement={stuckPlacement}
            onClick={onClick}
          >
            <WithLabel
              textArea
              empty={!(value?.length > 0)}
              size={size}
              label={label}
              hasPlaceholder={placeholder?.length > 0}
              alertState={alertState}
            >
              <textarea
                style={{
                  resize: "none",
                }}
                ref={ref}
                name={name}
                maxLength={maxLength}
                minLength={minLength}
                value={value}
                onChange={(e) => {
                  HandleTextAreaHeight(e);
                  onChange(e.target.value);
                }}
                placeholder={placeholder ?? label} //if no placeholder take the label if defined
                disabled={disabled}
              />
            </WithLabel>
          </InputContainer>
        </WithInformation>
      </WithHelperMessage>
    );
  }
);
