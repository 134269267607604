import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgBoldBasicsOnOffPowerButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10.2 2.7c.3-.2.7-.2.9 0 1.9 1 3.2 2.8 3.5 5 .3 2.1-.4 4.2-1.9 5.7-2 2-5.1 2.5-7.7 1.2s-4-4.1-3.6-6.9c.3-2.1 1.6-4 3.5-5 .4-.2 1-.1 1.2.4.2.4.1 1-.4 1.2-1.3.8-2.3 2.1-2.5 3.7-.2 1.5.3 3 1.4 4.1 1.9 1.9 4.9 1.9 6.8 0C12.5 11 13 9.5 12.8 8s-1.2-2.9-2.6-3.6c-.4-.3-.6-.8-.4-1.3.1-.1.2-.3.4-.4M8 .7c.5 0 .9.4.9.9v5.8c0 .5-.4.9-.9.9s-.9-.5-.9-1V1.6c0-.5.4-.9.9-.9" />
  </svg>
);
const Memo = memo(SvgBoldBasicsOnOffPowerButton);
export default Memo;
