import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FirstLoad from "./pages/FirstLoad";
import Home from "./pages/Home";
import Feed from "./pages/Feed";
import Help from "./pages/Help";
import Start from "./pages/Start";
import NotFound from "./pages/NotFound";
import AnalysisPage from "./pages/AnalysisPage";
import { AuthContext } from "./auth/AuthProvider";

// import FindManager from "./auth/FindManager";
// import FindUsers from "./auth/FindUsers";

function App() {
  const me = useContext(AuthContext);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/first-load" element={<FirstLoad />} />
        <Route path="/home" element={<Home />} />
        <Route path="/feed" element={<Feed />} />
        {me?.analysisAuthorization && (
          <Route path="/analysis" element={<AnalysisPage />} />
        )}
        {/* <Route path="/aaa" element={<FindManager />} /> */}
        {/* <Route path="/bbb" element={<FindUsers />} /> */}
        {/* <Route path="/start" element={<Start />} /> */}
        <Route path="/help" element={<Help />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
