//@ts-nocheck
import React from "react";
import * as S from "./checkbox.style";

export interface ICheckBox {
  /**The label of the checkbox. */
  label: React.ReactNode;
  /**If true, the component will be display on full width. */
  auto?: boolean;
  /**If true, the checkbox will be disabled. */
  disabled?: boolean;
  /**If true, checkbox will be checked*/
  isChecked?: boolean;
  /**If true, the checkbox will be displayed on the right. */
  inverted?: boolean;
  /**Fired when the checkbox or the label is clicked. Returns the suggested new value to set outside (controlled). */
  onClick?: (v: boolean, e: React.MouseEvent<HTMLElement>) => void;
  /**Fired when the checkbox is value change from checked. Returns the suggested new value to set outside (controlled).*/
  onChange?: (v: boolean, e: React.MouseEvent<HTMLElement>) => void;
  /**The size of the checkbox. */
  size?: "xxs" | "xs";
}

export const Checkbox = ({
  label,
  auto = false,
  disabled = false,
  isChecked = false,
  inverted = false,
  onClick,
  onChange,
  size = "xs",
}: ICheckBox) => {
  const handleClick = React.useCallback((e) => {
    const suggestedNewValue = !isChecked;
    if (onClick) {
      onClick(suggestedNewValue, e);
    }
    if (onChange) {
      onChange(suggestedNewValue, e);
    }
  }, []);

  return (
    <S.Wrapper
      $auto={auto}
      disabled={disabled}
      $inverted={inverted}
      $size={size}
    >
      <div
        className={`checkbox__control--wrapper ${
          isChecked && "checkbox__input__checked"
        }`}
        onClick={handleClick}
      >
        {inverted && (
          <S.Label size={size} disabled={disabled}>
            {label}
          </S.Label>
        )}
        <div className="checkbox__control" />
        {!inverted && (
          <S.Label size={size} disabled={disabled}>
            {label}
          </S.Label>
        )}
      </div>
    </S.Wrapper>
  );
};
