import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgTrends = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    className="trends_svg__si-glyph trends_svg__si-glyph-chart-column-increase"
    viewBox="0 -0.5 17 17"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M1 15h15.938v.969H1zM15.906.065 11.15.94l1.609 1.609-4.78 4.057L3.9 3.274a.5.5 0 1 0-.658.753l4.745 3.914 5.479-4.685L15.15 4.94zM12 7h2.951v6.878H12zM2 8h2.982v5.878H2zM7 10h2.951v3.892H7z"
        className="trends_svg__si-glyph-fill"
      />
    </g>
  </svg>
);
const Memo = memo(SvgTrends);
export default Memo;
