// Define types for the keys
type SizeTypes = "l" | "xl";
type PositionTypes = "top" | "bottom" | "left" | "right";

// // Create constant objects with typed keys
// const Types: Record<"Large" | "XLarge", SizeTypes> = {
//   Large: "l",
//   XLarge: "xl",
// };

export const FONT_SIZE: Record<SizeTypes, string> = {
  l: "14px", // Use 'l' directly here
  xl: "16px", // Use 'xl' directly here
};

// const Position: Record<"Top" | "Bottom" | "Left" | "Right", PositionTypes> = {
//   Top: "top",
//   Bottom: "bottom",
//   Left: "left",
//   Right: "right",
// };

export const CUSTOM_BORDER_RADIUS: Record<PositionTypes, string> = {
  top: "border-radius: 0 0 4px 4px;",
  bottom: "border-radius: 4px 4px 0 0;",
  right: "border-radius: 4px 0 0 4px;",
  left: "border-radius: 0 4px 4px 0;",
};
