import React from "react";
import { ButtonTrendIcon } from "./ButtonTrendIcon";
import { Button } from "../../../components/Button";
import { ChartLineProps } from "./../../../types";
import styles from "./styles.module.scss";

export interface SummaryLineProps {
  rank?: number;
  label: string;
  count: number;
  active?: boolean;
  setActive?: () => void;
  dates?: Date[];
  charts?: ChartLineProps[];
  onChartsChange?: (v: ChartLineProps[]) => void;
  width?: number;
}

export const SummaryLine: React.FC<SummaryLineProps> = ({
  rank,
  label,
  count,
  active,
  setActive,
  dates,
  charts,
  onChartsChange,
  width,
}) => {
  return (
    <div
      className={styles.summaryLine}
      onClick={setActive}
      style={{ width: !width ? "100%" : `${width}px` }}
    >
      <Button
        corners="squared"
        onClick={setActive}
        variant={active ? "primary" : "tertiary"}
        size="s"
        auto
      >
        <div className={styles.keyVal}>
          {rank && <div className={styles.rank}>{rank}/</div>}
          <div className={styles.label}>{label}</div>
          <div className={styles.count}>{count}</div>
        </div>
      </Button>

      {dates && charts && onChartsChange && (
        <div className={styles.summaryLineIcon}>
          <ButtonTrendIcon
            trendName={label}
            dates={dates}
            charts={charts}
            onChartsChange={onChartsChange}
          />
        </div>
      )}
    </div>
  );
};
