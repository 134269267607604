import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M23 4a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3zm-2 0a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1z"
      clipRule="evenodd"
    />
    <path d="M4.807 17.521 9.122 9.61a1 1 0 0 1 1.756 0l3.169 5.81 1.085-1.9a1 1 0 0 1 1.736 0l2.277 3.985A1 1 0 0 1 18.277 19H5.685a1 1 0 0 1-.878-1.479M18 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
  </svg>
);
const Memo = memo(SvgImage);
export default Memo;
