import React, { useEffect, useState } from "react";

//Components
import { Icon } from "../../../components/Icon";
import { SingleDatePicker } from "../../../components/Picker";
import { Select } from "../../../components/Select";
import { Toggle } from "../../../components/Toggle";
import { BasicButton } from "../../../components/Button";
//
import { SelectCountries } from "./SelectCountries";
import { SelectDepartments } from "./SelectDepartments";
import { SelectPersons } from "./SelectPersons";
//Types
import {
  ISelectMenuOption,
  RecipientType,
  RecipientScope,
  FullFilterProps,
} from "../../../types";
import { MAIN_COLOR } from "../../../config";
import styles from "./styles.module.scss";

interface AnalysisFilterProps {
  filter: FullFilterProps;
  onFilterChange: (props: FullFilterProps) => void;
  closeMe: () => void;
}

export type CheersDirection = "source" | "target";

function minWithToday(date?: Date): Date {
  const today = new Date();
  if (!date) return today;
  return date < today ? date : today;
}

export const AnalysisFilter: React.FC<AnalysisFilterProps> = ({
  filter,
  closeMe,
  onFilterChange,
}) => {
  const [inFilter, setInFilter] = useState<FullFilterProps>(filter);
  const today = new Date();
  return (
    <div className={styles.filterWrapper}>
      <div className={styles.closeIcon} onClick={closeMe}>
        <Icon name="Cross" size={32} />
      </div>
      <div className={styles.filterBodyWrap}>
        <div className={styles.filterBody}>
          <div className={styles.filterSection}>
            <div className={styles.filterSectionHeader}>Authors Filters</div>
            <SelectCountries
              directionType="Source"
              selected={inFilter?.authorCountries}
              onSelect={(v) => setInFilter({ ...inFilter, authorCountries: v })}
            />
            <SelectDepartments
              directionType="Source"
              selected={inFilter?.authorDepartments}
              onSelect={(v) =>
                setInFilter({ ...inFilter, authorDepartments: v })
              }
            />
            <SelectPersons
              label="Authors"
              selected={inFilter?.authors}
              onSelect={(v) => setInFilter({ ...inFilter, authors: v })}
            />
          </div>
          <div className={styles.filterSection}>
            <div className={styles.filterSectionHeader}>Recipients Filters</div>
            <SelectCountries
              directionType="Target"
              selected={inFilter?.recipientCountries}
              onSelect={(v) =>
                setInFilter({ ...inFilter, recipientCountries: v })
              }
            />
            <SelectDepartments
              directionType="Target"
              selected={inFilter?.recipientDepartments}
              onSelect={(v) =>
                setInFilter({ ...inFilter, recipientDepartments: v })
              }
            />
            <SelectPersons
              label="Recipients"
              selected={inFilter?.primoRecipients}
              onSelect={(v) => setInFilter({ ...inFilter, primoRecipients: v })}
            />
            {/* <SelectPersons selected={} onSelect={} /> ext recipient */}
            <Toggle
              auto
              value={inFilter.recipientsType}
              onChange={(v) => {
                setInFilter({
                  ...inFilter,
                  recipientsType: v as RecipientType | undefined,
                  recipientsScope: undefined,
                });
              }}
              options={[
                {
                  value: "Individual",
                  label: "@Individual",
                },
                {
                  value: "Group",
                  label: "@Group",
                },
              ]}
              required={false}
            />

            {inFilter.recipientsType && (
              <Toggle
                auto
                onChange={(v) => {
                  setInFilter({
                    ...inFilter,
                    recipientsScope: v as RecipientScope | undefined,
                  });
                }}
                options={
                  inFilter.recipientsType === "Group"
                    ? [
                        {
                          label: "@Primos",
                          value: "Primo",
                        },
                        {
                          label: "@Externals",
                          value: "External",
                        },
                        {
                          label: "@Mixed",
                          value: "Mixed",
                        },
                      ]
                    : [
                        {
                          label: "@Primos",
                          value: "Primo",
                        },
                        {
                          label: "@Externals",
                          value: "External",
                        },
                      ]
                }
                required={false}
                value={inFilter.recipientsScope}
              />
            )}
          </div>
          <div className={styles.filterSection}>
            <div className={styles.filterSectionHeader}>Miscellaneous</div>
            <SingleDatePicker
              size="xl"
              label="Start date"
              onChange={(d) => setInFilter({ ...inFilter, startDate: d })}
              value={inFilter.startDate}
              minWidthAsCalendar={false}
              maxDate={minWithToday(inFilter.endDate)}
            />
            <SingleDatePicker
              size="xl"
              label="End date"
              onChange={(d) => setInFilter({ ...inFilter, endDate: d })}
              value={inFilter.endDate}
              minWidthAsCalendar={false}
              minDate={inFilter.startDate}
              maxDate={today}
            />
            {/* <DoubleDatePicker
              size="xl"
              label="Date"
              startDate={inFilter.startDate}
              endDate={inFilter.endDate}
              startLabel="Start date"
              endLabel="End date"
              onStartChange={(d) => setInFilter({ ...inFilter, startDate: d })}
              onEndChange={(d) => setInFilter({ ...inFilter, endDate: d })}
            /> */}
            <Toggle
              auto
              value={
                inFilter.managerIncluded === true
                  ? "true"
                  : inFilter.managerIncluded === false
                  ? "false"
                  : undefined
              }
              onChange={(v) => {
                setInFilter({
                  ...inFilter,
                  managerIncluded:
                    v === "true" ? true : v === "false" ? false : undefined,
                });
              }}
              options={[
                {
                  value: "true",
                  label: "Manager Included",
                },
                {
                  value: "false",
                  label: "Manager Not Included",
                },
              ]}
              required={false}
            />
            <Toggle
              auto
              value={
                inFilter.pushedToFeed === true
                  ? "true"
                  : inFilter.pushedToFeed === false
                  ? "false"
                  : undefined
              }
              onChange={(v) => {
                setInFilter({
                  ...inFilter,
                  pushedToFeed:
                    v === "true" ? true : v === "false" ? false : undefined,
                });
              }}
              options={[
                {
                  value: "true",
                  label: "Added To Feeds",
                },
                {
                  value: "false",
                  label: "Not Added To Feeds",
                },
              ]}
              required={false}
            />
          </div>
        </div>
        <div className={styles.submitButton}>
          <BasicButton
            onClick={() => {
              onFilterChange(inFilter);
            }}
            label="Apply Filters"
            variant="primary"
            size="xl"
            corners="squared"
            auto
            color={MAIN_COLOR}
          />
        </div>
      </div>
    </div>
  );
};
