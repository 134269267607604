import React, { useEffect, useState } from "react";
import { IconButton } from "../../../components/Button";
import { Icon } from "../../../components/Icon";
import getColor from "../colors";
import { ChartLineProps } from "../../../types";
import styles from "./styles.module.scss";

interface ButtonTrendIconProps {
  trendName: string;
  dates?: Date[];
  charts: ChartLineProps[];
  onChartsChange: (v: ChartLineProps[]) => void;
}
export const ButtonTrendIcon: React.FC<ButtonTrendIconProps> = ({
  trendName,
  dates,
  charts,
  onChartsChange,
}) => {
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setActive(charts.some((x) => x.name === trendName));
  }, [charts, trendName]);

  const toggleTrend = (event: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();

    if (!dates?.length) return;
    //else
    let newCharts = charts;
    const index = charts.findIndex((chart) => chart.name === trendName);
    if (index !== -1) {
      newCharts = charts.filter((_, i) => i !== index);
    } else {
      const color = getColor(charts?.length);
      newCharts = [...charts, { name: trendName, color, dates }];
    }
    onChartsChange(newCharts);
  };

  return (
    <div className={active ? styles.active : styles.notActive}>
      <IconButton
        // corners="rounded"
        icon={<Icon name="Trends" size={16} />}
        onClick={(e) => toggleTrend(e)}
        variant={active ? "primary" : "tertiary"}
        size="s"
        auto
      />
    </div>
  );
};
