import styled from "styled-components";
import * as C from "./badge.constants";
import * as COLOR from "./../color";
import * as CONST from "./../const";

// Define the type for the props used in Caption
interface CaptionProps {
  $size: keyof typeof C.CAPTION_SIZE_PROPERTIES;
  $variant: keyof typeof C.CAPTION_VARIANT_PROPERTIES;
}

// Define the type for the props used in Container
interface ContainerProps extends CaptionProps {
  $caption: number;
}

export const Caption = styled.span<CaptionProps>`
  color: ${COLOR.carbon};
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  ${(props) => C.CAPTION_SIZE_PROPERTIES[props.$size]};
  ${(props) => C.CAPTION_VARIANT_PROPERTIES[props.$variant]};
`;

export const Container = styled.div<ContainerProps>`
  align-items: center;
  background: ${COLOR.silver};
  border-radius: 2em;
  display: inline-flex;
  justify-content: center;
  min-height: 20px;
  ${(props) =>
    props.$caption <= 0 &&
    `
display: none;
`}
  ${(props) => C.CONTAINER_SIZE_PROPERTIES[props.$size]};
  ${(props) => C.CONTAINER_VARIANT_PROPERTIES[props.$variant]};
`;
