import React, { useRef, ReactNode } from "react";
import { Modal } from "./../Modal";
import { SideSheetModal } from "./../SideSheet";
import { LabelButton } from "./../Button";
import { Icon } from "./../Icon";
import { useMobileSizeCheck } from "./../utils/useMediaSizeCheck";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import styles from "./style.module.scss";

export interface PrintablePostCardProps {
  children: ReactNode;
  visible: boolean;
  onCloseMe: () => void;
}

export const PrintablePostCard: React.FC<PrintablePostCardProps> = ({
  children,
  visible,
  onCloseMe,
}) => {
  const narrowMedia = useMobileSizeCheck();
  const divRef = useRef<HTMLDivElement>(null);

  const loadImages = (element: HTMLElement): Promise<void[]> => {
    const images = Array.from(element.getElementsByTagName("img"));
    const promises = images.map((img) => {
      return new Promise<void>((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => resolve(); // handle image errors if necessary
        }
      });
    });
    return Promise.all(promises);
  };

  // Download the content as an image
  // const downloadAsImage = () => {
  //   if (divRef.current) {
  //     loadImages(divRef.current).then(() => {
  //       html2canvas(divRef.current!, { useCORS: true }).then((canvas) => {
  //         const link = document.createElement("a");
  //         link.href = canvas.toDataURL("image/jpeg");
  //         link.download = "div-content.jpg";
  //         link.click();
  //       });
  //     });
  //   }
  // };

  const downloadAsImage = async () => {
    if (divRef.current) {
      // Dynamically import html2canvas
      const { default: html2canvas } = await import("html2canvas");

      // Load images before capturing the canvas
      await loadImages(divRef.current);

      // Capture the div as canvas
      html2canvas(divRef.current, { useCORS: true }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/jpeg");
        link.download = "div-content.jpg";
        link.click();
      });
    }
  };

  // // Download the content as a PDF
  // const downloadAsPDF = () => {
  //   if (divRef.current) {
  //     loadImages(divRef.current).then(() => {
  //       html2canvas(divRef.current!, { useCORS: true }).then((canvas) => {
  //         const imgData = canvas.toDataURL("image/jpeg");
  //         // Import jsPDF dynamically
  //       const { jsPDF } = await import("jspdf");
  //         const pdf = new jsPDF();

  //         const imgWidth = pdf.internal.pageSize.getWidth();
  //         const imgHeight = pdf.internal.pageSize.getHeight();

  //         pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
  //         pdf.save("div-content.pdf");
  //       });
  //     });
  //   }
  // };

  // const downloadAsPDF = () => {
  //   if (divRef.current) {
  //     loadImages(divRef.current).then(() => {
  //       html2canvas(divRef.current as HTMLElement, { useCORS: true }).then(
  //         async (canvas) => {
  //           const imgData = canvas.toDataURL("image/jpeg");

  //           // Import jsPDF dynamically
  //           const { jsPDF } = await import("jspdf");

  //           const pdf = new jsPDF();
  //           const imgWidth = pdf.internal.pageSize.getWidth();
  //           const imgHeight = pdf.internal.pageSize.getHeight();

  //           pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
  //           pdf.save("div-content.pdf");
  //         }
  //       );
  //     });
  //   }
  // };

  const downloadAsPDF = async () => {
    if (divRef.current) {
      // Load images before capturing the canvas
      await loadImages(divRef.current);

      // Dynamically import html2canvas
      const { default: html2canvas } = await import("html2canvas");

      // Capture the div as canvas
      const canvas = await html2canvas(divRef.current as HTMLElement, {
        useCORS: true,
      });
      const imgData = canvas.toDataURL("image/jpeg");

      // Dynamically import jsPDF
      const { jsPDF } = await import("jspdf");

      const pdf = new jsPDF();
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.save("div-content.pdf");
    }
  };

  const EmbeddedPostCard = () => {
    return (
      <div className={styles.postCardWrapper}>
        <div className={styles.closeIcon} onClick={onCloseMe}>
          <Icon name="Cross" size={24} />
        </div>
        <div ref={divRef}>{children}</div>
        <div className={styles.postCardControles}>
          <LabelButton
            icon={<Icon name="Pdf" size={20} />}
            label="Download PDF"
            onClick={downloadAsPDF}
            onKeyDown={function noRefCheck() {}}
            variant="tertiary"
            size="m"
            corners="squared"
          />
          <LabelButton
            icon={<Icon name="Image" size={20} />}
            label="Download Image"
            onClick={downloadAsImage}
            onKeyDown={function noRefCheck() {}}
            variant="tertiary"
            size="m"
            corners="squared"
          />
        </div>
      </div>
    );
  };

  return narrowMedia ? (
    <SideSheetModal
      // scrollableContent={false}
      open={visible}
      closeMe={onCloseMe}
      position="bottom"
      roundedCorners={true}
      closeOnSwip={false}
    >
      <EmbeddedPostCard />
    </SideSheetModal>
  ) : visible ? (
    <Modal onClose={onCloseMe}>
      <EmbeddedPostCard />
    </Modal>
  ) : null;
};
