import styled, { keyframes } from "styled-components";
import { WIDTH_RATIO } from "./loadingIndicator.constants";
import * as COLOR from "./../color";

interface ContainerProps {
  $innerBarWidth: number;
}

const sliding = keyframes`
  to {
    transform: translate(${100 * WIDTH_RATIO}%);
  }
`;

export const Container = styled.div<ContainerProps>`
  height: 4px;
  width: 100%;
  background: ${COLOR.snow};
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: ${(props) => props.$innerBarWidth}px;
    background: ${COLOR.interaction};
    height: 4px;
    transform: translate(-100%);
    animation: ${sliding} 2s infinite;
  }
`;
