//@ts-nocheck
import React, { useRef } from "react";
import { BasicButton } from "./../Button";
import { IAutoSuggest, AutoSuggest } from "./AutoSuggest";
import { IMultiSelectMenu, MultiSelectMenu } from "./SelectMenu/multiSelect";
//@ts-ignore
import * as S from "./index.style";

interface ISideSheetAutoSuggestBody {
  selectMenuProps: IMultiSelectMenu;
  autosuggestProps: IAutoSuggest;
  onClose: () => void;
}

export const SideSheetAutoSuggestBody = React.forwardRef<
  HTMLInputElement,
  ISideSheetAutoSuggestBody
>(({ selectMenuProps, autosuggestProps, onClose }, innerRef) => {
  const sideSheetMenuRef = useRef(null);
  const { multi, selected } = autosuggestProps;
  return (
    <S.SideSheetAutoSuggestContainer>
      <S.SideSheetHeader>
        <AutoSuggest ref={innerRef} {...autosuggestProps} />
      </S.SideSheetHeader>
      <S.SideSheetMenuContainer ref={sideSheetMenuRef}>
        <MultiSelectMenu
          {...selectMenuProps}
          onOptionClick={(opt, index) => {
            //intercept the on Click to set the focus on the input, the recall the onOptionClick
            innerRef?.current.focus();
            selectMenuProps?.onOptionClick(opt, index);
          }}
          getWrapperBoundingRect={() =>
            sideSheetMenuRef?.current?.getBoundingClientRect()
          }
        />
      </S.SideSheetMenuContainer>
      <S.ButtonsContainer>
        <BasicButton
          onClick={onClose}
          label={multi && selected?.length ? "Done" : "Close"}
          variant={multi && selected?.length ? "primary" : "secondary"}
          size="xl"
          corners="squared"
          auto
        />
      </S.ButtonsContainer>
    </S.SideSheetAutoSuggestContainer>
  );
});
