import React from "react";
import { BasicButton } from "../../Button";
import { PageWithImage } from "../../CheersPageWithImage";
import { MAIN_COLOR } from "./../../../config";
import styles from "./stepsStyle.module.scss";

interface SentCongratsProps {
  goBack: () => void;
}

export const SentCongrats: React.FC<SentCongratsProps> = ({ goBack }) => {
  return (
    <PageWithImage>
      <div className={styles.formContainer}>
        <img
          src="/img/felicitation.png"
          alt="Congrats!"
          className={styles.formCongratsHeaderImg}
        />

        <div className={styles.formCore}>
          <div className={styles.formTitle}>Congrats!</div>

          <div>Your Cheers! message has been successfully sent</div>
        </div>
        <BasicButton
          onClick={goBack}
          label="Go back to My Cheers"
          variant="primary"
          size="xl"
          corners="squared"
          auto
          color={MAIN_COLOR}
        />
      </div>
    </PageWithImage>
  );
};
