import * as COLOR from "./../color";

export const Types = {
  Light: "light",
  Dark: "dark",
} as const;

export type TypesType = typeof Types[keyof typeof Types];

export const EMPLOYEE_NAME_MODE_PROPERTIES: Record<TypesType, string> = {
  [Types.Light]: `color: ${COLOR.carbon};`,
  [Types.Dark]: `color: ${COLOR.white};`,
};

export const EMPLOYEE_TITLE_MODE_PROPERTIES: Record<TypesType, string> = {
  [Types.Light]: `color: ${COLOR.mercury};`,
  [Types.Dark]: `color: ${COLOR.white};`,
};
