import * as COLOR from "./../../color";

const Position = {
  Right: "right",
  Left: "left",
  Top: "top",
  Bottom: "bottom",
};

export const SS_OPEN_TRANSFORM = {
  [Position.Right]: {
    true: "translateX(0%)",
    false: "translateX(100%)",
  },
  [Position.Left]: {
    true: "translateX(0%)",
    false: "translateX(-100%)",
  },
  [Position.Top]: {
    true: "translateY(0%)",
    false: "translateY(-100%)",
  },
  [Position.Bottom]: {
    true: "translateY(0%)",
    false: "translateY(100%)",
  },
};
export const SS_SIZE = {
  [Position.Right]: `
    width: 320px;
    max-width: 83vw;`,
  [Position.Left]: `
    width: 320px;
    max-width: 83vw;`,
  [Position.Top]: `
    width: 100%;
    max-height: 83vh;`,
  [Position.Bottom]: `
    width: 100%;
    max-height: 83vh;`,
};
export const RAIL_SIZE = {
  [Position.Right]: `
    width: 72px;`,
  [Position.Left]: `
    width: 72px;`,
  [Position.Top]: `
    width: 100%;
    max-height: 83vh;`,
  [Position.Bottom]: `
    width: 100%;
    max-height: 83vh;`,
};

export const SS_MODAL_POSITION = {
  [Position.Right]: `
  top: 0;
  bottom: 0;
  right: 0;`,
  [Position.Left]: `
  top: 0;
  bottom: 0;
  left: 0;`,
  [Position.Top]: `
  top: 0;
  left: 0;
  right: 0;`,
  [Position.Bottom]: `
  bottom: 0;
  left: 0;
  right: 0;`,
};

export const SIDE_SEET_POSITION = {
  [Position.Right]: `flex-direction:row-reverse;`,
  [Position.Left]: `flex-direction:row;`,
  [Position.Top]: `flex-direction:column;`,
  [Position.Bottom]: `flex-direction:column-reverse;`,
};
export const SIDE_SEET_NULL_SIZE = {
  [Position.Right]: `max-width:0;`,
  [Position.Left]: `max-width:0;`,
  [Position.Top]: `max-height:0;`,
  [Position.Bottom]: `max-height:0;`,
};

const Types = {
  Dark: "dark",
  Light: "light",
};

export const SS_MODE_PROPERTIES = {
  [Types.Dark]: `
  background: ${COLOR.nightsky_650};
  color: ${COLOR.white};
  `,
  [Types.Light]: `background: ${COLOR.white};
  color: ${COLOR.carbon};`,
};
