import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgBoldBasicsChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 21 21"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M7.536 4.547a.734.734 0 0 0 0 1.01l3.54 3.68a.15.15 0 0 1 0 .202l-3.54 3.68a.73.73 0 0 0-.002 1.009c.269.279.704.28.974.001l4.124-4.286a.735.735 0 0 0 0-1.01L8.508 4.547a.67.67 0 0 0-.486-.21.67.67 0 0 0-.486.21" />
  </svg>
);
const Memo = memo(SvgBoldBasicsChevronRight);
export default Memo;
