import React from "react";
import * as I from "./interfaces";
//@ts-ignore
import * as S from "./typography.style";

export const Typography = ({
  children = "",
  variant = "body-medium",
  family = "body",
  align = "inherit",
  weight = undefined,
  colored = false,
  component = undefined, //"span"
  mode = "light",
  style = undefined,
}: I.ITypography) => {
  component = component || "span";

  return (
    <S.TypoContainer
      as={component}
      $align={align}
      $variant={variant}
      $family={family}
      $colored={colored}
      $weight={weight}
      $mode={mode}
      style={style}
    >
      {children}
    </S.TypoContainer>
  );
};
