//@ts-nocheck
export function dateTemplate(locale: string, date = undefined) {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).formatToParts(date);

  const _template = formatter
    .map(function (e) {
      switch (e.type) {
        case "month":
          return date ? e.value : "MM";
        case "day":
          return date ? e.value : "DD";
        case "year":
          return date ? e.value : "YYYY";
        default:
          return e.value;
      }
    })
    .join("");

  return _template;
}

export function datePartsOrdred(locale) {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).formatToParts();

  const _partsOrdred = formatter
    .map(function (e) {
      switch (e.type) {
        case "month":
          return "M";
        case "day":
          return "D";
        case "year":
          return "Y";
        default:
          return "-";
      }
    })
    .filter((x) => x !== "-");

  return _partsOrdred;
}

export function parseDate(input, format) {
  //format = format || "YYYY-MM-DD"; // default format
  if (!format) return null;

  var parts = input.match(/(\d+)/g),
    i = 0,
    fmt = {};
  // extract date-part indexes from the format
  format.replace(/(YYYY|DD|MM)/gi, function (part) {
    fmt[part] = i++;
  });

  return !parts ||
    isNaN(parts[fmt["DD"]]) ||
    isNaN(parts[fmt["MM"]]) ||
    isNaN(parts[fmt["YYYY"]])
    ? null
    : new Date(parts[fmt["YYYY"]], parts[fmt["MM"]] - 1, parts[fmt["DD"]]);
  // : new Date(
  //     Date.UTC(parts[fmt["YYYY"]], parts[fmt["MM"]] - 1, parts[fmt["DD"]])
  //   );
}
