//@ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import debounce from "lodash.debounce";
import { Drop } from "./../Drop";
import { SideSheetModal } from "./../SideSheet";
import { IMultiSelectMenu, MultiSelectMenu } from "./SelectMenu/multiSelect";
import { ISelectMenuOption } from "./interfaces";
import { useMobileSizeCheck } from "./../utils/useMediaSizeCheck";
import { SelectButton } from "./SelectButton";
import { SelectInput } from "./SelectInput";
import { AutoSuggest } from "./AutoSuggest";
import { SideSheetAutoSuggestBody } from "./SideSheetAutoSuggestBody";
//@ts-ignore
import * as S from "./index.style";

export interface ISelect extends IMultiSelectMenu {
  /**The variant of the selection element. */
  variant?: "input" | "button";
  /**The label on the selection element. */
  label?: string;
  /** The size of the component. */
  size?: "s" | "m" | "l" | "xl";
  /**If true, will close or not on selection. */
  closeOnSelect?: boolean;
  /**placeholder if the variant is 'input'. */
  placeholder?: string;
  /**If true, the string value given by the user in the input will be used to filter the initial list of options. */
  autosuggest?: boolean;
  /**If true, and if variant is 'input', the selected items will be styled as button inside input.
   * This option is the single option keeped with autosuggest
   */
  selectedButtonStyled?: boolean;
  /**If true, if we select multiple options and the entry is narrow, we will scroll horizontally to see all the options.
   * this option is not possible when autsuggestion is true
   */
  scrollable?: boolean;
  /**Fire on selection: on option click or on other, like pressing Enter key. */
  onSelect?: (lst: ISelectMenuOption[]) => void;
  /**Method, usually async, to fetch options for a given prefix; most of the time from a backend server... */
  fetchOptionsByPrefix?: (
    str: string,
    limit: number
  ) => Promise<ISelectMenuOption[]>; //
  /**if true, the dropdown will be replaced by a down-sidesheet on mobile view */
  downSheetOnMobile?: boolean;
}

const DROP_Max_HEIGHT = 290; //px
const DROP_Max_WIDTH = 250; //px

export const Select = ({
  variant = "input",
  label,
  size = "m",
  multi = false,
  closeOnSelect = !multi,
  onSelect,
  placeholder,
  autosuggest = false,
  selectedButtonStyled = autosuggest,
  scrollable = false,
  options,
  fetchOptionsByPrefix,
  // useCheckbox,
  downSheetOnMobile = true,
  ...rest
}: ISelect) => {
  const dropRef = useRef(null);
  const dropInnerRef = useRef(null);
  const elementInnerRef = useRef(null);
  const sideSheetMenuRef = useRef(null);
  const elementWrapperRef = useRef(null);

  rest.selected ??= []; //init rest.selected if is undefined

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [prefix, setPrefix] = useState<string>(""); //the sub string suggested to find an item in the list
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false); //true when we are loading suggested options
  const [suggestedOptions, setSuggestedOptions] = useState<ISelectMenuOption[]>(
    []
  ); //the sub string suggested to find an item in the list
  // const [selectedOptions, setSelectedOptions] = useState<ISelectMenuOption[]>(
  //   []
  // );
  const [preSelectedIndex, setPreSelectedIndex] = useState<number>(undefined); //the index of focused option
  const [suggestedString, setSuggestedString] = useState<string>();
  const narrowMedia = useMobileSizeCheck();
  const _useDownSheet = downSheetOnMobile && narrowMedia;

  // //Set the 'selectedOptions' related to the selected values (='selected')
  // useEffect(() => {
  //   if (!rest?.selected?.length || !options?.length) {
  //     setSelectedOptions([]);
  //   } else {
  //     const _opts = options.filter((x) => rest.selected.includes(x.value));
  //     setSelectedOptions(_opts);
  //   }
  // }, [rest.selected]);

  //When prefix changes, filter the "suggestedOptions"
  useEffect(() => {
    if (!autosuggest || fetchOptionsByPrefix) return; //if the option are given by a fetch function, this function will do the job

    const noFilter =
      !multi && rest.selected?.length && prefix === rest.selected[0]?.label; //In this case we don't filter the list : single choice & the filter-string is the choice already done.

    const _filtred =
      noFilter || !prefix?.length
        ? options
        : options.filter((x) =>
            x.label.toLowerCase().includes(prefix.toLowerCase())
          );

    setSuggestedOptions(_filtred);
  }, [prefix]);

  useEffect(() => {
    setSuggestedOptions(options);
  }, [options]);

  //On selecting => change the suggested string
  useEffect(() => {
    if (autosuggest && !multi) {
      setPrefix(rest.selected?.length ? rest.selected[0].label : "");
    }
  }, [autosuggest, multi, rest.selected]);

  useEffect(() => {
    // Store the current value of elementInnerRef to ensure it's stable
    const currentElemInnerRef = elementWrapperRef.current;

    const resizeObserver = new ResizeObserver(() => {
      // Wait for the input size to change before repositioning the drop
      if (dropRef.current) {
        dropRef.current.updateLocation(); // Update the drop position after resizing
      }
    });

    // Start observing the element for size changes
    if (currentElemInnerRef) {
      resizeObserver.observe(currentElemInnerRef);
    }

    // Cleanup function to stop observing the element
    return () => {
      if (currentElemInnerRef) {
        resizeObserver.unobserve(currentElemInnerRef);
      }
    };
  }, []); // Empty dependency array ensures this runs only once after the component mounts

  //compute the suggestedString when the preSelectedIndex changes
  useEffect(() => {
    const _suggestedLabel =
      suggestedOptions?.length > preSelectedIndex
        ? suggestedOptions[preSelectedIndex].label
        : undefined;
    setSuggestedString(_suggestedLabel);
  }, [suggestedOptions, preSelectedIndex]);

  useEffect(() => {
    setPreSelectedIndex(undefined);
  }, [suggestedOptions]);

  //Set the preSelectedIndex when opening the menu
  useEffect(() => {
    if (fetchOptionsByPrefix) return; //It the array is dynamic return

    const _options = suggestedOptions; //good if autocomplete or just normal select
    if (menuIsOpen && _options?.length) {
      let _preSelectedIndex = 0; //default value
      if (rest.selected?.length > 0) {
        const lastSelectedValue =
          rest.selected[rest.selected.length - 1]["value"];
        const lastSelectedIndex = _options.findIndex(
          (opt) => opt.value === lastSelectedValue
        );
        if (lastSelectedIndex !== -1) {
          _preSelectedIndex = lastSelectedIndex;
        }
      }
      setPreSelectedIndex(_preSelectedIndex);
    }
  }, [menuIsOpen]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    //Get the index of next 'enabled' item. When we reach the last we go to the first (circular way)
    function nextPreSelectedIndex(current) {
      function getNext(i) {
        return i < _options.length - 1 ? i + 1 : 0;
      }
      let _next = getNext(current);
      while (_next !== current && _options[_next]?.disabled) {
        _next = getNext(_next);
      }
      return _next;
    }

    //Get the index of previous 'enabled' item. When we reach the first we go to the last (circular way)
    function previousPreSelectedIndex(current) {
      function getPrevious(i) {
        return i > 0 ? i - 1 : _options.length - 1;
      }
      let _prev = getPrevious(current);
      while (_prev != current && _options[_prev].disabled) {
        _prev = getPrevious(_prev);
      }
      return _prev;
    }

    const _options = suggestedOptions; //is true if autsuggestion list or the first (not filtred) options list
    if (menuIsOpen) {
      switch (e.key) {
        case "Down": // IE/Edge specific value
        case "ArrowDown":
          e.preventDefault();
          setPreSelectedIndex(nextPreSelectedIndex(preSelectedIndex));
          break;
        case "Up": // IE/Edge specific value
        case "ArrowUp":
          e.preventDefault();
          setPreSelectedIndex(previousPreSelectedIndex(preSelectedIndex));
          break;
        case "Enter":
          e.preventDefault();
          if (preSelectedIndex !== undefined) {
            handleSelect(suggestedOptions[preSelectedIndex]);
          }
          break;
        default:
        //optionStartingWith(e);
      }
    } else {
      switch (e.key) {
        case "Down": // IE/Edge specific value
        case "ArrowDown":
        case "Up": // IE/Edge specific value
        case "ArrowUp":
          e.preventDefault();
          tryOpenMenu(e);
      }
    }
  };

  const toggleVisibility = (e) => {
    if (_useDownSheet) {
      setMenuIsOpen(!menuIsOpen);
    } else {
      dropRef?.current?.toggle(e);
    }
  };

  const tryOpenMenu = (e = undefined) => {
    if (_useDownSheet) {
      setMenuIsOpen(true);
    } else if (!dropRef?.current?.isOpen) {
      dropRef?.current?.open(e);
    }
  };

  function handleClose() {
    if (_useDownSheet) {
      setMenuIsOpen(false);
    } else {
      dropRef?.current?.close();
    }
  }

  const handleUnselect = (v: string) => {
    const _newSelectedValues = rest.selected.filter((x) => x.value !== v);
    onSelect(_newSelectedValues);
  };
  const handleSelect = (opt: ISelectMenuOption) => {
    const selectedListWithNewValue = () => {
      if (!rest.selected?.length) return [opt];
      //else: we should return un ordred list (depending on the options order)
      const unOrdred = [...rest.selected, opt];
      return unOrdred;
      // const ordred = _options
      //   .filter((op) => unOrdred.includes(op.value))
      //   .map((op) => op.value);
      // return ordred;
    };

    if (multi) {
      //if Multiple choise
      const filtred = rest.selected.filter((x) => x.value !== opt.value);
      const _newSelectedList =
        filtred.length === rest.selected.length
          ? selectedListWithNewValue() //not selected yet
          : filtred;
      onSelect(_newSelectedList);

      //if suggestions are dynaminc (comming from fetchOptionsByPrefix) clean suggestion and input will get the foncus
      if (!!fetchOptionsByPrefix) {
        setSuggestedOptions([]);
      }
      //if multi
      setPrefix(undefined);
    } else {
      const _selected = rest.selected.find((x) => x.value === opt.value)
        ? []
        : [opt];
      onSelect(_selected);
      setPrefix(_selected?.length ? _selected[0].label : undefined);
    }

    if (closeOnSelect) {
      handleClose();
    }
  };

  const handleOptionClick = (opt, i) => {
    if (!!opt?.label && !!opt.value) {
      //If the item of the list is not given (perhaps use only for graphical reason)
      setPreSelectedIndex(i);
      handleSelect(opt);
    }
  };

  const handlePrefixChangedImmediate = useCallback(
    async (str: string) => {
      if (!fetchOptionsByPrefix) return;

      if (!str?.length) {
        setSuggestedOptions([]);
        return;
      }
      //Else
      try {
        setLoadingOptions(true);
        const fetchedOptions = await fetchOptionsByPrefix(str);
        setSuggestedOptions(fetchedOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOptions(false);
      }
    },
    [fetchOptionsByPrefix]
  );

  const handlePrefixChangedDebounced = useCallback(
    debounce(async (str: string) => {
      handlePrefixChangedImmediate(str);
    }, 300),
    [handlePrefixChangedImmediate]
  );

  useEffect(() => {
    handlePrefixChangedDebounced(prefix);
    return () => {
      handlePrefixChangedDebounced.cancel(); // Clean up the debounce to avoid memory leaks
    };
  }, [prefix, handlePrefixChangedDebounced, fetchOptionsByPrefix]);

  return _useDownSheet ? (
    <div>
      {variant === "button" ? (
        <SelectButton
          ref={elementInnerRef}
          label={label}
          multi={multi}
          size={size}
          menuIsOpen={menuIsOpen}
          onClick={toggleVisibility}
          onKeyDown={handleKeyDown}
          selectedOptions={rest.selected}
        />
      ) : autosuggest ? (
        <AutoSuggest
          ref={elementInnerRef}
          label={label}
          multi={multi}
          size={size === "s" || size === "m" ? "l" : size} //input size is only "l" | "xl"
          menuIsOpen={menuIsOpen}
          onClick={toggleVisibility}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          selectedButtonStyled={selectedButtonStyled}
          scrollable={scrollable}
          selected={rest.selected}
          onUnselect={handleUnselect}
          //
          //When autosuggest is enabled
          suggestedString={suggestedString}
          prefix={prefix}
          loadingOptions={loadingOptions}
          onPrefixChange={(v) => {
            setPrefix(v);
            tryOpenMenu();
          }}
        />
      ) : (
        <SelectInput
          ref={elementInnerRef}
          label={label}
          multi={multi}
          size={size === "s" || size === "m" ? "l" : size} //input size is only "l" | "xl"
          menuIsOpen={menuIsOpen}
          onClick={toggleVisibility}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          selectedButtonStyled={selectedButtonStyled}
          scrollable={scrollable}
          selected={rest.selected}
          onUnselect={handleUnselect}
        />
      )}
      <SideSheetModal
        closeMe={handleClose}
        position="bottom"
        open={menuIsOpen}
        closeOnSwip={false}
        sidSheetInnerRef={dropInnerRef}
        growSize={autosuggest} //the heigh of the sidesheet should not move!
      >
        {autosuggest ? (
          <SideSheetAutoSuggestBody
            ref={dropInnerRef}
            selectMenuProps={{
              ...rest,
              options: suggestedOptions,
              multi: multi,
              onOptionClick: handleOptionClick,
              isOpen: menuIsOpen,
              preSelectedIndex: preSelectedIndex,
            }}
            autosuggestProps={{
              label,
              multi,
              size: size === "s" || size === "m" ? "l" : size, //input size is only "l" | "xl"
              menuIsOpen,
              // onClick={toggleVisibility}
              onKeyDown: handleKeyDown,
              placeholder,
              selectedButtonStyled,
              scrollable,
              selected: rest.selected,
              onUnselect: handleUnselect,
              //
              //When autosuggest is enabled
              suggestedString,
              prefix,
              loadingOptions,
              onPrefixChange: setPrefix,
            }}
            onClose={handleClose}
          />
        ) : (
          <S.SideSheetMenuContainer ref={sideSheetMenuRef}>
            <MultiSelectMenu
              ref={dropInnerRef}
              {...rest}
              options={suggestedOptions} //will work if autosuggest or simple select component
              multi={multi}
              onOptionClick={handleOptionClick}
              isOpen={menuIsOpen}
              preSelectedIndex={preSelectedIndex}
              onKeyDown={handleKeyDown}
              getWrapperBoundingRect={() => {
                return sideSheetMenuRef?.current?.getBoundingClientRect();
              }}
            />
          </S.SideSheetMenuContainer>
        )}
      </SideSheetModal>
    </div>
  ) : (
    <Drop
      ref={(node) => {
        dropRef.current = node;
        setMenuIsOpen(dropRef?.current?.isOpen);
      }}
      background="white"
      body={
        <MultiSelectMenu
          ref={dropInnerRef}
          {...rest}
          options={suggestedOptions} //will work (if autosuggest or simple select component
          multi={multi}
          onOptionClick={handleOptionClick}
          isOpen={menuIsOpen}
          preSelectedIndex={preSelectedIndex}
          onKeyDown={handleKeyDown}
          getWrapperBoundingRect={dropRef?.current?.getBoundingClientRect}
        />
      }
      placement="bottom"
      autoWidth
      arrow={variant === "button"}
      sameWidth={variant !== "button"}
      show="controlled" //we will controlle it: onClick or onButtonClick
      maxWidth={variant === "button" ? `${DROP_Max_WIDTH}px` : undefined}
      maxHeight={`${DROP_Max_HEIGHT}px`}
      dropInnerRef={dropInnerRef}
      elementInnerRef={elementInnerRef}
      keepFocusOnElement={autosuggest}
    >
      {variant === "button" ? (
        <div ref={elementWrapperRef}>
          <SelectButton
            ref={elementInnerRef}
            label={label}
            multi={multi}
            size={size}
            menuIsOpen={menuIsOpen}
            onClick={toggleVisibility}
            onKeyDown={handleKeyDown}
            selectedOptions={rest.selected}
          />
        </div>
      ) : autosuggest ? (
        <div ref={elementWrapperRef}>
          <AutoSuggest
            ref={elementInnerRef}
            label={label}
            multi={multi}
            size={size === "s" || size === "m" ? "l" : size} //input size is only "l" | "xl"
            menuIsOpen={menuIsOpen}
            onClick={toggleVisibility}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            selectedButtonStyled={selectedButtonStyled}
            scrollable={scrollable}
            selected={rest.selected}
            onUnselect={handleUnselect}
            //
            //When autosuggest is enabled
            suggestedString={suggestedString}
            prefix={prefix}
            loadingOptions={loadingOptions}
            onPrefixChange={(v) => {
              setPrefix(v);
              tryOpenMenu();
            }}
          />
        </div>
      ) : (
        <div ref={elementWrapperRef}>
          <SelectInput
            ref={elementInnerRef}
            label={label}
            multi={multi}
            size={size === "s" || size === "m" ? "l" : size} //input size is only "l" | "xl"
            menuIsOpen={menuIsOpen}
            onClick={toggleVisibility}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            selectedButtonStyled={selectedButtonStyled}
            scrollable={scrollable}
            selected={rest.selected}
            onUnselect={handleUnselect}
          />
        </div>
      )}
    </Drop>
  );
};
