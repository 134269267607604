import { apolloClient } from "./../index";
import { gql } from "@apollo/client";
import { ISelectMenuOption } from "./../components/Select/interfaces";

export const getCategories = async (): Promise<ISelectMenuOption[]> => {
  const query = gql`
    query {
      categories(pagination: { limit: -1 }) {
        data {
          id
          attributes {
            name
            default_template {
              data {
                id
              }
            }
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({ query });
    if (error) throw error;

    //else
    const _data = data?.categories?.data;
    const result = _data?.map((x: any) => {
      return {
        label: x.attributes?.name,
        value: x.id,
        data: {
          templateId: x.attributes?.default_template?.data?.id,
        },
      };
    });

    return result;
  } catch (error) {
    throw error;
  }
};
export const fetchCategoryIdByName = async (name: string): Promise<string> => {
  const query = gql`
    query getId($name: String!) {
      categories(filters: { name: { eq: $name } }) {
        data {
          id
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { name },
    });
    if (error) throw error;

    //else
    const _data = data?.categories?.data;
    return !_data?.length ? null : _data[0].id;
  } catch (error) {
    throw error;
  }
};
