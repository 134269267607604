import * as COLOR from "./../color";

const Types = {
  XXS: "xxs",
  XS: "xs",
  S: "s",
  M: "m",
  L: "l",
  XL: "xl",
  Carbon: "carbon",
  Inverted: "inverted",
  Primary: "primary",
};

export const CIRCLE_SIZE_PROPERTIES = {
  [Types.XXS]: `stroke-width: 0.8;
    `,
  [Types.XS]: `stroke-width: 0.9;
    `,
  [Types.S]: `stroke-width: 1;
    `,
  [Types.M]: `stroke-width: 1.2;
    `,
  [Types.L]: `stroke-width: 1.5;
    `,
  [Types.XL]: `stroke-width: 1.8;
    `,
};

export const CIRCLE_VARIANT_PROPERTIES = {
  [Types.Carbon]: `stroke: ${COLOR.carbon};
    `,
  [Types.Inverted]: `stroke: ${COLOR.white};
    `,
  [Types.Primary]: `stroke: ${COLOR.caribe};
    `,
};

export const RADIUS_SIZE_PROPERTIES = {
  [Types.XXS]: 4,
  [Types.XS]: 6,
  [Types.S]: 8,
  [Types.M]: 10,
  [Types.L]: 12,
  [Types.XL]: 14,
};
