// import styled, { css } from "styled-components";
import styled from "styled-components";
import * as COLOR from "./../color";

// Définir les types pour les props
interface SelectedAsButtonsProps {
  scrollable?: boolean;
  scrollableLeft?: boolean;
  scrollableRight?: boolean;
}

interface SelectedBtnProps {
  $withImg?: boolean;
}

// const noselect = css`
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -moz-user-select: none; /* Old versions of Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
// `;

export const SelectInputContainer = styled.div`
  outline: none;
`;

export const SelectedAsButtons = styled.div<SelectedAsButtonsProps>`
  position: relative;
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 5px 0 1px;
  margin-bottom: 4px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.scrollable
      ? `flex-wrap: nowrap;
        overflow-x: auto;`
      : `flex-wrap: wrap;`}

  &:hover {
    ${(props) => props.scrollable && `margin-bottom: 0;`}

    scrollbar-width: thin;
    &::-webkit-scrollbar {
      display: block;
      width: 4px;
      height: 4px;
    }

    overscroll-behavior: contain;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background-clip: content-box;
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: transparent;
    }
    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
      }
    }
  }

  ${(props) =>
    props.scrollableLeft &&
    !props.scrollableRight &&
    `mask-image: linear-gradient(to right, transparent 0%, black 10%);`}
  ${(props) =>
    !props.scrollableLeft &&
    props.scrollableRight &&
    `mask-image: linear-gradient(to left, transparent 0%, black 10%);`}
  ${(props) =>
    props.scrollableLeft &&
    props.scrollableRight &&
    `mask-image: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);`}
`;

export const SelectedBtn = styled.div<SelectedBtnProps>`
  border-radius: 2rem;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-wrap: nowrap;
  line-height: 1.3em;
  font-size: 12px;
  height: 24px;
  padding: 4px 8px;
  ${(props) =>
    props.$withImg
      ? `color: ${COLOR.ashes};
      border: 1px solid ${COLOR.silver};`
      : `color: ${COLOR.caribe_600};
  background-color: ${COLOR.caribe_opacity8};`}

  &:hover {
    ${(props) => props.$withImg && `border-color:${COLOR.ashes};`}
  }
`;

export const SelectedBtnClose = styled.div`
  cursor: pointer;
  display: flex;
`;

export const SelectedBtnLabel = styled.div`
  white-space: nowrap;
`;

export const SelectedItem = styled.div`
  white-space: nowrap;
`;

export const SelectedLabels = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;

  & > ${SelectedItem} + ${SelectedItem}::before {
    content: ", ";
  }
`;

export const SelectedOpt = styled.div``;

export const ImgAsIcon = styled.img`
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
  height: 20px;
  width: 20px;
  margin-left: -7px;
`;
