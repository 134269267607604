//@ts-nocheck
import React from "react";
import IconAlertCircle from "./../../../Icon/tsx/AlertCircle";
import IconCheckCircle from "./../../../Icon/tsx/CheckCircle1";
//@ts-ignore
import * as S from "./withHelperMessage.style";

import { IAlertState, IWithHelperMessage } from "./../interfaces";

interface IHelperWithChildren extends IWithHelperMessage {
  children: React.ReactNode;
}

function HelperMessagerIcon({
  alertState = undefined,
}: {
  alertState?: IAlertState;
}) {
  if (alertState === "success") {
    return <IconCheckCircle />;
  } else if (alertState === "error") {
    return <IconAlertCircle />;
  } else {
    return null;
  }
}

function HelperMessage({
  alertState = null,
  helperMessage = null,
}: IWithHelperMessage) {
  return (
    <S.HelperMessageWrap state={alertState}>
      <HelperMessagerIcon alertState={alertState} />
      <span>{helperMessage}</span>
    </S.HelperMessageWrap>
  );
}

function BottomMessages({
  alertState = null,
  helperMessage = null,
  rightBottomMessage = undefined,
}: IWithHelperMessage & { rightBottomMessage: string }) {
  return (
    <S.BottomMessages>
      {helperMessage?.length ? (
        <HelperMessage alertState={alertState} helperMessage={helperMessage} />
      ) : (
        <div />
      )}
      {rightBottomMessage?.length > 0 && (
        <S.RightBottomMessage>{rightBottomMessage}</S.RightBottomMessage>
      )}
    </S.BottomMessages>
  );
}

export const WithHelperMessage = ({
  children,
  alertState = null,
  helperMessage = null,
  rightBottomMessage = null,
}: IHelperWithChildren) => {
  return (
    <>
      {children}
      {(helperMessage?.length > 0 || rightBottomMessage?.length > 0) && (
        <BottomMessages
          alertState={alertState}
          helperMessage={helperMessage}
          rightBottomMessage={rightBottomMessage}
        />
      )}
    </>
  );
};
