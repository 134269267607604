//@ts-nocheck
import React from "react";
import { Icon } from "./../Icon";
import { Spinner } from "./../Spinner";
import { ISelectMenuOption } from "./interfaces";
//@ts-ignore
import * as S from "./selectInput.style";
//@ts-ignore
import * as B from "./selectButton.style";

import {
  WithHelperMessage,
  InputContainer,
  WithLabel,
} from "./../utils/inputCommon";
import {
  ICommonInput,
  IWithHelperMessage,
  IInputContainer,
  IWithLabel,
} from "./../utils/inputCommon/interfaces";

export interface IAutoSuggest
  extends IWithHelperMessage,
    IWithLabel,
    IInputContainer,
    ICommonInput {
  multi: boolean;
  selectedButtonStyled?: boolean;
  scrollable?: boolean;
  menuIsOpen?: boolean;
  selected?: ISelectMenuOption[];
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onUnselect?: (selected: string[]) => void;

  //--- with autosuggest ------------------------
  prefix?: string;
  onPrefixChange: (suggested: string) => void;
  suggestedString?: string;
  /** True when suggested-options are "loading" from server. Always false when the option are handled locally */
  loadingOptions?: boolean;
}

type RefType = HTMLInputElement; // | HTMLDivElement;
export const AutoSuggest = React.forwardRef<RefType, IAutoSuggest>(
  (
    {
      multi,
      selectedButtonStyled = false,
      scrollable = false,
      selected,
      menuIsOpen,
      onClick,
      onKeyDown,
      onUnselect,
      //
      prefix = "",
      onPrefixChange,
      suggestedString,
      loadingOptions = false,
      //
      ...rest
    },
    ref
  ) => {
    rest.rightSideNode = (
      <div style={{ cursor: "pointer", padding: "8px" }}>
        {loadingOptions ? (
          <Spinner size="s" variant="primary" />
        ) : (
          <B.ChevronIcon $size={rest.size} $menuOpen={menuIsOpen}>
            <Icon name="InterfaceArrowsButtonDown" />
          </B.ChevronIcon>
        )}
      </div>
    );

    //
    const handleRemove = (e, v: string) => {
      e.preventDefault();
      e.stopPropagation();
      onUnselect(v);
    };

    return (
      <S.SelectInputContainer onClick={onClick} onKeyDown={onKeyDown}>
        <WithHelperMessage>
          <InputContainer {...rest} noMaxHeight={selectedButtonStyled}>
            <WithLabel
              {...rest}
              empty={!(prefix?.length > 0) && !selected?.length}
              hasPlaceholder={rest.placeholder?.length > 0}
            >
              {multi ? (
                //==> Multi-selections
                <S.SelectedAsButtons>
                  {selectedButtonStyled &&
                    selected?.length > 0 &&
                    selected.map((x, i) => (
                      <S.SelectedBtn key={i} $withImg={x.imgSrc || x.icon}>
                        {x.imgSrc && (
                          <S.ImgAsIcon alt={x.label} src={x.imgSrc} />
                        )}
                        <S.SelectedBtnLabel>{x.label}</S.SelectedBtnLabel>
                        <S.SelectedBtnClose
                          onClick={(e) => handleRemove(e, x.value)}
                        >
                          <Icon name="Cross" />
                        </S.SelectedBtnClose>
                      </S.SelectedBtn>
                    ))}
                  <div>
                    <input
                      //the forwardRef is here if it is an autosuggest input
                      spellCheck="false"
                      ref={ref}
                      type="text"
                      value={prefix}
                      onChange={(e) => onPrefixChange(e.target.value)}
                      placeholder={rest.placeholder ?? rest.label} //if no placeholder take the label if defined
                      disabled={rest.disabled}
                    />
                  </div>
                </S.SelectedAsButtons>
              ) : (
                //==> Single-selection
                <S.SelectedLabels>
                  <input
                    //the forwardRef is here if it is an autosuggest input
                    spellCheck="false"
                    ref={ref}
                    type="text"
                    value={prefix}
                    onChange={(e) => onPrefixChange(e.target.value)}
                    placeholder={rest.placeholder ?? rest.label} //if no placeholder take the label if defined
                    disabled={rest.disabled}
                  />
                </S.SelectedLabels>
              )}
            </WithLabel>
          </InputContainer>
        </WithHelperMessage>
      </S.SelectInputContainer>
    );
  }
);
