import React, { useState, useEffect, useCallback } from "react";
import { Select } from "../../../components/Select";
import { fetchUsersByPrefix } from "../../../auth/utils";
import { ISelectMenuOption } from "../../../types";

// Simulated function to fetch countries from the server
async function fetchPersonsFromServer(): Promise<ISelectMenuOption[]> {
  // Simulate server call with a timeout
  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve([
          { label: "France", value: "FR" },
          { label: "USA", value: "US" },
        ]),
      1000
    )
  );
}

// Cache to store the countries loaded from the server, shared across all component instances
let countriesCache: ISelectMenuOption[] | null = null;

interface PersonsFilterProps {
  label: string;
  selected?: ISelectMenuOption[];
  onSelect?: (lst: ISelectMenuOption[]) => void;
}

export const SelectPersons: React.FC<PersonsFilterProps> = ({
  label,
  selected = [],
  onSelect = (v) => {},
}) => {
  const fetchFromPrimoByPrefix = useCallback(async (prefix: string) => {
    let result = await fetchUsersByPrefix(prefix);
    return result;
  }, []);

  return (
    <Select
      label={label}
      // options={allPersons}
      size="xl"
      fetchOptionsByPrefix={fetchFromPrimoByPrefix}
      multi={true}
      selected={selected}
      onSelect={onSelect}
      // downSheetOnMobile={false}
      autosuggest
    />
  );
};
