import styled from "styled-components";
import * as COLOR from "./../../../color";

interface WrapperProps {
  $alignCenter?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: ${(props) => (props.$alignCenter ? "center" : "flex-start")};
  width: 100%;
`;

export const Inner = styled.div`
  flex-grow: 1;
`;

export const Handle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  padding: 8px;
  color: ${COLOR.smoke};
  &:hover {
    color: ${COLOR.interaction};
  }
`;
