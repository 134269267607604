import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgFilter2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 48 48"
    {...props}
  >
    <g data-name="Layer 2">
      <path fill="none" d="M0 0h48v48H0z" data-name="invisible box" />
      <g data-name="icons Q2">
        <path d="M41.8 8H21.7A6.2 6.2 0 0 0 16 4a6 6 0 0 0-5.6 4H6.2A2.1 2.1 0 0 0 4 10a2.1 2.1 0 0 0 2.2 2h4.2a6 6 0 0 0 5.6 4 6.2 6.2 0 0 0 5.7-4h20.1a2.1 2.1 0 0 0 2.2-2 2.1 2.1 0 0 0-2.2-2M16 12a2 2 0 1 1 2-2 2 2 0 0 1-2 2M41.8 22h-4.1a6.2 6.2 0 0 0-5.7-4 6 6 0 0 0-5.6 4H6.2a2 2 0 1 0 0 4h20.2a6 6 0 0 0 5.6 4 6.2 6.2 0 0 0 5.7-4h4.1a2 2 0 1 0 0-4M32 26a2 2 0 1 1 2-2 2 2 0 0 1-2 2M41.8 36H24.7a6.2 6.2 0 0 0-5.7-4 6 6 0 0 0-5.6 4H6.2a2 2 0 1 0 0 4h7.2a6 6 0 0 0 5.6 4 6.2 6.2 0 0 0 5.7-4h17.1a2 2 0 1 0 0-4M19 40a2 2 0 1 1 2-2 2 2 0 0 1-2 2" />
      </g>
    </g>
  </svg>
);
const Memo = memo(SvgFilter2);
export default Memo;
