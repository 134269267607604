import { createGlobalStyle } from "styled-components";

const GlobalFonts = createGlobalStyle`

  @font-face {
    font-family: "SourceSans";
    font-weight: normal;
    font-style: normal;
    src: 
        url(${
          process.env.PUBLIC_URL + "/fonts/SourceSansPro-Regular.woff"
        }) format("woff")
  }
  @font-face {
    font-family: "SourceSans";
    font-weight: bold;
    font-style: normal;
    src: 
        url(${
          process.env.PUBLIC_URL + "/fonts/SourceSansPro-Bold.woff"
        }) format("woff")
  }

  @font-face {
    font-family: "SourceSans";
    font-weight: 600;
    font-style: normal;
    src: 
        url(${
          process.env.PUBLIC_URL + "/fonts/SourceSansPro-SemiBold.woff"
        }) format("woff")
  }

  body {
    font-family: "SourceSans", "Calibri", "Arial", sans-serif;
    font-size: 16px;
  } 
`;

export default GlobalFonts;
