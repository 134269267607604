import React from "react";
import { Badge } from "./../Badge";
import { Icon } from "./../Icon";
import { IFilterProperties } from "./../../types";
import { formatDate } from "./../utils/date";
import styles from "./style.module.scss";

export interface ChoosenFilterProps {
  filter?: IFilterProperties;
  onChange: (v?: IFilterProperties) => void;
}

export const ChoosenFilter: React.FC<ChoosenFilterProps> = ({
  filter,
  onChange,
}) => {
  const isEmpty = (f: IFilterProperties) => {
    return (
      !f?.authors?.length &&
      !f?.extRecipients?.length &&
      !f?.primoRecipients?.length &&
      !f?.startDate &&
      !f?.endDate
    );
  };
  const handleChange = (newFilter: IFilterProperties) => {
    onChange(isEmpty(newFilter) ? undefined : newFilter);
  };
  return (
    <div className={styles.badgeContainer}>
      {!!filter?.authors?.length &&
        filter.authors.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  authors: filter.authors?.filter((_, j) => i !== j),
                })
              }
            >
              <span>from : {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.primoRecipients?.length &&
        filter.primoRecipients.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  primoRecipients: filter.primoRecipients?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>to : {x.label}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.extRecipients?.length &&
        filter.extRecipients.map((x, i) => (
          <Badge key={i}>
            <div
              className={styles.badge}
              onClick={() =>
                handleChange({
                  ...filter,
                  extRecipients: filter.extRecipients?.filter(
                    (_, j) => i !== j
                  ),
                })
              }
            >
              <span>to : {x}</span>
              <Icon name="Cross" size={16} />
            </div>
          </Badge>
        ))}

      {!!filter?.startDate && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                startDate: undefined,
              })
            }
          >
            <span>from : {formatDate(filter.startDate)}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}

      {!!filter?.endDate && (
        <Badge>
          <div
            className={styles.badge}
            onClick={() =>
              handleChange({
                ...filter,
                endDate: undefined,
              })
            }
          >
            <span>to : {formatDate(filter.endDate)}</span>
            <Icon name="Cross" size={16} />
          </div>
        </Badge>
      )}
    </div>
  );
};
