//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import * as I from "./interfaces";
import { AvatarDropdown } from "./../AvatarDropdown";
import { Typography } from "../Typography";
import { useDeviceSizeCheck } from "./../utils/useMediaSizeCheck";
//@ts-ignore
import * as S from "./style";

interface IBodyLinkSection extends I.ILinksSection {
  onLinkClick: () => void;
}

export const LinksSection = ({
  links,
  label = undefined,
  hideOnDescktop = false,
  hideOnTablet = false,
  hideOnMobile = false,
  rail = false,
  //mobile = false,
  onLinkClick,
  primaryColor,
}: IBodyLinkSection) => {
  const { mobile, tablet, desktop } = useDeviceSizeCheck();
  return (tablet && hideOnTablet) ||
    (mobile && hideOnMobile) ||
    (desktop && hideOnDescktop) ? null : (
    <S.Section>
      {label && !rail && <S.SectionLabel>{label}</S.SectionLabel>}
      {links?.length > 0 && (
        <S.ExternalLinks>
          {links.map((link, i) =>
            (tablet && link.hideOnTablet) ||
            (mobile && link.hideOnMobile) ||
            (desktop && link.hideOnDescktop) ? null : (
              <S.ExternalLink
                key={i}
                $rail={rail}
                href={link.href}
                target="_blank"
                onClick={onLinkClick}
                $primaryColor={primaryColor}
              >
                <S.ExternalLinkIcon $primaryColor={primaryColor}>
                  {link.icon}
                </S.ExternalLinkIcon>
                {!rail && (
                  <S.ExternalLinkLabel>{link.label}</S.ExternalLinkLabel>
                )}
              </S.ExternalLink>
            )
          )}
        </S.ExternalLinks>
      )}
    </S.Section>
  );
};

interface IOptionsSectionCore extends I.IOptionsSection {
  value?: string;
  onChange: (v: string) => void;
}

export const OptionsSection = ({
  value,
  onChange,
  options,
  label = undefined,
  hideOnDescktop = false,
  rail = false,
  hideOnTablet = false,
  // mobile = false,
  bottomRail = false,
  hideOnMobile = false,
  primaryColor = undefined,
}: IOptionsSectionCore) => {
  const { mobile, tablet, desktop } = useDeviceSizeCheck();
  return (tablet && hideOnTablet) ||
    (mobile && hideOnMobile) ||
    (desktop && hideOnDescktop) ? null : (
    <S.Section>
      {label && !rail && <S.SectionLabel>{label}</S.SectionLabel>}
      {options?.length > 0 && (
        <S.SectionOptions $bottomRail={bottomRail}>
          {options.map((op, i) => {
            const selected = op?.value && op.value === value;
            return (tablet && op.hideOnTablet) ||
              (mobile && op.hideOnMobile) ||
              (desktop && op.hideOnDescktop) ? null : (
              <S.SectionOption
                key={i}
                $rail={rail}
                $bottomRail={bottomRail}
                selected={selected}
                $primaryColor={primaryColor}
                onClick={() => {
                  onChange(op.value);
                  op.onClick && op.onClick();
                }}
                $stateOption={!op.notStateOption}
              >
                <S.SectionOptionIcon
                  selected={selected}
                  $primaryColor={primaryColor}
                >
                  {op.icon}
                </S.SectionOptionIcon>
                {!rail && (
                  <S.SectionOptionLabel>{op.label}</S.SectionOptionLabel>
                )}
              </S.SectionOption>
            );
          })}
        </S.SectionOptions>
      )}
    </S.Section>
  );
};

interface IInnerDrawerBody extends I.IDrawerBody {
  menuOpen?: boolean;
  onClose?: () => void;
  rail?: boolean; // If true, the sidesheet is as a "Rail" component
  mobile?: boolean; // If true, we are on mobile device
  hideProfile?: boolean; //if true, we will hide the profile
  bottomRail?: boolean; //if we show a rail at the bottom (could happen on mobile)
  expandableRail?: boolean;
  onToggleRailDesing?: () => void;
  onLinkClick: () => void;
}

export const DrawerBody = ({
  // option: string;
  // onOptionChange: (v:string) => void;
  option, //option Value
  onOptionChange, //On changing Option Value
  onLinkClick,
  logo,
  title = undefined,
  menu,
  profile,
  mode = "light",
  menuOpen = true,
  hideProfile = false,
  onClose = undefined, //if undefined => not collapsable
  rail = false,
  // mobile = false,
  bottomRail = false,
  expandableRail = false,
  onToggleRailDesing = undefined,
  primaryColor = undefined,
}: IInnerDrawerBody) => {
  const [shortMedia, setShortMedia] = useState(false);
  const menuRef = useRef<null | HTMLDivElement>(null);
  const [_externalLinksVisible, set_externalLinksVisible] = useState(false);
  const [_profileVisible, set_profileVisible] = useState(false);
  const [_visibleFooter, set_visibleFooter] = useState(false);
  const [_visibleLogo, set_visibleLogo] = useState(false);

  const { mobile, tablet, desktop } = useDeviceSizeCheck();

  //_externalLinksVisible
  useEffect(() => {
    set_externalLinksVisible(
      menu?.externalLinks &&
        !(desktop && menu.externalLinks.hideOnDescktop) &&
        !(tablet && menu.externalLinks.hideOnTablet) &&
        !(mobile && menu.externalLinks.hideOnMobile)
    );
  }, [desktop, tablet, mobile, menu.externalLinks]);
  //_profileVisible
  useEffect(() => {
    set_profileVisible(
      !hideProfile &&
        profile &&
        !(desktop && profile.hideOnDescktop) &&
        !(tablet && profile.hideOnTablet) &&
        !(mobile && profile.hideOnMobile)
    );
  }, [tablet, mobile, hideProfile, profile, desktop]);
  //_visibleFooter
  useEffect(() => {
    set_visibleFooter(
      !bottomRail && (_profileVisible || _externalLinksVisible)
    );
  }, [bottomRail, _profileVisible, _externalLinksVisible]);
  //_visibleLogo
  useEffect(() => {
    set_visibleLogo(
      !bottomRail &&
        !(desktop && logo.hideOnDescktop) &&
        !(tablet && logo.hideOnTablet) &&
        !(mobile && logo.hideOnMobile)
    );
  }, [
    bottomRail,
    tablet,
    mobile,
    desktop,
    logo.hideOnDescktop,
    logo.hideOnTablet,
    logo.hideOnMobile,
  ]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (menuRef?.current) {
        setShortMedia(
          menuRef.current.scrollHeight > menuRef.current.offsetHeight
        );
      }
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return !menu ? null : (
    <S.Menu ref={menuRef} $rail={rail} $bottomRail={bottomRail}>
      {expandableRail && (
        <S.ExpandRailButton $rail={rail} onClick={onToggleRailDesing} />
      )}

      {_visibleLogo && (
        <S.MenuHeader $shortMedia={shortMedia} $rail={rail}>
          <S.LogoTitle>
            {logo && (
              <S.Logo
                src={mode === "light" ? logo.light : logo.dark}
                $rail={rail}
                alt="logo"
              />
            )}
            {!rail && title && (
              <Typography
                family="heading"
                variant="h2"
                style={{ margin: "10px 0 0 0" }}
              >
                {title}
              </Typography>
            )}
          </S.LogoTitle>

          {onClose && <S.CloseButton onClick={onClose} />}
        </S.MenuHeader>
      )}
      <S.MenuBody $shortMedia={shortMedia} $bottomRail={bottomRail}>
        {menu.mainSection && (
          <OptionsSection
            {...menu.mainSection}
            value={option}
            onChange={onOptionChange}
            rail={rail}
            mobile={mobile}
            bottomRail={bottomRail}
            primaryColor={primaryColor}
          />
        )}
        {menu.adminSection && (
          <OptionsSection
            {...menu.adminSection}
            value={option}
            onChange={onOptionChange}
            rail={rail}
            mobile={mobile}
            bottomRail={bottomRail}
            primaryColor={primaryColor}
          />
        )}
      </S.MenuBody>

      {_visibleFooter && (
        <S.MenuFooter>
          {_externalLinksVisible && (
            <LinksSection
              {...menu.externalLinks}
              rail={rail}
              mobile={mobile}
              onLinkClick={onLinkClick}
              primaryColor={primaryColor}
            />
          )}
          {_profileVisible && (
            <S.ProfileAvatar $shortMedia={shortMedia} $rail={rail}>
              <AvatarDropdown
                {...profile}
                mode={mode}
                dropPosition={"top"}
                showDescription={!rail}
              />
            </S.ProfileAvatar>
          )}
        </S.MenuFooter>
      )}
    </S.Menu>
  );
};
