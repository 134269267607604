import styled from "styled-components";
import * as COLOR from "./../../color";
import * as CONST from "./../../const";

// Définir les types pour les propriétés de Option
interface OptionProps {
  disabled?: boolean;
  $preSelected?: boolean;
  $isSelected?: boolean;
  $useCheckbox?: boolean;
  $withImg?: boolean;
  $customDisplay?: boolean;
}

export const ImgAsIcon = styled.img`
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
  height: 32px;
  width: 32px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.23em;
`;
export const MainLabel = styled.div`
  font-size: 1em;
`;
export const SubLabel = styled.div`
  opacity: 0.4;
  font-size: 0.85em;
`;

export const MenuContainer = styled.div`
  font-weight: normal;
  color: #14182a;
  background: ${COLOR.white};
  // padding: 4px 0; //not beautiful on Drop
  outline: none;
`;

export const Option = styled.div<OptionProps>`
  align-items: center;
  border-bottom: ${CONST.border_single_snow};
  display: flex;
  gap: 8px;

  height: 48px;
  &:last-of-type {
    border-bottom: none;
  }

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  margin: 0 ${CONST.spacing_m};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};

  ${(props) =>
    props.$preSelected &&
    !props.$isSelected &&
    `
      background-color:${COLOR.silver};
      margin: 0;
      padding: 0 24px;
    `}

  &:hover {
    ${(props) =>
      !props.disabled &&
      !props.$isSelected &&
      `
      background-color:${COLOR.snow};
      margin: 0;
      padding: 0 24px;
    `}
  }

  ${(props) =>
    props.$customDisplay && `padding: 0 !important; margin:0 !important`}

  //if with img or icon :
  /* ${(props) => props.$withImg && "margin-left:16px;"} */

  ${(props) =>
    props.$isSelected &&
    !props.$useCheckbox &&
    `
      background-color:${COLOR.interaction};
      margin: 0;
      padding: 0  ${CONST.spacing_m};
      color: ${COLOR.white};
    `}
`;
