//@ts-nocheck
import React, { useEffect, useRef } from "react";
import { Overlay } from "../Overlay";
import { Icon } from "../Icon";
import { IconButton } from "../Button/IconButton";
import { zindex_lowest_modal_level } from "../const";

import * as S from "./modal.styles";
import * as I from "./interfaces";

//nestingDepth = 5, //should not be > const (like 5 for example)
export function Modal({
  children,
  onClose = () => {},
  closeOnOverlay = true,
  closeOnEscape = true,
  transition = "top-down",
  addCloseButton = false,
}: I.IModal) {
  const zIndex = zindex_lowest_modal_level;
  const containerRef = useRef(null);

  const tryCloseOnEscape = () => {
    closeOnEscape && onClose();
  };
  const onKeyPressed = (e) => {
    e.stopPropagation();
    if (e.key === "Escape") {
      tryCloseOnEscape();
    }
  };

  const makeItClosable = () => {
    const handleClickOutside = (e) => {
      if (containerRef?.current && !containerRef?.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  };
  useEffect(() => {
    containerRef.current.focus();
    if (!closeOnOverlay) return;
    setTimeout(makeItClosable, 100);
  }, []);

  // useEffect(() => {
  //   containerRef.current.focus();
  // }, []);

  return (
    <Overlay zIndex={zIndex} onEscape={tryCloseOnEscape}>
      <S.ModalContainer
        ref={containerRef}
        onKeyDown={onKeyPressed}
        tabIndex={`${zIndex}`}
        $transition={transition}
      >
        {addCloseButton ? (
          <S.WithCloseBtn>
            <S.CloseBtn>
              <IconButton
                noBorder
                size="s"
                icon={<Icon name="InterfaceAdd45" />}
                onClick={onClose}
                onKeyDown={function noRefCheck() {}}
                variant="tertiary"
              />
            </S.CloseBtn>
            {children}
          </S.WithCloseBtn>
        ) : (
          children
        )}
      </S.ModalContainer>
    </Overlay>
  );
}
