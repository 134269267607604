import styled from "styled-components";
import * as C from "./constants";
import * as CONST from "./../const";

function arrowPlacement(placement) {
  switch (placement) {
    case "auto":
    case "auto-start":
    case "auto-end":
      return `bottom: -4px;`;
    case "top":
    case "top-start":
    case "top-end":
      return `bottom: -4px;`;
    case "bottom":
    case "bottom-start":
    case "bottom-end":
      return `top: -4px;`;
    case "right":
    case "right-start":
    case "right-end":
      return `left: -4px;`;
    case "left":
    case "left-start":
    case "left-end":
      return `right: -4px;`;
    default:
      return "";
  }
}
export const Container = styled.div``;
export const ElementDiv = styled.div`
  position: relative;
  display: ${(props) => (props.$autoWidth ? "block" : "inline-block")};
`;

export const PopperDiv = styled.div`
  z-index: ${CONST.zindex_drop};

  position: relative;
  ${(props) => C.CONTAINER_MODE_PROPERTIES[props.$background]}

  transition:opacity 0.4s ease-in-out;
  visibility: ${(props) => (props.$open ? "visible" : "hidden")};
  opacity: ${(props) => (props.$open ? "1" : "0")};

  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};

  font-size: 14px;
  line-height: 1.71;
  //
  // box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  //
  border-radius: 4px;
  ${(props) => props.$shortPlacement && C.DROP_CORNERS[props.$shortPlacement]}
`;

export const PopperBody = styled.div`
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth};`}
  &:focus-visible {
    outline: none;
  }
  ${(props) =>
    props.$maxHeight &&
    `
    max-height: ${props.$maxHeight};

    overflow-y: auto;
    overscroll-behavior: contain;

    ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
      width: 24px;
      background-clip: padding-box;
      border: 8px solid transparent;
    }    
    ::-webkit-scrollbar-thumb {       
      border-radius: 12px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {        
      background-color: #e0e0e0;
      min-height:45px;
    }   
    &:hover {
    }
    `}
`;

export const PopperArrow = styled.div`
  ${(props) => C.CONTAINER_MODE_PROPERTIES[props.$background]};

  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;

  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  & {
    visibility: hidden;
  }

  &::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }
  ${(props) => props.$popperPlacement && arrowPlacement(props.$popperPlacement)}
`;
