//@ts-nocheck
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { dateTemplate, parseDate } from "./dateTemplateUtils";
import { DateErrorType } from "./../utils/dateErrorsType";

enum PositionType {
  Leteral = 0,
  D1 = 1,
  D2 = 2,
  M1 = 3,
  M2 = 4,
  Y = 5,
}
const isValidDigit = (type, d, dPrevious) => {
  switch (type) {
    case PositionType.D1:
      return /[0-3]/.test(d);
    case PositionType.M1:
      return /[0-1]/.test(d);
    case PositionType.D2:
      return dPrevious != undefined && +`${dPrevious}${d}` < 32;
    case PositionType.M2:
      return dPrevious != undefined && +`${dPrevious}${d}` < 13;
    case PositionType.Y:
      return /[0-9]/.test(d);
    default:
      return false;
  }
};

export interface IDateFormatInput {
  onChange: (Date?, DateErrorType?) => void;
  onBlur: () => void;
  value: Date;
  template: string;
  locale: string;
  disabled?: boolean;
}

/** Returns 0 if no digit exists. Returns 1 if the first and last char is a digit,... */
function positionOfLastDigit(str: string) {
  let _lastPos = 0;
  if (str?.length > 0) {
    let i = str.length;
    while (i && i--) {
      const c = str.charAt(i);
      if (c >= "0" && c <= "9") {
        _lastPos = i + 1;
        break;
      }
    }
  }
  return _lastPos;
}

export const DateFormatInput = React.forwardRef(
  (
    { onChange, onBlur, value, locale, disabled = false }: IDateFormatInput,
    ref
  ) => {
    const [template, setTemplate] = useState(""); //exp: 'DD. MM. YYYY' or 'MM/DD/YYY' (to build once)
    const [formattedValue, setFormattedValue] = useState(""); //The string inside the input field : exp - "12/0M/YYYY"
    const [selection, setSelection] = useState({ start: 0, end: 0 }); //To handle cursor position
    const [lastCursorPosition, setLastCursorPosition] = useState(0); //exp if the input contains "12/1'M/YYY" the position of the cursor can not be more than '4'
    //
    const [positionsType, setPositionsType] =
      useState<{ type: PositionType; value: string }[]>(); //exp:[{value:'2',type:D1},{value:'D',type:D2},...]
    const [digitsPositions, setDigitsPositions] = useState<number[]>(); //exp:[0,1,4,5,8,9,10,11]
    //
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      //...ref,
      focus: () => {
        inputRef.current.focus();
      },
      offsetWidth: inputRef.current.offsetWidth,
    }));

    useEffect(() => {
      setTemplate(dateTemplate(locale));
    }, [locale]);

    //To control the cursor position
    useEffect(() => {
      if (!selection || !inputRef.current) return; // prevent running on start or if ref is null
      const { start, end } = selection;

      // Get current selection range to avoid unnecessary updates
      const currentStart = inputRef.current.selectionStart;
      const currentEnd = inputRef.current.selectionEnd;

      // Only update if the selection has changed
      if (start !== currentStart || end !== currentEnd) {
        inputRef.current.setSelectionRange(start, end);
      }
    }, [selection]);

    //To control the input content (as string)
    useEffect(() => {
      inputRef.current.value = formattedValue;
    }, [formattedValue]);

    const handleNewFormattedValue = (str: string) => {
      setLastCursorPosition(positionOfLastDigit(str));
      setFormattedValue(str);
    };

    useEffect(() => {
      const _initDateFormatted = value ? dateTemplate(locale, value) : "";
      handleNewFormattedValue(_initDateFormatted);
    }, [value, locale]);

    const getPositions = () => {
      let _dOrder = 1;
      let _mOrder = 1;
      const getType = (x) => {
        switch (x) {
          case "d":
          case "D":
            return _dOrder++ === 1 ? PositionType.D1 : PositionType.D2;
          case "m":
          case "M":
            return _mOrder++ === 1 ? PositionType.M1 : PositionType.M2;
          case "y":
          case "Y":
            return PositionType.Y;
          default:
            return PositionType.Leteral;
        }
      };

      const digitsPositions = [];
      const positions = template.split("").map((x, i) => {
        const type = getType(x);
        if (type !== PositionType.Leteral) {
          digitsPositions.push(i);
        }
        return {
          type,
          value: x,
        };
      });

      return { positions, digitsPositions };
    };

    //setting positionsType & digitsPositions
    useEffect(() => {
      if (!template) return;
      //else

      const { positions, digitsPositions } = getPositions();

      setDigitsPositions(digitsPositions);
      setPositionsType(positions);
    }, [template]);

    const updatePosition = (
      positionsTypeClone,
      position,
      d,
      dPrevious = undefined
    ) => {
      let isValidPartially = false;
      if (isValidDigit(positionsTypeClone[position].type, d, dPrevious)) {
        positionsTypeClone[position] = {
          ...positionsTypeClone[position],
          value: d,
        };
        isValidPartially = true;
      }
      return isValidPartially;
    };
    const handleFocus = (e) => {
      //setSelection({ start: lastCursorPosition, end: lastCursorPosition });
      let cursorPosition = formattedValue.length;
      const chars = formattedValue.split("");
      for (let i = 0; i < chars.length; i++) {
        if (["d", "m", "y"].includes(chars[i].toLowerCase())) {
          cursorPosition = i;
          break;
        }
      }
      const startCursorPosition =
        e.target.selectionStart < cursorPosition
          ? e.target.selectionStart
          : cursorPosition;
      const lastCursorPosition =
        e.target.selectionEnd < cursorPosition
          ? e.target.selectionEnd
          : cursorPosition;

      setSelection({ start: startCursorPosition, end: lastCursorPosition });
    };
    const handleBlur = (e) => {
      completeChange(e.target.value);
      onBlur && onBlur();
    };
    const completeChange = (valStr) => {
      if (valStr === null) {
        onChange(null, null); //empty date
        return;
      }

      //If the formatted string in the input is a valide date => call onChange
      if (!valStr || /[dmy]/gi.test(valStr)) {
        return; //Not valid date
      }

      const date = parseDate(valStr, template);
      onChange(date);
    };

    const handleKeyDown = (e) => {
      if (e.target.selectionStart !== e.target.selectionEnd) return;

      //Pressed Enter
      if (e.keyCode == 13) {
        completeChange(e.target.value);
      }

      //Pressed Backspace => delete
      if (e.keyCode == 8) {
        let _cursorPosition = e.target.selectionStart;
        // e.target.selectionEnd;
        e.preventDefault();
        if (_cursorPosition === 0) return;
        //else
        for (let i = _cursorPosition - 1; i >= 0; i--) {
          if (/[0-9]/.test(e.target.value[i])) {
            _cursorPosition = i;
            break;
          }
        }

        const _formatted =
          e.target.value.substring(0, _cursorPosition) +
          template.substring(_cursorPosition);

        handleNewFormattedValue(_formatted);
        inputRef.current.value = _formatted;
        setSelection({ start: _cursorPosition, end: _cursorPosition });
      }
    };

    const handleChange = (e) => {
      if (e.target.value === "") {
        handleNewFormattedValue("");
        completeChange(null);
        return;
      }
      //else

      //internal function
      const getNextDigitPosition = (i) => {
        let result = i;
        if (i < positionsType.length - 1) {
          if (positionsType[i].type === PositionType.Leteral) {
            result = getNextDigitPosition(i + 1); //recursive
          }
        }
        return result;
      };

      let _cursorPosition = e.target.selectionEnd;
      //input field in edit mode(insert mode)
      const _value =
        e.target.value.substr(0, _cursorPosition) +
        e.target.value.substr(_cursorPosition + 1);

      let _formatted = formattedValue;

      const _digits = _value.replace(/[^0-9]/g, "").split(""); //will get digits

      //limit exceeded
      if (_digits.length > 8) {
        _cursorPosition--;
        setSelection({ start: _cursorPosition, end: _cursorPosition });
        inputRef.current.value = _formatted;
        return;
      }

      let _isValidDigits = true;
      const { positions } = getPositions();
      for (let i = 0; i < _digits.length; i++) {
        _isValidDigits = updatePosition(
          positions,
          digitsPositions[i],
          _digits[i],
          i > 0 ? _digits[i - 1] : undefined
        );
        if (!_isValidDigits) {
          break;
        }
      }
      if (_isValidDigits) {
        setPositionsType(positions);
        _formatted = positions.map((x) => x.value).join("");
        handleNewFormattedValue(_formatted);
        _cursorPosition = getNextDigitPosition(_cursorPosition);
      } else if (_cursorPosition > 0) {
        _cursorPosition--;
      }

      inputRef.current.value = _formatted;
      setSelection({ start: _cursorPosition, end: _cursorPosition });
    };

    return (
      <input
        ref={inputRef}
        type="text"
        onClick={handleFocus}
        //value={formattedValue}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        placeholder={template} //if no placeholder take the label if defined
        disabled={disabled}
      />
    );
  }
);
