import styled from "styled-components";
import * as COLOR from "./../color";
import * as CONST from "./../const";
import * as C from "./toggle.constants";

interface OptionProps {
  $size: keyof typeof C.BUTTON_PADDING;
  $sameWidth?: boolean;
  $isActive?: boolean;
  $variant: "primary" | "secondary";
  $noValue: boolean;
}

interface ActiveIndicatorProps {
  $variant: "primary" | "secondary";
  $offset: {
    left: string;
    width: string;
  };
  $size: keyof typeof C.BUTTON_HEIGHT;
}

interface ContainerProps {
  $size: keyof typeof C.CONTAINER_SIZE_PROPERTIES;
  $auto?: boolean;
  $variant: "primary" | "secondary";
}

export const OptionLabel = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

export const Option = styled.button<OptionProps>`
  align-items: center;
  background: ${(props) =>
    props.$noValue ? "rgb(255 255 255 / 40%)" : "transparent"};
  border: none;
  color: ${COLOR.mercury};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;

  box-sizing: border-box;

  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};
  font-size: 14px;
  line-height: 20px;

  flex: auto;
  margin: 0;
  text-decoration: none;
  padding: ${(props) => C.BUTTON_PADDING[props.$size]};
  z-index: 1;

  ${(props) =>
    props.$sameWidth &&
    `width: 100%;
     flex: 1 1 0; 
   `}

  transition: color 0.4s;

  ${(props) =>
    props.$isActive &&
    `color: ${
      props.$variant === "primary" ? COLOR.white : COLOR.interaction
    };`};

  font-weight: ${(props) => props.$isActive && CONST.weight_semi_bold};
`;

export const ActiveIndicator = styled.span<ActiveIndicatorProps>`
  border-radius: ${CONST.radius_px4};
  background: ${(props) =>
    props.$variant === "primary" ? COLOR.interaction : COLOR.white};
  position: absolute;
  transition: 0.4s;
  top: 0;
  left: ${(props) => props.$offset.left};
  width: ${(props) => props.$offset.width};
  height: ${(props) => C.BUTTON_HEIGHT[props.$size]};
  z-index: 0;
`;

export const Options = styled.nav`
  display: inline-flex;
  gap: 4px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div<ContainerProps>`
  border: ${CONST.border_single_snow};
  border-radius: ${CONST.radius_px4};
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  justify-content: left;
  ${(props) => C.CONTAINER_SIZE_PROPERTIES[props.$size]};
  ${(props) => props.$auto && "width: 100%;"};
  background: ${(props) =>
    props.$variant === "primary" ? COLOR.white : COLOR.silver};
`;
