export const radius_none = "none";
export const radius_px4 = "4px";
export const radius_px8 = "8px";
export const radius_px12 = "12px";
export const radius_px16 = "16px";
export const radius_rounded = "36px";
export const radius_circle = "50%";

// xxs: 4,
// xs: 6,
// s: 8,
// m: 10,
// l: 12,
// xl: 14,
