//@ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { Drop } from "../../Drop";
import { SingleDateInput } from "../../DateInput/SingleDateInput";
import { DateSelector, IDateSelector } from "../../Calendar/DateSelector";
import { SideSheetModal } from "../../SideSheet";
import { useMobileSizeCheck } from "../../utils/useMediaSizeCheck";

import * as S from "./style";

import {
  IInputContainer,
  IWithLabel,
  IWithHelperMessage,
  IWithInformation,
  ICommonInput,
} from "../../utils/inputCommon/interfaces";

export interface IDatePicker
  extends IWithLabel,
    IWithHelperMessage,
    IWithInformation,
    IInputContainer,
    ICommonInput,
    IDateSelector {
  /** The date value */
  value?: Date;
  /** Fires on date change. */
  onChange: (Date) => void;
  /**If true, the width of the input can not be less than the width of the calendar, even if the container width is smaller. */
  minWidthAsCalendar?: boolean;
  /** If true, the calendar drop-down list will remain open after choosing the date. Will close on Escape or clicking the calendar */
  keepOpen?: boolean;
  /** The method to show the calendar: on clicking the input, on clicking the right button, or never show the calendar */
  showCalendar?: "never" | "onClick" | "onButtonClick";
  /**if true, the dropdown will be replaced by a down-sidesheet on mobile view */
  downSheetOnMobile?: boolean;
}

const minWidthOfCalendar = 324; //limit to switch to same width

export const SingleDatePicker = ({
  value,
  onChange,
  showCalendar = "onClick",
  keepOpen = false,
  minWidthAsCalendar = true,
  downSheetOnMobile = true,
  ...rest
}: IDatePicker) => {
  const [theSameWidth, setTheSameWidth] = useState(false);
  const [sideSheetOpened, setSideSheetOpened] = useState(false);
  const dropRef = useState(null);
  const [dropPlacement, setDropPlacement] = useState(null);
  const inputContainerRef = useRef(null);
  const inputRef = useRef(null);
  const isMobile = useMobileSizeCheck();
  const _useDownSheet = downSheetOnMobile && isMobile;

  /** receives a date in its simplest form and transforms it to the desired form before rendering it  */
  const handleChange = (date) => {
    onChange(date);
  };

  useEffect(() => {
    if (_useDownSheet) return;
    //usefull only when using dropdown for calendar

    const updateDimensions = () => {
      const inputWidth = inputContainerRef.current.offsetWidth;
      setTheSameWidth(inputWidth > minWidthOfCalendar);
    };
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const toggleVisibility = (e) => {
    if (_useDownSheet) {
      setSideSheetOpened(!sideSheetOpened);
    } else {
      dropRef?.current?.toggle(e);
    }
  };
  const handleInputClick = (e) => {
    if (showCalendar === "onClick") {
      toggleVisibility(e);
    }
  };
  const handleButtonClick = (e) => {
    if (showCalendar === "onButtonClick") {
      toggleVisibility(e);
    }
  };
  function handleClose() {
    if (_useDownSheet) {
      setSideSheetOpened(false);
    } else {
      dropRef?.current?.close();
    }
  }

  function DropBody() {
    return (
      <DateSelector
        {...rest}
        auto={_useDownSheet || theSameWidth}
        value={value}
        onChange={(dt) => {
          handleChange(dt);
          if (!keepOpen) handleClose();
        }}
      />
    );
  }

  return _useDownSheet ? (
    <div>
      <S.DateInputContainer
        ref={inputContainerRef}
        onClick={handleInputClick}
        $minWidth={minWidthAsCalendar ? `${minWidthOfCalendar}px` : undefined}
      >
        <SingleDateInput
          ref={inputRef}
          {...rest}
          stuckPlacement={false && dropPlacement} //disabling this feature for the moment (border-reduis the same even with same width)
          value={value}
          onChange={handleChange}
          withPickerButton={showCalendar !== "never"}
          onPickerClick={handleButtonClick}
        />
      </S.DateInputContainer>
      <SideSheetModal
        closeMe={handleClose}
        position="bottom"
        // fullscreen={true}
        open={sideSheetOpened}
        closeOnSwip={false}
      >
        <DropBody />
      </SideSheetModal>
    </div>
  ) : (
    <Drop
      ref={(node) => {
        setDropPlacement(node?.shortPlacement);
        dropRef.current = node;
      }}
      background="white"
      body={<DropBody />}
      placement="bottom"
      show="controlled" //we will controlle it: onClick or onButtonClick
      placement="bottom-start"
      offset={1}
      autoWidth={true}
      sameWidth={theSameWidth}
    >
      <S.DateInputContainer
        ref={inputContainerRef}
        onClick={handleInputClick}
        $minWidth={minWidthAsCalendar ? `${minWidthOfCalendar}px` : undefined}
      >
        <SingleDateInput
          ref={inputRef}
          {...rest}
          stuckPlacement={false && dropPlacement} //disabling this feature for the moment (border-reduis the same even with same width)
          value={value}
          onChange={handleChange}
          withPickerButton={showCalendar !== "never"}
          onPickerClick={handleButtonClick}
        />
      </S.DateInputContainer>
    </Drop>
  );
};
