import styled from "styled-components";
import * as COLOR from "./../color";
import * as CONST from "./../const";
import * as C from "./avatarDropdown.constants";

interface ModeProps {
  $mode?: C.TypesType;
}

interface NarrowProps {
  $narrowForContent?: boolean;
}

interface MenuOpenProps {
  $menuOpen?: boolean;
}

export const ArrowAreaButton = styled.div<ModeProps>`
  cursor: pointer;
  display: flex;
  ${(props) => C.EMPLOYEE_TITLE_MODE_PROPERTIES[props.$mode || C.Types.Light]};
  .menu-button {
    z-index: 40;
    border: none;
    position: fixed;
    top: 40px;
    right: 20px;
    padding: 12px;
    font-size: 1em;
    background: none;
    background: #fff;
    transition: background 0.1s linear;
    cursor: pointer;
  }
  .menu-button--full {
    top: 40px;
    left: 20px;
  }
`;

export const ChevronIcon = styled.div<MenuOpenProps>`
  font-size: 0.6em;
  transition: transform 0.3s ease-out;
  ${(props) => props.$menuOpen && `transform: rotate(180deg) translateY(6px);`}
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
`;

export const ImageDesc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

export const EmployeeInfoContainer = styled.div<NarrowProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
  height: 40px;
  margin: 0 12px;
  ${(props) =>
    props.$narrowForContent &&
    `mask-image: linear-gradient(to left, transparent 0%, black 60%);`}
`;

export const EmployeeName = styled.p<ModeProps>`
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  white-space: nowrap;
  ${(props) => C.EMPLOYEE_NAME_MODE_PROPERTIES[props.$mode || C.Types.Light]};
`;

export const EmployeeTitle = styled.p<ModeProps>`
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  white-space: nowrap;
  ${(props) => C.EMPLOYEE_TITLE_MODE_PROPERTIES[props.$mode || C.Types.Light]};
`;

export const Wrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 56px;
  position: relative;
  padding: 8px;
`;

export const Menu = styled.div`
  background-color: ${COLOR.white};
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: ${CONST.shadow_very_highlighted};
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  font-size: 14px;
  line-height: 20px;
`;

export const MenuOption = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  padding: ${CONST.spacing_s} ${`calc(${CONST.spacing_s}*2)`};
  opacity: 0.6;
  text-decoration: none;
  color: ${COLOR.carbon};

  &:hover {
    background-color: ${`${COLOR.success}14`};
    opacity: 1;
  }
  &:hover .menu-option-icon {
    color: ${COLOR.interaction};
  }
  &:focus,
  &:active {
    color: ${COLOR.white};
    background-color: ${COLOR.interaction};
    .menu-option-icon {
      color: ${COLOR.white};
    }
  }
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const MenuLogout = styled(MenuOption)`
  margin: ${CONST.spacing_s};
`;

export const MenuOptions = styled.div`
  height: auto;
  padding: ${CONST.spacing_s};
  border-bottom: ${CONST.border_single_silver};
`;
