import React, { useState, useEffect } from "react";
import { Select } from "../../../components/Select";
import { getCountries } from "./../../../api/primos";
import { ISelectMenuOption, DirectionType } from "../../../types";

// Cache to store the countries loaded from the server, shared across all component instances
let countriesCache: ISelectMenuOption[] | null = null;

interface CountriesFilterProps {
  directionType: DirectionType;
  selected?: ISelectMenuOption[];
  onSelect?: (lst: ISelectMenuOption[]) => void;
}

export const SelectCountries: React.FC<CountriesFilterProps> = ({
  directionType,
  selected = [],
  onSelect = (v) => {},
}) => {
  const [allCountries, setAllCountries] = useState<ISelectMenuOption[]>([]);

  useEffect(() => {
    // If countries are already cached, use them
    if (countriesCache) {
      setAllCountries(countriesCache);
    } else {
      // Otherwise, fetch countries from the server and store them in the cache
      getCountries(directionType).then((countries) => {
        const labeledCountries = countries.map((x: string) => ({
          label: x,
          value: x,
        }));
        countriesCache = labeledCountries;
        setAllCountries(labeledCountries);
      });
    }
  }, [directionType]);

  return (
    <Select
      label="Countries"
      options={allCountries}
      size="xl"
      multi={true}
      selected={selected}
      onSelect={onSelect}
      downSheetOnMobile={false}
      autosuggest
    />
  );
};
