import React from "react";
import { SideSheetModal } from "../SideSheetModal";
import { useMobileSizeCheck } from "../../utils/useMediaSizeCheck";
// @ts-ignore
import * as S from "./sideSheetContainer.styles";
import * as I from "./interfaces";

const SideSheet = ({
  children,
  position,
  open = false,
  rail = false,
  mode = "light",
}: I.SideSheetProps) => {
  return (
    <S.SideSheet $position={position} $open={open} $rail={rail}>
      {children}
    </S.SideSheet>
  );
};

export const SideSheetContainer = ({
  children,
  sideSheet,
  position,
  open = false,
  closeSideSheet,
  closable = true,
  rail = false,
  scrollableContent=true,
  modalOnMobile = closable, //the default value: if can not be closed, then can not be modal
  mobilePosition = position, //the default is the original position but can be different
}: I.SideSheetContainerProps) => {
  const isMobile = useMobileSizeCheck();

  const _open = !closable || open;
  const handleCloseSideSheet = () => {
    if (closable && closeSideSheet) {
      closeSideSheet();
    }
  };

  return isMobile && modalOnMobile ? (
    <div>
      <div>{children}</div>
      <SideSheetModal
        open={_open}
        closeMe={handleCloseSideSheet}
        position={mobilePosition}
      >
        <S.SideSheet $position={mobilePosition} $open={_open}>
          {sideSheet}
        </S.SideSheet>
      </SideSheetModal>
    </div>
  ) : (
    <S.SideSheetContainerWrapper>
      <S.SideSheetContainer $position={position}>
        {sideSheet && (
          <SideSheet position={position} open={_open} rail={rail}>
            {sideSheet}
          </SideSheet>
        )}
        <S.Content $scrollable={scrollableContent}>{children}</S.Content>
      </S.SideSheetContainer>
    </S.SideSheetContainerWrapper>
  );
};
