import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "./../Icon";
import { Select } from "./../Select";
import { Toggle } from "./../Toggle";
import { BasicButton } from "./../Button";
import {
  RecipientScope,
  IFilterProperties,
  ISelectMenuOption,
} from "./../../types";

import { TextInput } from "./../TextInput";
import { fetchUsersByPrefix } from "../../auth/utils";
import { MAIN_COLOR } from "./../../config";

import styles from "./style.module.scss";

interface FeedsFilterProps {
  properties?: IFilterProperties;
  closeMe: () => void;
  onFilterDone: (props: IFilterProperties) => void;
}

export const CheersFilter: React.FC<FeedsFilterProps> = ({
  properties,
  closeMe,
  onFilterDone,
}) => {
  const [recipientsType, setRecipientsType] = useState<RecipientScope>("Primo");
  const [authors, setAuthors] = useState<ISelectMenuOption[]>();
  const [primoRecipients, setPrimoRecipients] = useState<ISelectMenuOption[]>();
  const [extRecipients, setExtRecipients] = useState<string[]>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setendDate] = useState<Date>();

  useEffect(() => {
    setRecipientsType(properties?.recipientsType ?? "Primo");
    setAuthors(properties?.authors ?? []);
    setPrimoRecipients(properties?.primoRecipients ?? []);
    setExtRecipients(properties?.extRecipients ?? []);
    setStartDate(properties?.startDate);
    setendDate(properties?.endDate);
  }, [properties]);

  const fetchFromPrimoByPrefix = useCallback(
    async (prefix: string) => {
      let result = await fetchUsersByPrefix(prefix);
      if (!!result?.length && !!primoRecipients?.length) {
        const primoCopiesIds = new Set(primoRecipients.map((x) => x.value));
        result = result.filter(
          (item: ISelectMenuOption) => !primoCopiesIds.has(item.value)
        );
      }
      return result;
    },
    [primoRecipients]
  );

  const handleSubmitFilter = (f: any) => {
    //TODO: Verifier que les extRecipients sont des emails

    const _extRecipients = extRecipients?.filter((x) => x.trim() !== "");
    onFilterDone({
      recipientsType,
      authors,
      primoRecipients:
        recipientsType === "Primo" || recipientsType === "Mixed"
          ? primoRecipients
          : [],
      extRecipients:
        recipientsType === "External" || recipientsType === "Mixed"
          ? _extRecipients
          : [],
      startDate,
      endDate,
    });
  };

  const handleExtRecipientsChange = (val: string) => {
    const lst = val?.split(/[;, \t\n\r]+/) ?? [];
    setExtRecipients(lst);
  };

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.closeIcon} onClick={closeMe}>
        <Icon name="Cross" size={32} />
      </div>

      <Toggle
        auto
        onChange={(v) => setRecipientsType(v as RecipientScope)}
        options={[
          {
            label: "To Primos",
            value: "Primo",
          },
          {
            label: "To Externals",
            value: "External",
          },
          {
            label: "To Mixed",
            value: "Mixed",
          },
        ]}
        required
        value={recipientsType}
      />

      <Select
        label="Author"
        placeholder="Add name or email..."
        // options={EMPLOYEES1}
        fetchOptionsByPrefix={fetchFromPrimoByPrefix}
        size="xl"
        autosuggest
        multi={true}
        // useCheckbox={false}
        selected={authors}
        onSelect={(v) => {
          setAuthors(v);
        }}
      />

      {(recipientsType === "Primo" || recipientsType === "Mixed") && (
        <Select
          label="Primo Recipients"
          placeholder="Add name or email..."
          fetchOptionsByPrefix={fetchFromPrimoByPrefix}
          size="xl"
          autosuggest
          multi={true}
          selected={primoRecipients}
          onSelect={(v) => {
            setPrimoRecipients(v);
          }}
        />
      )}
      {(recipientsType === "External" || recipientsType === "Mixed") && (
        <TextInput
          size="xl"
          label="External recipients" // email"
          placeholder="Add emails separated by ';'..."
          value={!extRecipients?.length ? "" : extRecipients?.join("; ")}
          onChange={(v) => handleExtRecipientsChange(v)}
        />
      )}
      {/* <DoubleDatePicker
        size="xl"
        label="Date"
        startDate={startDate}
        endDate={endDate}
        startLabel="Start date"
        endLabel="End date"
        onStartChange={(d) => setStartDate(d)}
        onEndChange={(d) => setEndDate(d)}
      /> */}
      <BasicButton
        onClick={handleSubmitFilter}
        label="Find"
        variant="primary"
        size="xl"
        corners="squared"
        auto
        color={MAIN_COLOR}
      />
    </div>
  );
};
