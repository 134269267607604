import React, { useState, useEffect, useRef, useCallback } from "react";

import { SideSheetModal } from "./../components/SideSheet";
import { Spinner } from "../components/Spinner";
import { Icon } from "../components/Icon";
import { IconButton, LabelButton } from "../components/Button";
import { getFeedsCards, CheerShortProps } from "./../api/cheers";
import { CheerCard, CheerDetails } from "./../components/CheersCard";
import { CheersLayout } from "./../components/CheersLayout";
import { CheersFilter } from "./../components/Filter/CheersFilter";
import { ChoosenFilter } from "./../components/Filter/ChoosenFilter";
import { useMobileSizeCheck } from "./../components/utils/useMediaSizeCheck";
import { CheersHelpButton } from "./../components/CheersHelpButton";
import { MAX_FEED_PAGES_TO_LOAD } from "./../config";

import { IFilterProperties } from "./../types";
import styles from "./style.module.scss";

export interface CheerCardProps extends CheerShortProps {
  onClick?: (id: string) => void;
}

export default function Feed() {
  const [cheerId2show, setCheerId2show] = useState<string>(); //Show details of cheers if this id is given
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<IFilterProperties>();
  //
  const [list, setList] = useState<CheerShortProps[]>([]);
  const [loadedPages, setLoadedPages] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>();
  const [totalFeeds, setTotalFeeds] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  //
  const homeWrapRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobileSizeCheck();

  const loadNextPageOfFeeds = useCallback(async () => {
    if (loadedPages >= MAX_FEED_PAGES_TO_LOAD) return; //We are asked to load a max of 20 feeds (2pages)

    if (!totalPages || loadedPages < totalPages) {
      //if !totalPages => nothing is loaded yet
      try {
        setIsLoading(true);
        const { data, meta } = await getFeedsCards({
          filter,
          page: loadedPages + 1,
        });
        setTotalPages(meta.pageCount);
        setTotalFeeds(meta.total);
        if (data?.length > 0) {
          setList([...list, ...data]);
          setLoadedPages((prev) => prev + 1);
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  }, [totalPages, loadedPages, filter, list]);

  useEffect(() => {
    const homeWrap = homeWrapRef.current;
    const handleScroll = () => {
      //When the user scrolled dowun and reached the end
      const tolerance = 1;
      if (
        homeWrap &&
        Math.abs(
          homeWrap.scrollHeight - homeWrap.scrollTop - homeWrap.clientHeight
        ) <= tolerance
      ) {
        !isLoading && loadNextPageOfFeeds();
      }
    };

    !!homeWrap && homeWrap.addEventListener("scroll", handleScroll);
    return () => {
      !!homeWrap && homeWrap.removeEventListener("scroll", handleScroll);
    };
  }, [filter, isLoading, loadNextPageOfFeeds]);

  useEffect(() => {
    loadNextPageOfFeeds();
  }, [filter]);

  const handleFilterChanged = (newFilter?: IFilterProperties) => {
    const cleanLoaded = () => {
      setList([]);
      setLoadedPages(0);
      setTotalPages(undefined);
      setTotalFeeds(undefined);
      setIsLoading(false);
    };
    cleanLoaded();
    setFilter(newFilter);
    setFilterVisible(false);
  };

  return (
    <CheersLayout>
      <div ref={homeWrapRef} className={styles.pageWrap}>
        <div className={styles.pageHeader}>
          <img src="/img/header3.png" alt="Header" />
        </div>
        <CheersHelpButton />
        <div className={styles.homeTitleWrap}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>Cheers Feed</h1>
            {!filter && (
              <IconButton
                // corners="rounded"
                icon={<Icon name="Filter2" size={26} />}
                size="m"
                onClick={() => {
                  setFilterVisible(true);
                }}
                variant="tertiary"
              />
            )}
          </div>
          <div className={styles.titleDesc}>
            A little Thank You goes a long way
          </div>
        </div>

        {filter && (
          <div className={styles.currentFilter}>
            <ChoosenFilter filter={filter} onChange={handleFilterChanged} />
            <LabelButton
              // corners="rounded"
              icon={<Icon name="Filter2" size={26} />}
              size="m"
              // label="Filter"
              onClick={() => {
                setFilterVisible(true);
              }}
              variant="tertiary"
            />
          </div>
        )}

        <div className={styles.homeContent}>
          <div className={styles.cheersListWrapper}>
            {!!totalFeeds && (
              <span className={styles.feedsNumber}>{totalFeeds}</span>
            )}
            {list &&
              list.map((x, i) => (
                <CheerCard
                  key={i}
                  {...x}
                  onClick={() => setCheerId2show(x.id)}
                />
              ))}
            {isLoading && <Spinner size="xl" variant="primary" />}
          </div>
        </div>
      </div>

      <SideSheetModal
        scrollableContent={false}
        open={!!cheerId2show}
        closeMe={() => setCheerId2show(undefined)}
        fullscreen
        position="right"
        closeOnSwip={false}
      >
        {cheerId2show && (
          <CheerDetails
            cheerId={cheerId2show}
            closeMe={() => setCheerId2show(undefined)}
          />
        )}
      </SideSheetModal>

      <SideSheetModal
        scrollableContent={false}
        open={!!filterVisible}
        closeMe={() => {
          setFilterVisible(false);
        }}
        fullscreen={isMobile}
        position="right"
        closeOnSwip={false}
      >
        {filterVisible && (
          <CheersFilter
            properties={filter}
            closeMe={() => setFilterVisible(false)}
            onFilterDone={handleFilterChanged}
          />
        )}
      </SideSheetModal>
    </CheersLayout>
  );
}
