import React from "react";
import { Icon } from "./../Icon";
import { formatDate } from "./../utils/date";
import { getFetchedUser } from "./../../auth/utils";
import { CheerShortProps } from "./../../api/cheers";
import styles from "./style.module.scss";

export interface CheerCardProps extends CheerShortProps {
  sentByMe?: boolean;
  receivedByMe?: boolean;
  onClick?: (id: string) => void;
}

export const CheerCard: React.FC<CheerCardProps> = ({
  id,
  toGroup,
  recipientType,
  groupeName,
  nickName,
  category,
  from,
  to,
  toCc,
  createdAt,
  sentByMe = false,
  receivedByMe = false,
  onClick,
}) => {
  const _to = toGroup
    ? groupeName || to?.map((x) => x.name)?.join(", ")
    : to?.map((x) => x.name)?.join(", ") || nickName;

  return (
    <div className={styles.cheersCard} onClick={() => !!onClick && onClick(id)}>
      <div className={styles.cheersCardLeft}>
        {(sentByMe || !receivedByMe) && (
          <div className={styles.avatarWrap}>
            <img
              className={styles.avatar}
              src={
                (!to?.length ? undefined : getFetchedUser(to[0].azureId)) ??
                "/img/default-avatar.jpg"
              }
              alt="recipient"
            />
            {!!to?.length && to?.length > 1 && (
              <div className={styles.imgNbr}>+{to?.length - 1}</div>
            )}
          </div>
        )}
        {receivedByMe && (
          <img
            className={styles.avatar}
            src={getFetchedUser(from.azureId) ?? "/img/default-avatar.jpg"}
            alt={from.name}
          />
        )}
        <div className={styles.cheersCardContent}>
          {!sentByMe && !receivedByMe && to && (
            <div className={styles.cheersCardTitle}>{_to}</div>
          )}
          {!sentByMe && !receivedByMe && (
            <div className={styles.category}>
              Received a <b>{category}</b>
            </div>
          )}
          {from && receivedByMe && (
            <div className={styles.cheersCardTitle}>
              <b>You</b> received a <b>{category}</b>
            </div>
          )}
          {sentByMe && to && (
            <div className={styles.cheersCardTitle}>
              <b>You</b> sent a <b>{category}</b>
            </div>
          )}

          {from && !sentByMe && (
            <div>
              Cheers from <b>{from?.name}</b>
            </div>
          )}
          {sentByMe && (
            <div>
              Cheers to <b>{_to}</b>
            </div>
          )}

          <div>{formatDate(createdAt)}</div>
        </div>
      </div>
      <Icon
        className={styles.chevronRight}
        name="BoldBasicsChevronRight"
        size={32}
      />
    </div>
  );
};
