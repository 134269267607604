import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgInterfaceDelete1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 10 10"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M6.414 5.354a.5.5 0 0 1 0-.708L9.78 1.28A.75.75 0 0 0 8.72.22L5.354 3.586a.5.5 0 0 1-.708 0L1.28.22A.75.75 0 0 0 .22 1.28l3.366 3.366a.5.5 0 0 1 0 .708L.22 8.72a.75.75 0 1 0 1.06 1.06l3.366-3.366a.5.5 0 0 1 .708 0L8.72 9.78a.75.75 0 0 0 1.06-1.06Z" />
  </svg>
);
const Memo = memo(SvgInterfaceDelete1);
export default Memo;
