//@ts-nocheck
import React from "react";

import { InputContainer } from "./InputContainer";
import { WithLabel } from "./WithLabel";
import { WithHelperMessage } from "./WithHelperMessage";
import { WithInformation } from "./WithInformation";

export const CommonInputWrapper = ({
  disabled = false, //InputContainer
  size = "l", //IWithLabel & InputContainer
  //
  informationMessage = "", //IWithInformation
  informationPosition = "bottom", //IWithInformation
  //
  helperMessage = null, //IWithHelperMessage
  alertState = undefined, //IWithHelperMessage & IWithLabel & InputContainer
  label = undefined, //IWithLabel
  hasPlaceholder = undefined, //ItWithLabel
  rightSideNode = undefined, //InputContainer
  leftSideNode = undefined, //InputContainer
  stuckPlacement = undefined, //InputContainer
  //
  onClick = undefined,
  empty,
  children,
}: I.ICommonInputWrapper & {
  empty: boolean;
  children: React.ReactElement;
}) => {
  return (
    <WithHelperMessage
      onClick={(e) => {
        onClick();
      }}
      alertState={alertState}
      helperMessage={helperMessage}
    >
      <WithInformation
        informationMessage={informationMessage}
        informationPosition={informationPosition}
      >
        <InputContainer
          alertState={alertState}
          size={size}
          disabled={disabled}
          rightSideNode={rightSideNode}
          leftSideNode={leftSideNode}
          stuckPlacement={stuckPlacement}
        >
          <WithLabel
            empty={empty}
            size={size}
            label={label}
            hasPlaceholder={hasPlaceholder}
            alertState={alertState}
          >
            {children}
          </WithLabel>
        </InputContainer>
      </WithInformation>
    </WithHelperMessage>
  );
};
