import { RecipientScope, RecipientType } from ".";
import { ISelectMenuOption } from "./../components/Select/interfaces";

export interface FullFilterProps {
  //Author properties
  authorCountries?: ISelectMenuOption[];
  authorDepartments?: ISelectMenuOption[];
  authors?: ISelectMenuOption[]; //TODO

  //Recipient properties
  recipientCountries?: ISelectMenuOption[];
  recipientDepartments?: ISelectMenuOption[];
  //
  primoRecipients?: ISelectMenuOption[]; //TODO
  extRecipients?: string[]; //TODO
  //
  recipientsType?: RecipientType; //"Individual" | "Group"
  recipientsScope?: RecipientScope; //"Primo" | "External" | "Mixed"

  //Other properties
  startDate?: Date;
  endDate?: Date;
  categories?: ISelectMenuOption[]; //TODO
  managerIncluded?: boolean;
  pushedToFeed?: boolean;
}

export interface AnalysisPrimoProps {
  azure_id: string;
  name: string;
  country: string;
  department: string;
}

export interface AnalysisPointProps {
  date: Date;
  from: AnalysisPrimoProps;
  toPrimo: AnalysisPrimoProps[];
  toExternal: string[];
  recipientType: RecipientType;
  recipientScope: RecipientScope;
  includedManager: boolean;
  publishedToFeed: boolean;
  category: string;
}

export interface GroupedByName {
  name: string;
  items: AnalysisPointProps[];
}

export interface AnalysisDataProps {
  cheers?: AnalysisPointProps[];
  cheersToIndividual?: AnalysisPointProps[];
  cheersToGroup?: AnalysisPointProps[];
  cheersToPrimos?: AnalysisPointProps[];
  cheersToExternals?: AnalysisPointProps[];
  cheersToMixeds?: AnalysisPointProps[];
  cheersManagerIncluded?: AnalysisPointProps[];
  cheersPushedToFeed?: AnalysisPointProps[];
  //
  primoRecipients?: ({ date: Date } & AnalysisPrimoProps)[];
  externalRecipients?: ({ date: Date } & { mail: string })[];
  allRecipients?: AnalysisPrimoProps[];
  //
  groupedByCategories?: GroupedByName[];
  //
  groupedBySourceCountry?: GroupedByName[];
  groupedBySourceDepartment?: GroupedByName[];
  groupedByTargetCountry?: GroupedByName[];
  groupedByTargetDepartment?: GroupedByName[];
}
export enum WinnerType {
  AllCheers = "Authors of Cheers",
  AtIndividual = "Authors @Individuals",
  AtGroup = "Authors @Groups",
  AtPrimos = "Authors @Primos",
  AtExternal = "Authors @Externals",
  AtMixed = "Authors @Mixed",
  ManagerIncluded = "Authors With Manager Included",
  PushedToFeed = "Authors Pushing To Feed",
  //
  PrimosRecipients = "Primos Recipients",
  ExternalsRecipients = "Externals Recipients",
  //
  Categories = "Categories",
  SourceCountries = "Source Countries",
  SourceDepartments = "Source Departments",
  RecipientsCountries = "Recipients Countries",
  RecipientsDepartments = "Recipients Departments",
}
export interface WinnerProps {
  name: string;
  count: number;
  cheers: AnalysisPointProps[];
  data?: any;
}

export interface FrameDotProps {
  date: string; // Represents the 'dataKey' for the X-axis
  count: number; // Represents the 'dataKey' for the Y-axis (values plotted on the line)
}
export interface ChartLineProps {
  name: string;
  color?: string; // Represents the stroke color of the line for each data point
  dates: Date[];
  dots?: FrameDotProps[]; //Will change with the time-frame (mounthly, yearly,..)
}
