//@ts-nocheck
import React, { useEffect, useCallback } from "react";
//@ts-ignore
import * as S from "./style";
import { Checkbox } from "./../../Checkbox";
import { ISelectMenuOption } from "./../interfaces";

export interface IMultiSelectMenu {
  /**The options to use in the menu. */
  options?: ISelectMenuOption[];
  /**The set of values of le list of selected items (when multi). */
  selected?: ISelectMenuOption[];
  /**If true, we will use checkbox.
   * If not set, will be true by default when multi-selection is active. Otherwise false. */
  useCheckbox?: boolean;
  // onSelectedChanged?: (lst: ISelectMenuOption[]) => void;
  /**If true, the selection will have always the last selected. */
  multi?: boolean;
  /**Fired when a different set of items is selected. It returns the values of selected items. */
  onOptionClick?: (
    selectedValue: ISelectMenuOption,
    selectedIndex: number
  ) => void;
  /**If true, the menu is opened. */
  isOpen?: boolean;
  /** */
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  preSelectedIndex?: number;
}

export const MultiSelectMenu = React.forwardRef<
  HTMLElement,
  IMultiSelectMenu & { getWrapperBoundingRect: () => any }
>(
  (
    {
      options = [],
      multi = false,
      useCheckbox = multi,
      selected = [],
      onOptionClick,
      onKeyDown,
      isOpen = false,
      getWrapperBoundingRect,
      preSelectedIndex,
    },
    ref
  ) => {
    const initOptionsRefs = useCallback(() => {
      const refs = options.reduce((acc, opt, i) => {
        acc[i] = React.createRef();
        return acc;
      }, {});
      return refs;
    }, [options?.length]);
    const optionsRefs = initOptionsRefs();

    const scrollIfNeeded = (itemRef) => {
      const itemRec = itemRef?.current?.getBoundingClientRect();
      const wrapperRec = getWrapperBoundingRect(); //which give the wrapper height is not the list height, but the wrapper height.
      if (itemRec && wrapperRec) {
        if (itemRec.bottom > wrapperRec.bottom) {
          itemRef.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            // behavior: "smooth",
          });
        } else if (itemRec.top < wrapperRec.top) {
          itemRef.current.scrollIntoView({
            block: "start",
            inline: "nearest",
            // behavior: "smooth",
          });
        }
      }
    };

    //Scroll to preSelectedIndex when it change
    useEffect(() => {
      if (isOpen && options?.length) {
        const rf = optionsRefs[preSelectedIndex];
        if (rf) {
          scrollIfNeeded(rf);
        }
      }
    }, [isOpen, preSelectedIndex]);

    function LabelComponent({ opt }: { opt: ISelectMenuOption }) {
      return (
        opt.customDisplay ?? (
          <S.LabelContainer>
            {opt.imgSrc ? (
              <S.ImgAsIcon src={opt.imgSrc} alt={opt.label} />
            ) : (
              opt.icon
            )}
            <div>
              <S.MainLabel>{opt.label}</S.MainLabel>
              {opt.subLabel && <S.SubLabel>{opt.subLabel}</S.SubLabel>}
            </div>
          </S.LabelContainer>
        )
      );
    }

    const handleOptionClick = (e, opt, index) => {
      if (opt.disabled) return;
      onOptionClick(opt, index);
    };

    return (
      <S.MenuContainer ref={ref} onKeyDown={onKeyDown} tabIndex="-1">
        {options.map((opt, index) => {
          const _isChecked =
            selected && selected.find((x) => x.value === opt.value);
          return (
            <S.Option
              key={index}
              ref={optionsRefs[index]}
              $isSelected={_isChecked}
              $useCheckbox={useCheckbox}
              disabled={opt.disabled}
              $preSelected={index === preSelectedIndex}
              onClick={(event) => handleOptionClick(event, opt, index)}
              $withImg={opt.imgSrc || opt.icon}
              $customDisplay={opt.customDisplay}
            >
              {useCheckbox ? (
                <Checkbox
                  isChecked={_isChecked}
                  label={<LabelComponent opt={opt} />}
                  disabled={opt.disabled}
                  size={"xxs"}
                />
              ) : (
                <LabelComponent opt={opt} />
              )}
            </S.Option>
          );
        })}
      </S.MenuContainer>
    );
  }
);
