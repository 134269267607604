import styled, { css } from "styled-components";

const noselect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
export const TimeSelectorContainer = styled.div`
  ${noselect}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    height: 34px;
    display: flex;
    align-items: center;
  }
`;
