//@ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Icon } from "./../Icon";
import { ISelectMenuOption } from "./interfaces";
//@ts-ignore
import * as S from "./selectInput.style";
//@ts-ignore
import * as B from "./selectButton.style";

import {
  WithHelperMessage,
  InputContainer,
  WithLabel,
} from "./../utils/inputCommon";
import {
  ICommonInput,
  IWithHelperMessage,
  IInputContainer,
  IWithLabel,
} from "./../utils/inputCommon/interfaces";

interface ISelectInput
  extends IWithHelperMessage,
    IWithLabel,
    IInputContainer,
    ICommonInput {
  multi: boolean;
  selectedButtonStyled?: boolean;
  scrollable?: boolean;
  menuIsOpen?: boolean;
  selected?: ISelectMenuOption[];
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onUnselect?: (selected: string[]) => void;
}

type RefType = HTMLInputElement; // | HTMLDivElement;
export const SelectInput = React.forwardRef<RefType, ISelectInput>(
  (
    {
      multi,
      selectedButtonStyled = false,
      scrollable = false,
      selected,
      menuIsOpen,
      onClick,
      onKeyDown,
      onUnselect,
      ...rest
    },
    ref
  ) => {
    const [scrollableLeft, setScrollableLeft] = useState(false);
    const [scrollableRight, setScrollableRight] = useState(false);
    const buttonsContainerRef = useRef(null);

    const handleScroll = useCallback(() => {
      const el = buttonsContainerRef?.current;
      setScrollableLeft(el?.scrollLeft > 0);
      setScrollableRight(el?.scrollWidth > el?.scrollLeft + el?.clientWidth);
    }, []);

    const onMousedown = (e) => {
      //Otherwise this will show an active input when the input is not in autosugestion mode
      e.stopPropagation();
      e.preventDefault();
    };

    useEffect(() => {
      handleScroll();
    }, [handleScroll, selected]);

    // rest.rightSideNode = (
    //   <div style={{ cursor: "pointer", padding: "8px 16px 8px 8px" }}>
    //     <B.ChevronIcon $size={rest.size} $menuOpen={menuIsOpen}>
    //       <Icon name="InterfaceArrowsButtonDown" />
    //     </B.ChevronIcon>
    //   </div>
    // );

    function SelectedOptions() {
      const handleRemove = (e, v: string) => {
        e.preventDefault();
        e.stopPropagation();
        onUnselect(v);
      };

      return !selected?.length ? null : selectedButtonStyled ? (
        <S.SelectedAsButtons
          onScroll={handleScroll}
          ref={buttonsContainerRef}
          scrollable={scrollable}
          scrollableLeft={scrollableLeft}
          scrollableRight={scrollableRight}
        >
          {selected.map((x, i) => (
            <S.SelectedBtn key={i} $withImg={x.imgSrc || x.icon}>
              {x.imgSrc && <S.ImgAsIcon alt={x.label} src={x.imgSrc} />}
              <S.SelectedBtnLabel>{x.label}</S.SelectedBtnLabel>
              <S.SelectedBtnClose onClick={(e) => handleRemove(e, x.value)}>
                <Icon name="InterfaceAdd45" />
              </S.SelectedBtnClose>
            </S.SelectedBtn>
          ))}
        </S.SelectedAsButtons>
      ) : (
        <S.SelectedLabels>
          {selected.map((x) => x.label).join(", ")}
        </S.SelectedLabels>
      );
    }

    rest.rightSideNode = (
      <div style={{ cursor: "pointer", padding: "8px" }}>
        <B.ChevronIcon $size={rest.size} $menuOpen={menuIsOpen}>
          <Icon name="InterfaceArrowsButtonDown" />
        </B.ChevronIcon>
      </div>
    );

    return (
      <S.SelectInputContainer
        ref={ref}
        tabIndex={-1}
        onMouseDown={onMousedown}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <WithHelperMessage>
          <InputContainer {...rest} noMaxHeight={selectedButtonStyled}>
            <WithLabel
              {...rest}
              empty={!selected?.length} //just to show placeholder here - not meant to be edited
              hasPlaceholder={rest.placeholder?.length > 0}
            >
              <SelectedOptions />

              {!selected?.length && (
                <input
                  type="text"
                  placeholder={
                    !!rest.placeholder?.length ? rest.placeholder : rest.label
                  } //if no placeholder take the label if defined
                  disabled={rest.disabled}
                />
              )}
            </WithLabel>
          </InputContainer>
        </WithHelperMessage>
      </S.SelectInputContainer>
    );
  }
);
