import React, { useState, useEffect, lazy, Suspense } from "react";
// import {
//   // BarChart,
//   // Bar,
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
import {
  format,
  startOfWeek,
  startOfMonth,
  startOfYear,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachDayOfInterval,
  eachYearOfInterval,
} from "date-fns";
import { LabelButton } from "./../../../components/Button";
import { Toggle } from "./../../../components/Toggle";
import { ChartLineProps, FrameDotProps } from "./../../../types";

import styles from "./styles.module.scss";
import globalStyles from "../style.module.scss";
import { useMobileSizeCheck } from "./../../../components/utils/useMediaSizeCheck";

type TimeFrame = "daily" | "weekly" | "monthly" | "yearly";
// type ChartType = "Bar" | "Line";

const LazyLineChart = lazy(async () => {
  const {
    // BarChart,
    // Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } = await import("recharts");

  const LineChartComponent = ({
    chartLines,
  }: {
    chartLines: ChartLineProps[];
  }) => (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={chartLines[0].dots}>
        {/* Assuming all lines have the same x-axis data */}
        <CartesianGrid strokeDasharray="3 3" />
        {chartLines.map((x, index) => (
          <XAxis key={index} dataKey="date" xAxisId={index} hide={index > 0} />
        ))}
        <XAxis dataKey="date" xAxisId={0} />
        <YAxis />
        <Tooltip />
        <Legend />
        {chartLines.map((x, index) => (
          <Line
            key={index}
            xAxisId={index}
            type="monotone"
            dataKey="count"
            // dataKey={x.name}
            data={x.dots}
            stroke={x.color}
            name={x.name}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );

  return { default: LineChartComponent };
});

export const TrendsCharts: React.FC<{
  charts: ChartLineProps[];
  clearGraphs: () => void;
}> = ({ charts, clearGraphs }) => {
  const [timeFrame, setTimeFrame] = useState<TimeFrame>("weekly"); // Define the state type
  const [chartLines, setChartLines] = useState<ChartLineProps[]>([]); // Data for the chart
  const isMobile = useMobileSizeCheck();

  // Update data whenever lines on data or timeFrame changes
  useEffect(() => {
    if (!charts?.length) setChartLines([]);
    //Else
    const withFrameDots: ChartLineProps[] = charts.map((x) => ({
      ...x,
      dots: groupItemsByTimeFrame(x.dates, timeFrame),
    }));
    setChartLines(withFrameDots);
  }, [charts, timeFrame]);

  // Generate all time periods (days, weeks, months, or years) between min and max dates
  const generateAllTimePeriods = (
    minDate: Date,
    maxDate: Date,
    timeFrame: TimeFrame
  ) => {
    switch (timeFrame) {
      case "daily":
        return eachDayOfInterval({ start: minDate, end: maxDate });
      case "weekly":
        return eachWeekOfInterval({ start: minDate, end: maxDate });
      case "monthly":
        return eachMonthOfInterval({ start: minDate, end: maxDate });
      case "yearly":
        return eachYearOfInterval({ start: minDate, end: maxDate });
      default:
        return [];
    }
  };

  // Group and fill missing time periods
  const groupItemsByTimeFrame = (
    dates: Date[],
    timeFrame: TimeFrame
  ): FrameDotProps[] => {
    if (dates.length === 0) return [];

    const minDate = new Date(Math.min(...dates.map((d) => d.getTime())));
    const maxDate = new Date(Math.max(...dates.map((d) => d.getTime())));

    const allTimePeriods = generateAllTimePeriods(minDate, maxDate, timeFrame);

    const groupedData: Record<string, number> = {};

    dates.forEach((date) => {
      let key: string;

      // Determine the key based on the selected time frame
      switch (timeFrame) {
        case "daily":
          key = format(date, "yyyy-MM-dd");
          break;
        case "weekly":
          key = format(startOfWeek(date), "yyyy-MM-dd");
          break;
        case "monthly":
          key = format(startOfMonth(date), "yyyy-MM");
          break;
        case "yearly":
          key = format(startOfYear(date), "yyyy");
          break;
        default:
          key = format(date, "yyyy-MM-dd");
      }

      if (!groupedData[key]) {
        groupedData[key] = 0;
      }
      groupedData[key]++;
    });

    // Fill in missing time periods with count 0
    return allTimePeriods.map((timePeriod) => {
      let key: string;

      switch (timeFrame) {
        case "daily":
          key = format(timePeriod, "yyyy-MM-dd");
          break;
        case "weekly":
          key = format(startOfWeek(timePeriod), "yyyy-MM-dd");
          break;
        case "monthly":
          key = format(startOfMonth(timePeriod), "yyyy-MM");
          break;
        case "yearly":
          key = format(startOfYear(timePeriod), "yyyy");
          break;
        default:
          key = format(timePeriod, "yyyy-MM-dd");
      }

      return {
        date: key,
        count: groupedData[key] || 0, // Use 0 if no data for the time period
      };
    });
  };

  return (
    <div className={styles.trendsChartsContainer}>
      <div className={styles.dataContainer}>
        <div className={globalStyles.sectionTopBar}>
          <div style={{ minWidth: "140px" }}>Trend Charts</div>
          <div className={styles.sectionActions}>
            <Toggle
              size={isMobile ? "s" : "m"}
              onChange={(v) => setTimeFrame(v as TimeFrame)}
              options={[
                {
                  value: "daily",
                  label: "Daily",
                },
                {
                  value: "weekly",
                  label: "Weekly",
                },
                {
                  value: "monthly",
                  label: "Monthly",
                },
                {
                  value: "yearly",
                  label: "Yearly",
                },
              ]}
              value={timeFrame}
            />
            <LabelButton
              label="Clear"
              onClick={clearGraphs}
              variant="tertiary"
              size={isMobile ? "s" : "m"}
              corners="squared"
            />
          </div>
        </div>
        <div className={styles.dataBody}>
          <Suspense fallback={<div>Loading chart...</div>}>
            {!!chartLines.length && <LazyLineChart chartLines={chartLines} />}
          </Suspense>
        </div>
      </div>
    </div>
  );
};
