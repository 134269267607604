import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { SideSheetModal } from "./../components/SideSheet";
import { Spinner } from "../components/Spinner";
import { CheersLayout } from "./../components/CheersLayout";
import { getSentBy, getRecievedBy, CheerShortProps } from "./../api/cheers";
import { CheerCard, CheerDetails } from "./../components/CheersCard";
import { AuthContext } from "../auth/AuthProvider";
import { CheersHelpButton } from "./../components/CheersHelpButton";
import styles from "./style.module.scss";

export default function Home() {
  const [activeTab, setActiveTab] = useState<"received" | "sent">("received");
  const [cheerId2show, setCheerId2show] = useState<string>();
  const [receivedLoadedPages, setReceivedLoadedPages] = useState<number>(0);
  const [receivedTotalPages, setReceivedTotalPages] = useState<number>(0);
  const [receivedTotal, setReceivedTotal] = useState<number>(0);

  const [sentLoadedPages, setSentLoadedPages] = useState<number>(0);
  const [sentTotalPages, setSentTotalPages] = useState<number>(0);
  const [sentTotal, setSentTotal] = useState<number>(0);
  const [cheersReceivedShort, setCheersReceivedShort] = useState<
    CheerShortProps[]
  >([]);
  const [cheersSentShort, setCheersSentShort] = useState<CheerShortProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const homeWrapRef = useRef<HTMLDivElement>(null);
  const me = useContext(AuthContext);
  const primoId = me!.strapiId;

  const reset = () => {
    setCheerId2show(undefined);
    setReceivedLoadedPages(0);
    setReceivedTotalPages(0);
    setReceivedTotal(0);
    //
    setSentLoadedPages(0);
    setSentTotalPages(0);
    setSentTotal(0);
    //
    setCheersReceivedShort([]);
    setCheersSentShort([]);
    setIsLoading(false);
  };
  const loadOnePageOfSentCheers = useCallback(async () => {
    if (!sentTotalPages || sentLoadedPages < sentTotalPages) {
      try {
        setIsLoading(true);
        const { data, meta } = await getSentBy({
          primoId,
          page: sentLoadedPages + 1,
        });
        setSentTotalPages(meta.pageCount);
        setSentTotal(meta.total);
        if (data?.length > 0) {
          setCheersSentShort((prev) => [...prev, ...data]);
          setSentLoadedPages((prev) => prev + 1);
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  }, [primoId, sentLoadedPages, sentTotalPages]);

  const loadOnePageOfReceivedCheers = useCallback(async () => {
    if (!receivedTotalPages || receivedLoadedPages < receivedTotalPages) {
      try {
        setIsLoading(true);
        const { data, meta } = await getRecievedBy({
          primoId,
          page: receivedLoadedPages,
        });
        setReceivedTotalPages(meta.pageCount);
        setReceivedTotal(meta.total);
        if (data?.length > 0) {
          setCheersReceivedShort([...cheersReceivedShort, ...data]);
          setReceivedLoadedPages(receivedLoadedPages + 1);
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  }, [cheersReceivedShort, primoId, receivedLoadedPages, receivedTotalPages]);

  useEffect(() => {
    const homeWrap = homeWrapRef.current;
    const handleScroll = () => {
      //When the user scrolled dowun and reached the end
      const tolerance = 1;
      if (
        homeWrap &&
        Math.abs(
          homeWrap.scrollHeight - homeWrap.scrollTop - homeWrap.clientHeight
        ) <= tolerance
      ) {
        if (isLoading) return;
        //else
        activeTab === "received"
          ? loadOnePageOfReceivedCheers()
          : loadOnePageOfSentCheers();
      }
    };

    !!homeWrap && homeWrap.addEventListener("scroll", handleScroll);
    return () => {
      !!homeWrap && homeWrap.removeEventListener("scroll", handleScroll);
    };
  }, [
    activeTab,
    isLoading,
    loadOnePageOfReceivedCheers,
    loadOnePageOfSentCheers,
  ]);

  useEffect(() => {
    reset();
    if (activeTab === "sent") loadOnePageOfSentCheers();
    if (activeTab === "received") loadOnePageOfReceivedCheers();
  }, [activeTab]);

  return (
    <CheersLayout>
      <div ref={homeWrapRef} className={styles.pageWrap}>
        <div className={styles.pageHeader}>
          <img src="/img/header3.png" alt="Header" />
        </div>
        <CheersHelpButton />
        <div className={styles.homeTitleWrap}>
          <h1 className={styles.title}>My Cheers</h1>
          <div className={styles.titleDesc}>
            A little Thank You goes a long way
          </div>
        </div>
        <div className={styles.homeContent}>
          <div className={styles.tabulation}>
            <div
              className={`${styles.tabItem} ${
                activeTab === "received" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("received")}
            >
              Cheers Received
            </div>
            <div
              className={`${styles.tabItem} ${
                activeTab === "sent" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("sent")}
            >
              Cheers Sent
            </div>
          </div>

          {activeTab === "received" && (
            <div className={styles.cheersListWrapper}>
              {!!receivedTotal && (
                <span className={styles.feedsNumber}>{receivedTotal}</span>
              )}
              {cheersReceivedShort.map((x, i) => (
                <CheerCard
                  key={i}
                  receivedByMe={true}
                  {...x}
                  onClick={() => setCheerId2show(x.id)}
                />
              ))}
              {isLoading && <Spinner size="xl" variant="primary" />}
              {!isLoading && !cheersReceivedShort?.length && (
                <div className={styles.noneReceived}>
                  <img src="/img/sad.png" alt="sad" />
                  <div>
                    You haven't received any Cheers!
                    <br />
                    message yet, keep being yourself :)
                  </div>
                </div>
              )}
            </div>
          )}
          {activeTab === "sent" && (
            <div className={styles.cheersListWrapper}>
              {!!sentTotal && (
                <span className={styles.feedsNumber}>{sentTotal}</span>
              )}

              {cheersSentShort.map((x, i) => (
                <CheerCard
                  key={i}
                  sentByMe={true}
                  {...x}
                  onClick={() => setCheerId2show(x.id)}
                />
              ))}
              {isLoading && <Spinner size="xl" variant="primary" />}
              {!isLoading && !cheersSentShort?.length && (
                <div className={styles.noneReceived}>
                  <img src="/img/sad.png" alt="sad" />

                  <div>
                    You haven't sent any Cheers!
                    <br />
                    message yet.
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <SideSheetModal
        scrollableContent={false}
        open={!!cheerId2show}
        closeMe={() => setCheerId2show(undefined)}
        fullscreen
        position="right"
        closeOnSwip={false}
      >
        {cheerId2show && (
          <CheerDetails
            cheerId={cheerId2show}
            closeMe={() => setCheerId2show(undefined)}
          />
        )}
      </SideSheetModal>
    </CheersLayout>
  );
}
