//@ts-nocheck
import React from "react";
import * as S from "./styles";
import { range } from "./../utile";

//////

const itemHeight = 34;
export interface CircularNumberProps {
  /** The max number of the array */
  max: number;
  /** The min number of the array */
  min?: number;
  /** The step between two consecutive numbers  */
  step?: number;
  /** The number of item to display at the same time */
  displayed?: number;
  /** The choosen item will automatically be at this position. If zero, then will be on the top */
  chosenPosition?: number;
  /** The choosen value */
  value?: number;
  /** The new changed / choosen number */
  onChange?: (number) => void;
}
function IntegerDigits({
  onChange,
  value,
  range,
}: {
  onChange: (number) => void;
  value: number;
  range: number[];
}) {
  const handleChange = (v) => {
    onChange(v);
  };
  return (
    <>
      {range.map((x, i) => (
        <S.ItemRow
          key={i}
          $itemHeight={itemHeight}
          onClick={() => handleChange(x)}
          active={x === value}
        >
          {x.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </S.ItemRow>
      ))}
    </>
  );
}

//////

interface InfiniteScrollLoopProps {
  surroundingBackup?: number;
  scrollPaddingPercentage?: number;
  displayed: number;
  value: number;
  step: number;
  children: React.ReactNode;
}
function InfiniteScrollLoop({
  surroundingBackup = 2,
  displayed,
  value,
  step,
  children,
}: InfiniteScrollLoopProps): JSX.Element {
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const scrollTimerRef = React.useRef(null);
  const [blockHeight, setBlockHeight] = React.useState<number>(0);

  const backupHeight = blockHeight * surroundingBackup;

  const handleScroll = React.useCallback(() => {
    if (scrollRef.current) {
      //One element should be on the top, scrolling step by step
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current);
      }
      scrollTimerRef.current = setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop =
            Math.round(scrollRef.current.scrollTop / itemHeight) * itemHeight;
        }
        clearTimeout(scrollTimerRef.current);
      }, 100);
      ///

      const scroll = scrollRef.current.scrollTop;

      if (scroll < backupHeight || scroll >= backupHeight + blockHeight) {
        scrollRef.current.scrollTop = backupHeight + (scroll % blockHeight);
      }
    }
  }, [blockHeight]);

  React.useLayoutEffect(() => {
    if (contentRef.current) {
      setBlockHeight(contentRef.current.offsetHeight);
    }
  });
  React.useEffect(() => {
    if (contentRef.current) {
      const displayMiddleTransition = ~~(displayed / 2);
      const _scroll =
        (value / step) * itemHeight +
        contentRef.current.offsetHeight - //backupHeight -
        displayMiddleTransition * itemHeight;
      scrollRef.current.scrollTop = _scroll;
    }
  }, [value]);

  return (
    <S.InfiniteScrollLoopInner
      ref={scrollRef}
      $displayed={displayed}
      $itemHeight={itemHeight}
      onScroll={handleScroll}
    >
      {Array(surroundingBackup)
        .fill(0)
        .map((x, i) => (
          <div key={i}>{children}</div>
        ))}
      <div ref={contentRef}>{children}</div>
      {Array(surroundingBackup)
        .fill(0)
        .map((x, i) => (
          <div key={i}>{children}</div>
        ))}
    </S.InfiniteScrollLoopInner>
  );
}

//////

/** The range should be multiple of step.
 * Otherwise we will use the first number inferior to step
 * where range is multiple of that new step
 */
function correctStep(range, step) {
  let i = step;
  while (i > 1) {
    if (range % i === 0) break;
    i--;
  }
  return i;
}

export const CircularNumbers = ({
  max,
  min = 0,
  step = 1,
  displayed = 1,
  value = min,
  onChange = (v) => {},
}: CircularNumberProps) => {
  const _step = correctStep(max - min + 1, step);
  const _range = range(min, max, _step);

  function decrement() {
    const _max = max - min + 1 - step; //the max even if step != 1
    const val = value === min ? _max : value - _step;
    onChange(val);
  }
  function increment() {
    const _max = max - min + 1 - step; //the max even if step != 1
    const val = value === _max ? min : value + _step;
    onChange(val);
  }
  return (
    <S.CircularNumberContainer>
      <S.ArrowIconPrev onClick={decrement} />
      <InfiniteScrollLoop displayed={displayed} step={_step} value={value}>
        <IntegerDigits range={_range} onChange={onChange} value={value} />
      </InfiniteScrollLoop>
      <S.ArrowIconNext onClick={increment} />
    </S.CircularNumberContainer>
  );
};
