import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgOutlineTimeClockCircleAlternate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10 .8c5.1 0 9.2 4.1 9.2 9.2s-4.1 9.2-9.2 9.2S.8 15.1.8 10 4.9.8 10 .8m0 1.6c-4.2 0-7.6 3.4-7.6 7.6s3.4 7.6 7.6 7.6 7.6-3.4 7.6-7.6-3.4-7.6-7.6-7.6M9.6 5c.4 0 .8.3.8.8v3.9l3.5 3.2c.3.3.3.8 0 1.1s-.8.3-1.1 0L9 10.5c-.2-.1-.2-.3-.2-.6V5.8c.1-.4.4-.8.8-.8" />
  </svg>
);
const Memo = memo(SvgOutlineTimeClockCircleAlternate);
export default Memo;
