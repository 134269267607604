import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgBoldArrowsNavigateRightThin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 25 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m23.6 11.3-6.7-7.5c-.4-.5-1.1-.5-1.5 0s-.4 1.2 0 1.7l4.9 5.5h-18c-.6 0-1 .5-1 1.2 0 .6.5 1.2 1 1.2h18l-4.9 5.5c-.4.5-.4 1.2 0 1.7.2.2.5.3.7.3.3 0 .5-.1.7-.3l6.7-7.5c.5-.6.5-1.3.1-1.8" />
  </svg>
);
const Memo = memo(SvgBoldArrowsNavigateRightThin);
export default Memo;
