import React from "react";

import {
  min_width_on_tablet,
  min_width_on_laptop,
  min_width_on_large_desktop,
  min_width_on_very_large_desktop,
  min_width_on_largest_desktop,
} from "./mediaqueries";

export const useMobileSizeCheck = () => {
  const [narrowMedia, setNarrowMedia] = React.useState(
    window.innerWidth < min_width_on_tablet
  );
  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setNarrowMedia(newWidth < min_width_on_tablet);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  return narrowMedia;
};

export const useDeviceSizeCheck = () => {
  const [desktop, setDesktop] = React.useState(
    window.innerWidth >= min_width_on_large_desktop
  );
  const [tablet, setTablet] = React.useState(
    window.innerWidth >= min_width_on_tablet && window.innerWidth < min_width_on_large_desktop
  );
  const [mobile, setMobile] = React.useState(
    window.innerWidth < min_width_on_tablet
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      setDesktop(window.innerWidth >= min_width_on_large_desktop);
      setTablet(window.innerWidth >= min_width_on_tablet && window.innerWidth < min_width_on_large_desktop);
      setMobile(window.innerWidth < min_width_on_tablet);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  return {
    mobile,
    tablet,
    desktop
  };
};

export const useMediaCheck = () => {
  const [largestAndMore, setLargest] = React.useState(
    window.innerWidth >= min_width_on_largest_desktop
  );
  const [veryLargeAndMore, setVeryLarge] = React.useState(
    window.innerWidth >= min_width_on_very_large_desktop
  );
  const [largeDesktopAndMore, setLargeDesktop] = React.useState(
    window.innerWidth >= min_width_on_large_desktop
  );
  const [laptopAndMore, setLaptop] = React.useState(
    window.innerWidth >= min_width_on_laptop
  );
  const [tabletAndMore, setTablet] = React.useState(
    window.innerWidth >= min_width_on_tablet
  );
  const [mobile, setMobile] = React.useState(
    window.innerWidth < min_width_on_tablet
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      setLargest(window.innerWidth >= min_width_on_largest_desktop);
      setVeryLarge(window.innerWidth >= min_width_on_very_large_desktop);
      setLargeDesktop(window.innerWidth >= min_width_on_large_desktop);
      setLaptop(window.innerWidth >= min_width_on_laptop);
      setTablet(window.innerWidth >= min_width_on_tablet);
      setMobile(window.innerWidth < min_width_on_tablet);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  return {
    mobile,
    tabletAndMore,
    laptopAndMore,
    largeDesktopAndMore,
    veryLargeAndMore,
    largestAndMore,
  };
};
