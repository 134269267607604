import chroma from "chroma-js";
export type ColorVariant = 500 | 650 | 600 | 400 | 200 | 100 | "opacity8";

export const colorVariants = (
  baseColor: string,
  colorVariant?: ColorVariant
): string => {
  switch (colorVariant) {
    case 650:
      return chroma(baseColor).darken(2).hex(); // Beaucoup plus sombre

    case 600:
      return chroma(baseColor).darken(1).hex(); // Un peu plus sombre

    case 400:
      return chroma(baseColor).brighten(1).hex(); // Un peu plus clair

    case 200:
      return chroma(baseColor).brighten(2).hex(); // Plus clair

    case 100:
      return chroma(baseColor).brighten(3).hex(); // Encore plus clair

    case "opacity8":
      return chroma(baseColor).alpha(0.08).hex("rgba"); // Opacité 8%

    case 500:
    default:
      return chroma(baseColor).hex(); // Couleur principale
  }
};

export const transparent = "transparent";

//brand
export const brand = "#e81253";
export const brand_650 = "#a10c3a"; //darken
export const brand_600 = "#b90e42"; //darken
export const brand_400 = "#f03d73"; //lighten
export const brand_200 = "#f46c95"; //lighten
export const brand_100 = "#fde3eb"; //lighten
export const brand_opacity10 = "#e812531A";

//semantics
export const success = "#42C778";
export const danger = "#E81253";
export const warning = "#FFB025";
export const information = "#1747A1";

//neutral
export const ashes = "#848484";
export const mercury = "#545454";
export const silver = "#f2f2f2";
export const smoke = "#d0d0d0";
export const snow = "#f7f7f7";

//primary : white
export const white = "#FFFFFF";
export const white_opacity8 = "#FFFFFF14";
export const white_opacity24 = "#FFFFFF3D";
export const white_opacity80 = "#FFFFFFCC";

//primary : carbon
export const carbon = "#121212";
export const carbon_opacity80 = "#FFFFFFCC";

//primary : caribe
export const caribe = "#17a0a1";
export const caribe_650 = "#0d5d5e"; //darken
export const caribe_600 = "#117474"; //darken
export const caribe_400 = "#1dccce"; //lighten
export const caribe_200 = "#3be2e3"; //lighten
export const caribe_100 = "#abf2f3"; //lighten
export const caribe_opacity8 = "#17A0A114";

//primary : interaction
export const interaction = "#17a0a1";
export const interaction_650 = "#0d5d5e"; //darken
export const interaction_600 = "#117474"; //darken
export const interaction_400 = "#1dccce"; //lighten
export const interaction_200 = "#3be2e3"; //lighten
export const interaction_100 = "#abf2f3"; //lighten
export const interaction_opacity8 = "#17A0A114";

//primary : interaction Red Color for cheers project
export const red_interaction = "#e12c16"; // Couleur principale
export const red_interaction_650 = "#8b1d10"; // Assombri
export const red_interaction_600 = "#b72312"; // Assombri légèrement
export const red_interaction_400 = "#ff523b"; // Éclairci
export const red_interaction_200 = "#ff8673"; // Plus éclairci
export const red_interaction_100 = "#ffc4bb"; // Encore plus éclairci
export const red_interaction_opacity8 = "#e12c1614"; // Opacité 8%

//secondary : bubbaloo
export const bubbaloo = "#dc547d";
export const bubbaloo_650 = "#bd2754"; //darken
export const bubbaloo_600 = "#d22b5d"; //darken
export const bubbaloo_400 = "#e57e9d"; //lighten
export const bubbaloo_200 = "#eda9bd"; //lighten
export const bubbaloo_100 = "#fae8ee"; //lighten

//secondary : daylight
export const daylight = "#3ca0ed";
export const daylight_650 = "#137aca"; //darken
export const daylight_600 = "#1588e1"; //darken
export const daylight_400 = "#6bb7f1"; //lighten
export const daylight_200 = "#99cdf6"; //lighten
export const daylight_100 = "#f7fbfe"; //lighten

//secondary : grape
export const grape = "#1747a1";
export const grape_650 = "#0d295e"; //darken
export const grape_600 = "#113374"; //darken
export const grape_400 = "#1d5bce"; //lighten
export const grape_200 = "#3b75e3"; //lighten
export const grape_100 = "#abc4f3"; //lighten

//secondary : mint
export const mint = "#4be4c4";
export const mint_650 = "#1ec5a2"; //darken
export const mint_600 = "#21dbb4"; //darken
export const mint_400 = "#77ebd3"; //lighten
export const mint_200 = "#a4f1e1"; //lighten
export const mint_100 = "#e6fbf7"; //lighten

//secondary : nightsky
export const nightsky = "#001564";
export const nightsky_650 = "#000518"; //darken
export const nightsky_600 = "#000a31"; //darken
export const nightsky_400 = "#002097"; //lighten
export const nightsky_200 = "#002aca"; //lighten
export const nightsky_100 = "#4a70ff"; //lighten

//secondary : yolk
export const yolk = "#ffb025";
export const yolk_650 = "#d78900"; //darken
export const yolk_600 = "#f19a00"; //darken
export const yolk_400 = "#ffc258"; //lighten
export const yolk_200 = "#ffd58b"; //lighten
export const yolk_100 = "#fffaf1"; //lighten

//secondary : peach
export const peach = "#fb7b25";
export const peach_650 = "#d05604"; //darken
export const peach_600 = "#e96004"; //darken
export const peach_400 = "#fc9957"; //lighten
export const peach_200 = "#fdb889"; //lighten
export const peach_100 = "#fff4ed"; //lighten

//secondary : pomelo
export const pomelo = "#f34040";
export const pomelo_650 = "#d90e0e"; //darken
export const pomelo_600 = "#f01010"; //darken
export const pomelo_400 = "#f67070"; //lighten
export const pomelo_200 = "#f9a0a0"; //lighten
export const pomelo_100 = "#fee8e8"; //lighten

//modalBackground
export const modal_background_dark = "rgba(0, 0, 0, 0.72)";
export const modal_background_light = "rgba(255; 255, 255, 0.9)";
