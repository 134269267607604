export const max_width_on_mobile = 479; //px
export const min_width_on_tablet = 480; //px
export const min_width_on_laptop = 769; //px
export const min_width_on_large_desktop = 1025; //px
export const min_width_on_very_large_desktop = 1440; //px
export const min_width_on_largest_desktop = 1920; //px

export const isMobile = "@media (max-width: 479px)";
export const isTablet =
  "@media (min-width: 480px)" || "@media only screen and (min-width: 480px)";
export const isLaptop =
  "@media (min-width: 769px)" || "@media only screen and (min-width: 769px)";
export const isLargeDesktop =
  "@media (min-width: 1025px)" || "@media only screen and (min-width: 1025px)";
export const isVeryLargeDesktop =
  "@media (min-width: 1440px)" || "@media only screen and (min-width: 1440px)";
export const isLargestDesktop =
  "@media (min-width: 1920px)" || "@media only screen and (min-width: 1920px)";

export const isNotMobile = isTablet;
