import React from "react";
// @ts-ignore
import * as S from "./button.style";

export interface IButton {
  /** the caption. See also the children field. */
  label?: string;
  /** The caption. This will override the label field if defined and not empty. */
  children?: React.ReactNode;
  /** A react object, often representing an icon that would be inserted on the left side of the label */
  icon?: React.ReactNode;
  /** Accesibilty text */
  alt?: string;
  /** If true, button will be disabled */
  disabled?: boolean;
  /** If true, the screen width occupancy will be 100%. */
  auto?: boolean;
  /** the type of the button. */
  type?: "button" | "submit" | "reset";
  /** If true, it will be loading */
  loading?: boolean;
  /** The size of the component */
  size?: "s" | "m" | "l" | "xl";
  /** If true, it will display success icon on label */
  success?: boolean;
  /** The is three types/variants */
  variant?: "primary" | "secondary" | "tertiary";
  /** The type of the corners */
  corners?: "rounded" | "squared";
  /** Fired when the button is clicked */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /** fire on keydown */
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  /** If defined, the button will be implimented as a link (anchor) */
  href?: string;
  /** When the button is implemented as link (href not null) this anchor property is important */
  target?: "_self" | "_blank" | "_parent" | "_top";
  /** If true, the button will have no border */
  noBorder?: boolean;
  /**if true the background will be transparent */
  transparent?: boolean;
  /** If given will used as primary color of the button */
  color?: string;
}

type RefType = HTMLButtonElement | HTMLAnchorElement;
export const Button = React.forwardRef<RefType, IButton>(
  (
    {
      children = null,
      label = null,
      icon = null,
      alt = "button",
      disabled = false,
      auto = false,
      type = "button",
      loading = false,
      size = "m",
      success = false,
      variant = "primary",
      corners = label || children ? "rounded" : "squared",
      onClick = undefined,
      onKeyDown = undefined,
      href = undefined,
      target = "_self",
      noBorder = false,
      transparent = false,
      color = undefined,
    },
    ref
  ) => {
    const isPrimarySuccess = success && variant === "primary";
    const isSecondarySuccess = success && variant === "secondary";

    const createRipple = (e: any) => {
      const button = e.currentTarget;
      const circle = document.createElement("span");
      const diameter = Math.max(button.clientWidth, button.clientHeight) / 4;
      const radius = diameter / 2;
      /* eslint-disable */
      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${e.clientX - button.offsetLeft - radius}px`;
      circle.style.top = `${e.clientY - button.offsetTop - radius}px`;
      circle.classList.add("ripple");
      /* eslint-enable */

      const ripple = button.getElementsByClassName("ripple")[0];

      if (ripple) {
        ripple.remove();
      }
      button.appendChild(circle);
    };

    return (
      <S.Container
        $auto={auto}
        onClick={(e) => !disabled && !!onClick && onClick(e)}
        $variant={variant}
      >
        <S.StyledLinkOrButton
          ref={ref}
          alt={alt}
          className="ripple"
          type={type}
          disabled={disabled}
          onClick={(e: any) => createRipple(e)}
          onKeyDown={onKeyDown}
          href={href}
          target={target}
          $auto={auto}
          $size={size}
          $variant={variant}
          $loading={loading}
          $corners={corners}
          $iconbutton={!(children || label?.length)}
          $noBorder={noBorder}
          $transparent={transparent}
          $color={color}
        >
          {loading && <S.Icon $variant={variant} type="bubbles" />}
          {isPrimarySuccess && (
            <S.SuccessIcon>
              <S.IconLineTip />
              <S.IconLineLong />
            </S.SuccessIcon>
          )}
          {isSecondarySuccess && (
            <S.SuccessIcon>
              <S.IconLineTip />
              <S.IconLineLong />
            </S.SuccessIcon>
          )}
          <S.CaptionWrapper
            $loading={loading}
            $size={size}
            $success={success}
            $variant={variant}
          >
            {icon && <S.IconContainer>{icon}</S.IconContainer>}
            {(children || label) && <S.Caption>{children || label}</S.Caption>}
          </S.CaptionWrapper>
        </S.StyledLinkOrButton>
      </S.Container>
    );
  }
);
