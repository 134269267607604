import React, { useState, useEffect } from "react";
import { Select } from "../../../components/Select";
import { getDepartments } from "./../../../api/primos";
import { DirectionType, ISelectMenuOption } from "../../../types";

// Cache to store the departments loaded from the server, shared across all component instances
let departmentsCache: ISelectMenuOption[] | null = null;

interface DepartmentsFilterProps {
  directionType: DirectionType;
  selected?: ISelectMenuOption[];
  onSelect?: (lst: ISelectMenuOption[]) => void;
}

export const SelectDepartments: React.FC<DepartmentsFilterProps> = ({
  directionType,
  selected = [],
  onSelect = (v) => {},
}) => {
  const [allDepartments, setAllDepartments] = useState<ISelectMenuOption[]>();

  useEffect(() => {
    // If departments are already cached, use them
    if (departmentsCache) {
      setAllDepartments(departmentsCache);
    } else {
      // Otherwise, fetch departments from the server and store them in the cache
      getDepartments(directionType).then((departments) => {
        const labeledCountries = departments.map((x: string) => ({
          label: x,
          value: x,
        }));
        departmentsCache = labeledCountries;
        setAllDepartments(labeledCountries);
      });
    }
  }, []);

  return (
    <Select
      label="Departments"
      options={allDepartments}
      size="xl"
      multi={true}
      selected={selected}
      onSelect={onSelect}
      downSheetOnMobile={false}
      autosuggest
    />
  );
};
