import React, { useState, useEffect, useRef } from "react";
import IconArrowsDown from "./../Icon/tsx/InterfaceArrowsButtonDown";
import IconPowerButton from "./../Icon/tsx/BoldBasicsOnOffPowerButton";
import { Avatar } from "./../Avatar";
import { PlacementType } from "../Drop";
import { Drop } from "../Drop";
// @ts-ignore
import * as S from "./avatarDropdown.style";

export interface IMenu {
  options: any[];
  onSelect?: (index: number) => void;
  onLogout?: (e: React.MouseEvent<HTMLElement>) => void;
  logoutLabel?: string;
}

const Menu = ({
  options,
  onSelect = () => {},
  onLogout = (e: React.MouseEvent<HTMLElement>) => {},
  logoutLabel = "Log Out",
}: IMenu) => {
  return (
    <S.Menu>
      <S.MenuOptions>
        {options.map((option, index) => (
          <S.MenuOption
            href={option.href}
            key={index}
            onClick={() => onSelect(index)}
          >
            <S.IconContainer className="menu-option-icon">
              {option.icon}
            </S.IconContainer>
            {option.label}
          </S.MenuOption>
        ))}
      </S.MenuOptions>
      <S.MenuLogout onClick={onLogout}>
        <IconPowerButton
          className="menu-option-icon"
          style={{ fontSize: "1.2em" }}
        />
        {logoutLabel}
      </S.MenuLogout>
    </S.Menu>
  );
};

interface IProfile {
  /** User or employee name */
  userName: string;
  /** Employee job title */
  userJobTitle?: string;
  /** User or employee photo url to set on avatar if contains an image */
  imageSource?: string;
  /** The menu options, as an array of links : [{ Label, icon(optional) and href }] */
  options: [{ icon?: React.ReactNode; label: string; href: string }];
  /** An object {label, onClick} where label is used for the logout button label, and onClick is the function fired on logout button click */
  logout: {
    label?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  };
}

export interface IAvatarDropdown extends IProfile {
  /** Disable or anable the dark mode */
  mode?: "dark" | "light";
  /** Favorite position of the drop */
  dropPosition?: PlacementType;
  /** If true, a description will be displayed on the right of the avatar */
  showDescription?: boolean;
}

export const AvatarDropdown = ({
  userName,
  userJobTitle,
  imageSource,
  logout = {
    label: "Log out",
    onClick: (e: React.MouseEvent<HTMLElement>) => {},
  },
  options,
  //
  mode = "light",
  dropPosition = "bottom",
  showDescription = true,
}: IAvatarDropdown) => {
  // const containerRef = useRef(null);
  const headerTitleRef = useRef<any>(null);
  const dropRef = useRef<any>(null);
  const [narrowForContent, setNarrowForContent] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (headerTitleRef?.current) {
        setNarrowForContent(
          headerTitleRef.current.scrollWidth >
            headerTitleRef.current.offsetWidth
        );
      }
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  function handleClose() {
    dropRef?.current?.close();
  }
  function toggleVisibility(e: any) {
    dropRef?.current?.toggle(e);
  }

  const handleSelect = () => {
    handleClose();
  };
  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    handleClose();
    logout?.onClick && logout?.onClick(e);
  };

  return (
    <Drop
      ref={(node) => {
        dropRef.current = node;
      }}
      background="white"
      body={
        <Menu
          options={options}
          onSelect={handleSelect}
          logoutLabel={logout?.label}
          onLogout={handleLogout}
        />
      }
      show="controlled" //we will controlle it: onClick or onButtonClick
      placement={dropPosition}
      autoWidth={true}
      arrow={true}
      onChange={(opn) => setOpen(opn)}
    >
      <S.Wrapper onClick={toggleVisibility}>
        <S.ImageContainer>
          {imageSource ? (
            <Avatar alt="Avatar image" size="m" source={imageSource} />
          ) : userName?.length > 0 ? (
            <Avatar alt="Avatar initials" userName={userName} size="m" />
          ) : (
            <Avatar alt="Avatar icon" size="m" />
          )}
        </S.ImageContainer>
        {showDescription && (
          <S.ImageDesc>
            <S.EmployeeInfoContainer
              ref={headerTitleRef}
              $narrowForContent={narrowForContent}
            >
              <S.EmployeeName $mode={mode}>{userName}</S.EmployeeName>
              <S.EmployeeTitle $mode={mode}>{userJobTitle}</S.EmployeeTitle>
            </S.EmployeeInfoContainer>
            <S.ArrowAreaButton
              className="menu-button menu-button--full"
              onClick={toggleVisibility}
              $mode={mode}
            >
              <S.ChevronIcon $menuOpen={open}>
                <IconArrowsDown />
              </S.ChevronIcon>
            </S.ArrowAreaButton>
          </S.ImageDesc>
        )}
      </S.Wrapper>
    </Drop>
  );
};
