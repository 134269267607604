import { gql } from "@apollo/client";
import { apolloClient } from "./../index";
import { DirectionType } from "../types";

//Try to see if the primo exists on the strapi backend. If not add it

export interface primoProps {
  name: string;
  country?: string;
  department?: string;
  // imgUrl?: string;
  azureId: string;
}

export const fetchPrimoId = async (azureId: string) => {
  const query = gql`
    query getPrimo($azureId: String!) {
      primos(filters: { azure_id: { eqi: $azureId } }) {
        data {
          id
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
      variables: { azureId: String(azureId) },
      fetchPolicy: "no-cache", // Ignore le cache
    });
    if (error) throw error;

    //else
    const _data = data?.primos?.data;
    return !_data?.length ? undefined : _data[0].id;
  } catch (error) {
    throw error;
  }
};
export const addPrimo = async ({
  azureId,
  name,
  mail,
  country,
  department,
}: {
  azureId: string;
  name: string;
  mail?: string;
  country?: string;
  department?: string;
}) => {
  const CREATE_PRIMO = gql`
    mutation createPrimo($data: PrimoInput!) {
      createPrimo(data: $data) {
        data {
          id
        }
      }
    }
  `;
  const data = { azure_id: azureId, name, mail, country, department };
  try {
    const result = await apolloClient.mutate({
      mutation: CREATE_PRIMO,
      variables: { data },
      fetchPolicy: "no-cache",
    });
    // if (result.error) throw result.errors;
    return result?.data?.createPrimo?.data?.id;
  } catch (error) {
    // if (error instanceof ApolloError) {
    //   console.error(
    //     "ApolloError Details:",
    //     error.message,
    //     error.graphQLErrors,
    //     error.networkError
    //   );
    // } else {
    //   console.error("Unexpected Error:", error);
    // }
    throw error;
  }
};

export const analysisAuthorisation = async (id: string): Promise<boolean> => {
  try {
    const { error, data } = await apolloClient.query({
      query: gql`
        query{
        analysisDashboard{
          data{attributes{
              users (filters:{id:{eq:${id}}}){
                data{id}}}}
        }}`,
    });
    if (error) throw error;

    //else
    const _data = data?.analysisDashboard?.data?.attributes?.users?.data;
    return !!_data?.length && _data[0].id === id;
  } catch (error) {
    throw error;
  }
};

export const getOrAddPrimoId = async ({
  azureId,
  name,
  mail,
  country,
  department,
}: {
  azureId: string;
  name: string;
  mail?: string;
  country?: string;
  department?: string;
}) => {
  let strapiUserId = await fetchPrimoId(azureId);
  if (!strapiUserId) {
    try {
      strapiUserId = await addPrimo({
        azureId,
        name,
        mail,
        country,
        department,
      });
    } catch (error) {
      strapiUserId = await fetchPrimoId(azureId);
      if (!strapiUserId) {
        throw error;
      }
    }
  }
  return strapiUserId;
};

export const getCountries = async (
  directionType: DirectionType
): Promise<string[]> => {
  const query = gql`
    query {
      primos(
        pagination: { limit: -1 }
        filters: { ${
          directionType === "Target" ? "cheers_received" : "cheers_sent"
        }: { id: { null: false } } }
      ) {
        data {
          id
          attributes {
            country
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
    });
    if (error) throw error;

    //else
    const countries: string[] = data?.primos?.data?.map(
      (x: { attributes: { country: string } }) => x.attributes.country
    );
    const result =
      countries.length > 0 ? Array.from(new Set(countries)).sort() : [];
    return result.filter((x) => x?.length > 0);
  } catch (error) {
    throw error;
  }
};

export const getDepartments = async (
  directionType: DirectionType
): Promise<string[]> => {
  const query = gql`
    query {
      primos(
        pagination: { limit: -1 }
        filters: { ${
          directionType === "Target" ? "cheers_received" : "cheers_sent"
        }: { id: { null: false } } }
      ) {
        data {
          id
          attributes {
            department
          }
        }
      }
    }
  `;
  try {
    const { error, data } = await apolloClient.query({
      query,
    });
    if (error) throw error;

    //else
    const departments: string[] = data?.primos?.data?.map(
      (x: { attributes: { department: string } }) => x.attributes.department
    );
    const result =
      departments.length > 0 ? Array.from(new Set(departments)).sort() : [];
    return result.filter((x) => x?.length > 0);
  } catch (error) {
    throw error;
  }
};
