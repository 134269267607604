const colors = [
  "#FF0000", // Red
  "#0000FF", // Blue
  "#00FF00", // Green
  // "#FFFF00", // Yellow
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#800000", // Maroon
  "#808000", // Olive
  "#008080", // Teal
  "#800080", // Purple
  "#FF4500", // OrangeRed
  "#2E8B57", // SeaGreen
  "#4682B4", // SteelBlue
  "#DAA520", // GoldenRod
  "#CD5C5C", // IndianRed
  "#9932CC", // DarkOrchid
  "#8B4513", // SaddleBrown
  "#FFA07A", // LightSalmon
  "#DDA0DD", // Plum
  "#B0E0E6", // PowderBlue
  "#DC143C", // Crimson
  "#7FFF00", // Chartreuse
  "#FF6347", // Tomato
  "#40E0D0", // Turquoise
  "#9ACD32", // YellowGreen
  "#FFD700", // Gold
  "#ADFF2F", // GreenYellow
  "#BA55D3", // MediumOrchid
  "#20B2AA", // LightSeaGreen
  "#FF69B4", // HotPink
  "#B22222", // FireBrick
  "#5F9EA0", // CadetBlue
  "#FFB6C1", // LightPink
  "#778899", // LightSlateGray
  "#FF1493", // DeepPink
  "#7CFC00", // LawnGreen
  "#6A5ACD", // SlateBlue
  "#00FA9A", // MediumSpringGreen
  "#8A2BE2", // BlueViolet
  "#87CEFA", // LightSkyBlue
];
export default function getColor(n: number): string {
  const index = n % colors.length; // Boucle autour du tableau
  return colors[index];
}
