import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgInterfaceArrowsButtonDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 10 10"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M5 7.874a1.24 1.24 0 0 1-.879-.364L.206 3.393A.75.75 0 1 1 1.294 2.36l3.525 3.712a.25.25 0 0 0 .362 0L8.706 2.36a.75.75 0 1 1 1.088 1.033L5.9 7.5a1.27 1.27 0 0 1-.9.374" />
  </svg>
);
const Memo = memo(SvgInterfaceArrowsButtonDown);
export default Memo;
