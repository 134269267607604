import styled from "styled-components";
import * as COLOR from "./../../../color";
import * as CONST from "./../../../const";
import { scrollBar } from "./../../../utils/scrollBar.style";
import { IAlertState } from "./../interfaces";

const _lineHeightLabel = "20px"; // TODO: to verify

interface InputContainerProps {
  $labelOnTop?: boolean;
  $label?: string;
  $textArea?: boolean;
  $alertState?: IAlertState;
  disabled?: boolean;
  $size?: string;
}

const getColor = ($alertState?: IAlertState): string => {
  switch ($alertState) {
    case "error":
      return COLOR.brand;
    default:
      return COLOR.interaction;
  }
};

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  padding-top: ${(props) =>
    props.$labelOnTop && (props.$label?.length ?? 0) > 0
      ? props.$textArea
        ? "1.5em"
        : "1.2em"
      : "initial"};

  &:focus-within {
    ${(props) =>
      (props.$label?.length ?? 0) > 0 &&
      `padding-top: ${props.$textArea ? "1.5em" : "1.2em"};`};
  }

  & > .form-control-label {
    visibility: ${(props) => (props.$labelOnTop ? "visible" : "hidden")};

    font-family: ${CONST.font_family_body};

    color: ${COLOR.ashes};
    font-size: 12px;
    line-height: ${_lineHeightLabel};
    position: absolute;
    left: 0;
    top: 0.5em;

    ${(props) =>
      props.$alertState === "error" &&
      `color: ${COLOR.brand};
      font-weight: ${CONST.weight_semi_bold};`}
  }

  &:focus-within > .form-control-label {
    visibility: visible;
    font-weight: ${CONST.weight_semi_bold};
    color: ${(props) => getColor(props.$alertState)};
  }

  transition: all 0.2s ease-in-out;

  & ::placeholder {
    ${(props) => props.disabled && `opacity: 0.4;`};
  }
`;

export const TextAreaWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${CONST.isNotMobile} {
    ${scrollBar}
  }
`;
