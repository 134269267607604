import React from "react";
import { TemplateProps } from "./../../api/cheers";
import styles from "./style.module.scss";

export interface CheersPostcardProps {
  template?: TemplateProps;
  title?: string;
  message?: string;
  authorName?: string;
}

export const CheersPostcard: React.FC<CheersPostcardProps> = ({
  template,
  title = "",
  message,
  authorName = "",
}) => {
  const headerImgUrl = template?.headerImgUrl;
  const footerImgUrl = template?.footerImgUrl;
  const _message = message || template?.message || "";

  return (
    <div className={styles.templateContainer}>
      <div className={`${styles.redbar} ${styles.title}`}>{title}</div>
      {headerImgUrl && (
        <div
          className={styles.backGroundImg}
          style={{
            backgroundImage: `url("${`${process.env.REACT_APP_API_BASE_URL}${headerImgUrl}`}")`,
          }}
        />
      )}

      <div className={styles.message}>{_message}</div>

      {footerImgUrl && (
        <div
          className={styles.backGroundImg}
          style={{
            backgroundImage: `url("${`${process.env.REACT_APP_API_BASE_URL}${footerImgUrl}`}")`,
          }}
        />
      )}

      <div className={`${styles.redbar} ${styles.bottom}`}>
        {authorName && `FROM ${authorName}`}
      </div>
    </div>
  );
};

export const getHtmlMessage_old = ({
  template,
  title = "",
  message,
  authorName = "",
}: CheersPostcardProps) => {
  let headerImgUrl = template?.headerImgUrl;
  let footerImgUrl = template?.footerImgUrl;
  const _message = message || template?.message || "";

  // to delete
  headerImgUrl =
    "https://dev-makemomentsmatter.onebacardi.com/static/media/header-background@1x.a447d27284607ecd6a3c.jpg";
  footerImgUrl =
    "https://dev-makemomentsmatter.onebacardi.com/static/media/background-162@3x.c101eff776c5ffba0b56.jpg";

  return `<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
    }
    .templateContainer {
      border-radius: 4px;
      max-width: 600px;
      overflow: hidden;
      box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);
      margin: 20px auto;
    }
    .templateContainer .redbar {
      height: 50px;
      background-color: #c13401;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      background-color: #c13401;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .templateContainer .backGroundImg {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      padding-bottom: 33.67%;
      position: relative;
    }
    .templateContainer .message {
      padding: 32px 24px;
      text-align: center;
      background-color: #fff;
      font-size: 16px;
      line-height: 1.4em;
    }
    .templateContainer .redbar.title {
      font-size: 1.5em;
      font-weight: bold;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .templateContainer .redbar.bottom {
      font-size: 0.8em;
      justify-content: flex-end;
      text-transform: uppercase;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .container {
      width: 80%;
      margin: auto;
    }
    .header {
      background-color: #f8f9fa;
      padding: 10px;
      text-align: center;
    }
    .content {
      margin: 20px 0;
    }
    .footer {
      background-color: #f1f1f1;
      padding: 10px;
      text-align: center;
    }
  </style>
</head>
<body>
  <div class="templateContainer">
    <div class="redbar title">
      ${title}
    </div>
    ${
      headerImgUrl &&
      `<div
      class="backGroundImg"
      style="background-image: url('${headerImgUrl}')"
    ></div>`
    }
    <div class="message">${_message}</div>
    ${
      footerImgUrl &&
      `<div
      class="backGroundImg"
      style="background-image: url('${footerImgUrl}')"
    ></div>`
    }
    <div class="redbar bottom">
      ${authorName}
    </div>
  </div>
</body>
</html>`.replace(/\n/g, "");
};

export const getHtmlMessage_old_table_img_in_background = ({
  template,
  title = "",
  message,
  authorName = "",
}: CheersPostcardProps) => {
  let headerImgUrl = template?.headerImgUrl;
  let footerImgUrl = template?.footerImgUrl;
  const _message = message || template?.message || "";

  // to delete
  headerImgUrl =
    "https://dev-makemomentsmatter.onebacardi.com/static/media/header-background@1x.a447d27284607ecd6a3c.jpg";
  footerImgUrl =
    "https://dev-makemomentsmatter.onebacardi.com/static/media/background-162@3x.c101eff776c5ffba0b56.jpg";

  return `
<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f8f9fa;
    }
    .templateContainer {
      max-width: 600px;
      margin: 20px auto;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);
    }
    .redbar {
      background-color: #c13401;
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      padding: 20px;
    }
    .message {
      padding: 32px 24px;
      background-color: #ffffff;
      text-align: center;
      font-size: 16px;
      line-height: 1.4em;
    }
    .backGroundImg {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      padding-bottom: 33.67%;
    }
    .footer-bar {
      background-color: #c13401;
      color: #ffffff;
      text-align: right;
      font-size: 0.8em;
      text-transform: uppercase;
      padding: 10px 24px;
    }
  </style>
</head>
<body>
  <table width="100%" cellpadding="0" cellspacing="0" border="0">
    <tr>
      <td align="center">
        <table width="600" cellpadding="0" cellspacing="0" border="0" class="templateContainer">
          <tr>
            <td class="redbar" style="font-size: 1.5em;">
              ${title}
            </td>
          </tr>
          ${
            headerImgUrl
              ? `<tr>
                  <td>
                    <div
                      class="backGroundImg"
                      style="background-image: url('${headerImgUrl}');"
                    ></div>
                  </td>
                </tr>`
              : ""
          }
          <tr>
            <td class="message">
              ${_message}
            </td>
          </tr>
          ${
            footerImgUrl
              ? `<tr>
                  <td>
                    <div
                      class="backGroundImg"
                      style="background-image: url('${footerImgUrl}');"
                    ></div>
                  </td>
                </tr>`
              : ""
          }
          <tr>
            <td class="footer-bar">
              ${authorName}
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
`.replace(/\n/g, "");
};

export const getHtmlMessage_using_img = ({
  template,
  title = "",
  message,
  authorName = "",
}: CheersPostcardProps) => {
  let headerImgUrl = template?.headerImgUrl;
  let footerImgUrl = template?.footerImgUrl;
  const _message = message || template?.message || "";

  // to delete
  headerImgUrl =
    "https://dev-makemomentsmatter.onebacardi.com/static/media/header-background@1x.a447d27284607ecd6a3c.jpg";
  footerImgUrl =
    "https://dev-makemomentsmatter.onebacardi.com/static/media/background-162@3x.c101eff776c5ffba0b56.jpg";

  return `
<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f8f9fa;
    }
    .templateContainer {
      max-width: 600px;
      margin: 20px auto;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);
    }
    .redbar {
      background-color: #c13401;
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      padding: 20px;
    }
    .message {
      padding: 32px 24px;
      background-color: #ffffff;
      text-align: center;
      font-size: 16px;
      line-height: 1.4em;
    }
    .footer-bar {
      background-color: #c13401;
      color: #ffffff;
      text-align: right;
      font-size: 0.8em;
      text-transform: uppercase;
      padding: 10px 24px;
    }
  </style>
</head>
<body>
  <table width="100%" cellpadding="0" cellspacing="0" border="0">
    <tr>
      <td align="center">
        <table width="600" cellpadding="0" cellspacing="0" border="0" class="templateContainer">
          <tr>
            <td class="redbar" style="font-size: 1.5em;">
              ${title}
            </td>
          </tr>
          ${
            headerImgUrl
              ? `<tr>
                  <td>
                    <img
                      src="${headerImgUrl}"
                      alt="Header Image"
                      style="display: block; width: 100%; height: auto; border: 0;"
                    />
                  </td>
                </tr>`
              : ""
          }
          <tr>
            <td class="message">
              ${_message}
            </td>
          </tr>
          ${
            footerImgUrl
              ? `<tr>
                  <td>
                    <img
                      src="${footerImgUrl}"
                      alt="Footer Image"
                      style="display: block; width: 100%; height: auto; border: 0;"
                    />
                  </td>
                </tr>`
              : ""
          }
          <tr>
            <td class="footer-bar">
              ${authorName}
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
`.replace(/\n/g, "");
};

// With Attachements CID
export const getHtmlMessage = ({
  template,
  title = "",
  message,
  authorName = "",
}: CheersPostcardProps) => {
  // let headerImgUrl = template?.headerImgUrl;
  // let footerImgUrl = template?.footerImgUrl;
  const _message = message || template?.message || "";

  return `
      <html>
      <body>
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td align="center">
              <table width="600" cellpadding="0" cellspacing="0" border="0" class="templateContainer">
                <tr>
                  <td class="redbar" style="font-size: 1.5em; background-color: #c13401; color: #ffffff; text-align: center; font-weight: bold; padding: 20px;">
                    ${title}
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="cid:headerImage"
                      alt="Header Image"
                      style="display: block; width: 100%; height: auto; border: 0;"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="message" style="padding: 32px 24px; background-color: #ffffff; text-align: center; font-size: 16px; line-height: 1.4em;">
                    ${_message}
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="cid:footerImage"
                      alt="Footer Image"
                      style="display: block; width: 100%; height: auto; border: 0;"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="footer-bar" style="background-color: #c13401; color: #ffffff; text-align: right; font-size: 0.8em; text-transform: uppercase; padding: 10px 24px;">
                    ${authorName}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </body>
      </html>
    `;
};

export const getHtmlMessage_converting_img2base64 = async ({
  template,
  title = "",
  message,
  authorName = "",
}: CheersPostcardProps) => {
  let headerImgUrl = template?.headerImgUrl;
  let footerImgUrl = template?.footerImgUrl;
  const _message = message || template?.message || "";

  async function convertImageToBase64(
    url: string | undefined
  ): Promise<string | null> {
    try {
      if (!url) {
        return null;
      }

      const fullUrl = `${process.env.REACT_APP_API_BASE_URL}${url}`;
      // Fetch the image from the URL
      const response = await fetch(fullUrl);

      // Check if the fetch was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Read the image as a blob
      const blob = await response.blob();

      // Get the MIME type of the image
      const mimeType = blob.type;

      // Create a promise to convert the blob to a Base64 string
      const base64String = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        // Convert blob to Base64 when reading is complete
        reader.onloadend = () => {
          // Ensure reader.result is a string
          if (typeof reader.result === "string") {
            resolve(reader.result);
          } else {
            reject(new Error("Failed to read the file as a data URL"));
          }
        };

        // Handle errors
        reader.onerror = () => {
          reject(new Error("Error reading the blob as Base64"));
        };

        // Read the blob as a data URL (Base64 string)
        reader.readAsDataURL(blob);
      });

      // Replace the incorrect MIME type if necessary
      if (base64String.startsWith("data:text/html")) {
        return base64String.replace("data:text/html", `data:${mimeType}`);
      }

      // Return the Base64 string with the correct MIME type
      return base64String;
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      return null;
    }
  }

  const headerBase64Image = await convertImageToBase64(headerImgUrl);
  const footerBase64Image = await convertImageToBase64(footerImgUrl);

  const result = `
      <html>
      <body>
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td align="center">
              <table width="600" cellpadding="0" cellspacing="0" border="0" class="templateContainer">
                <tr>
                  <td class="redbar" style="font-size: 1.5em; background-color: #c13401; color: #ffffff; text-align: center; font-weight: bold; padding: 20px;">
                    ${title}
                  </td>
                </tr>
                ${
                  headerBase64Image &&
                  `<tr>
                  <td>
                    <img
                      src="${headerBase64Image}"
                      alt="Header"
                      style="display: block; width: 100%; height: auto; border: 0;"
                    />
                  </td>
                </tr>`
                }
                <tr>
                  <td class="message" style="padding: 32px 24px; background-color: #ffffff; text-align: center; font-size: 16px; line-height: 1.4em;">
                    ${_message}
                  </td>
                </tr>
                ${
                  footerBase64Image &&
                  `<tr>
                  <td>
                   <img
                    src="${footerBase64Image}"
                    alt="Footer"
                    style="display: block; width: 100%; height: auto; border: 0;"
                  />
                  </td>
                </tr>`
                }
                <tr>
                  <td class="footer-bar" style="background-color: #c13401; color: #ffffff; text-align: right; font-size: 0.8em; text-transform: uppercase; padding: 10px 24px;">
                    ${authorName}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </body>
      </html>
    `.replace(/\n/g, "");

  return result;
};
