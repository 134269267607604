import React, { useState, useCallback, useEffect, useContext } from "react";
import { BasicButton } from "../../Button";
import { TextInput } from "../../TextInput";
import { Select } from "../../Select";
import { ISelectMenuOption } from "../../Select/interfaces";

import { Switch } from "../../Switch";
import { fetchUsersByPrefix } from "../../../auth/utils";
import { RecipientScope } from "./../../../types";
import { PageWithImage } from "./../../CheersPageWithImage";
import { AuthContext } from "../../../auth/AuthProvider";
import { MAIN_COLOR } from "./../../../config";
import styles from "./stepsStyle.module.scss";

export type form1Props = {
  includeManager: boolean;
  primoRecipients: ISelectMenuOption[];
  primoCopies: ISelectMenuOption[];
  nickname?: string;
  groupName?: string;
  extRecipients: string[];
  extCopies: string[];
};
export type form1SettersProps = {
  setIncludeManager: (v: boolean) => void;
  setPrimoRecipients: (v: ISelectMenuOption[]) => void;
  setPrimoCopies: (v: ISelectMenuOption[]) => void;
  setNickname: (v?: string) => void;
  setGroupName: (v?: string) => void;
  setExtRecipients: (v: string[]) => void;
  setExtCopies: (v: string[]) => void;
};
interface SendStep3BodyProps extends form1Props, form1SettersProps {
  toGroup: boolean;
  recipientType: RecipientScope;
  goNext: () => void;
  closeMe: () => void;
}
export const SendStep3Body: React.FC<SendStep3BodyProps> = ({
  includeManager,
  primoRecipients,
  primoCopies,
  nickname,
  groupName,
  extRecipients = [],
  extCopies = [],
  //
  setIncludeManager,
  setPrimoRecipients,
  setPrimoCopies,
  setNickname,
  setGroupName,
  setExtRecipients,
  setExtCopies,
  //
  toGroup,
  recipientType,
  goNext,
  closeMe,
}) => {
  const [valideForm, setValideForm] = useState<boolean>(false);
  const [valideExtRecipients, setValideExtRecipients] = useState<boolean>(true);
  const [valideExtCopies, setValideExtCopies] = useState<boolean>(true);
  const [extRecipientsStr, setExtRecipientsStr] = useState<string>("");
  const [extCopiesStr, setExtCopiesStr] = useState<string>("");

  const me = useContext(AuthContext);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValideEmails = useCallback(() => {
    const _valideExtRecipients = extRecipients.every(isValidEmail);
    const _valideExtCopies = extCopies.every(isValidEmail);

    // setValideExtRecipients(_valideExtRecipients);
    // setValideExtCopies(_valideExtCopies);

    return _valideExtRecipients && _valideExtCopies;
  }, [extRecipients, extCopies]);

  useEffect(() => {
    let _valide =
      (!toGroup && recipientType === "Primo" && !!primoRecipients?.length) ||
      (!toGroup &&
        recipientType === "External" &&
        !!nickname?.length &&
        !!extRecipients?.length) ||
      (toGroup &&
        !!groupName &&
        ((recipientType === "Primo" && !!primoRecipients?.length) ||
          (recipientType === "External" && !!extRecipients?.length) ||
          (recipientType === "Mixed" &&
            !!primoRecipients?.length &&
            !!extRecipients?.length)));

    _valide = _valide && isValideEmails();
    setValideForm(_valide);
  }, [
    extRecipients,
    groupName,
    nickname,
    primoRecipients,
    recipientType,
    toGroup,
    isValideEmails,
  ]);

  const validateExtRecipients = () => {
    const valideEmails = extRecipients?.every(isValidEmail) ?? true;
    setValideExtRecipients(valideEmails);
  };
  const validateExtCopies = () => {
    const valideEmails = extCopies?.every(isValidEmail) ?? true;
    setValideExtCopies(valideEmails);
  };

  const handleExtRecipientsChange = (str: string) => {
    const trim = str?.trim();
    if (!trim?.length) {
      setExtRecipients([]);
    }
    //else
    let substrings = str.split(/[,\s;]+/); //split is space or ";" or ","
    substrings = Array.from(new Set(substrings)); // Remove duplicates

    setExtRecipients(toGroup ? substrings : [substrings[0]]);
    setExtRecipientsStr(toGroup ? substrings.join(";") : substrings[0]);

    //change the validation value only if true
    const valideEmails = substrings?.every(isValidEmail) ?? true;
    if (valideEmails) setValideExtRecipients(true);
  };
  const handleExtCopiesChange = (str: string) => {
    const trim = str?.trim();
    if (!trim?.length) {
      setExtCopies([]);
    }
    //else
    let substrings = str.split(/[,\s;]+/); //split is space or ";" or ","
    substrings = Array.from(new Set(substrings)); // Remove duplicates

    setExtCopies(substrings);
    setExtCopiesStr(substrings.join(";"));

    //change the validation value only if true
    const valideEmails = substrings?.every(isValidEmail) ?? true;
    if (valideEmails) setValideExtCopies(true);
  };
  const handleGoNext = () => {
    //TODO: verify all the information & if error => show errors

    goNext();
  };

  //The author should not be the receipient
  const fetchRecipientByPrefix = useCallback(
    async (prefix: string) => {
      let result = await fetchUsersByPrefix(prefix);

      //Should not be the author
      result = result.filter((item) => me?.azureId !== item.value);

      //Should not exists in primo copy (cc)
      if (!!result?.length && !!primoCopies?.length) {
        const primoCopiesIds = new Set(primoCopies.map((x) => x.value));
        result = result.filter((item) => !primoCopiesIds.has(item.value));
      }

      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [primoCopies, me]
  );

  //Should not exists in primos-recipent
  const fetchPrimoCcByPrefix = useCallback(
    async (prefix: string) => {
      let result = await fetchUsersByPrefix(prefix);

      //Should not be the author
      result = result.filter((item) => me?.azureId !== item.value);

      //Should not exists in primo recipients (to)
      if (!!result?.length && !!primoRecipients?.length) {
        const primoRecipientIds = new Set(primoRecipients.map((x) => x.value));
        result = result.filter((item) => !primoRecipientIds.has(item.value));
      }
      return result;
    },
    [primoRecipients, me]
  );

  return (
    <PageWithImage iconName="Cross" onIconClick={closeMe} helpButton>
      <div className={styles.formContainer}>
        <div className={styles.formCore}>
          <div className={styles.formTitle}>
            <div>
              {`${toGroup ? "Group" : "Individual"} `}
              {recipientType === "Mixed" && <b>Bacardi &</b>}
            </div>
            <div>
              <b>{`${recipientType === "Primo" ? "Bacardi" : "External"}`}</b>{" "}
              Cheers!
            </div>
          </div>
          <div className={styles.stepNumber}>Step 1</div>
          <b>Who will receive?</b>

          {/* Single Primo || Group Primos || Group Mixed */}
          {(recipientType === "Primo" || recipientType === "Mixed") && (
            <Select
              label={
                toGroup
                  ? recipientType === "Primo"
                    ? "Recipients *" //Group && Primos
                    : "Bacardi recipients *" //Group && Mixed
                  : "Recipient *" //Single && Primo
              }
              placeholder="Add recipient..."
              // options={EMPLOYEES1}
              fetchOptionsByPrefix={fetchRecipientByPrefix}
              size="xl"
              autosuggest
              multi={toGroup}
              // useCheckbox={false}
              selected={primoRecipients}
              onSelect={(v) => {
                setPrimoRecipients(v);
              }}
            />
          )}

          {/* TO Externals List */}
          {(recipientType === "External" || recipientType === "Mixed") && (
            <div>
              <TextInput
                size="xl"
                label={`External recipient${toGroup ? "s" : ""} *`} // emails searated by semicolon(;)"
                placeholder={
                  toGroup
                    ? "Add emails searated by semicolon(;)..."
                    : "Add an email..."
                }
                value={extRecipientsStr}
                onChange={handleExtRecipientsChange}
                onBlur={validateExtRecipients}
                alertState={!valideExtRecipients ? "error" : undefined}
                helperMessage={
                  !valideExtRecipients ? "Invalid email address." : undefined
                }
              />
            </div>
          )}

          {/* Single => Nickname */}
          {!toGroup && (
            <TextInput
              size="xl"
              label={`Recipient's Nickname ${
                recipientType === "External" ? "*" : ""
              }`}
              placeholder="Add the nickname..."
              value={nickname}
              onChange={(v) => setNickname(v)}
            />
          )}
          {/* Group => Group name */}
          {toGroup && (
            <TextInput
              size="xl"
              label="Group name *"
              placeholder="Add the groupe name..."
              value={groupName}
              onChange={(v) => setGroupName(v)}
            />
          )}

          {/* Primos in copy */}
          <Select
            label="Copy Prim@s"
            placeholder="Add primos..."
            // options={EMPLOYEES2}
            fetchOptionsByPrefix={fetchPrimoCcByPrefix}
            size="xl"
            autosuggest
            multi
            selected={primoCopies}
            onSelect={(p) => setPrimoCopies(p)}
          />

          {(recipientType === "External" || recipientType === "Mixed") && (
            <div>
              <TextInput
                size="xl"
                label="Copy externals emails" // separated by semicolon (;)"
                placeholder="Add emails separated by semicolon (;)..."
                value={extCopiesStr}
                onChange={handleExtCopiesChange}
                onBlur={validateExtCopies}
                alertState={!valideExtCopies ? "error" : undefined}
                helperMessage={
                  !valideExtCopies ? "Invalid email address." : undefined
                }
              />
            </div>
          )}

          {(recipientType === "Primo" || recipientType === "Mixed") && (
            <label className={styles.flexH}>
              <Switch
                disabled={false}
                size="s"
                name="inputName"
                value={includeManager}
                onChange={(v) => setIncludeManager(v)}
              />
              <div>{`Include manager${toGroup ? "s" : ""}`}</div>
            </label>
          )}
        </div>
        <BasicButton
          onClick={handleGoNext}
          label="Next"
          variant="primary"
          size="xl"
          corners="squared"
          auto
          color={MAIN_COLOR}
          disabled={!valideForm}
        />
      </div>
    </PageWithImage>
  );
};
