export default function nameIntials(nameStr: string) {
  let initials = "";
  if (nameStr?.length > 0) {
    let words = nameStr.replace(/\s+/g, " ").trim().split(" ");
    if (words?.length > 1)
      initials = `${words[0].charAt(0)}${words[1].charAt(0)}`;
    else if (words[0].length > 1) initials = words[0].substring(0, 2);
    else initials = words[0].charAt(0);
    return initials.toUpperCase();
  }
  return "";
}
