import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M378.413 0H195.115L185.8 9.314 57.02 138.102l-9.314 9.314v278.69c0 47.36 38.528 85.895 85.896 85.895h244.811c47.353 0 85.881-38.535 85.881-85.895V85.896C464.294 38.528 425.766 0 378.413 0m54.084 426.105c0 29.877-24.214 54.091-54.084 54.091H133.602c-29.884 0-54.098-24.214-54.098-54.091V160.591h83.716c24.885 0 45.077-20.178 45.077-45.07V31.804h170.116c29.87 0 54.084 24.214 54.084 54.092z"
      className="pdf_svg__st0"
    />
    <path
      d="M171.947 252.785h-28.529c-5.432 0-8.686 3.533-8.686 8.825v73.754c0 6.388 4.204 10.599 10.041 10.599 5.711 0 9.914-4.21 9.914-10.599v-22.406c0-.545.279-.817.824-.817h16.436c20.095 0 32.188-12.226 32.188-29.612.001-17.658-11.953-29.744-32.188-29.744m-1.228 42.103h-15.208c-.545 0-.824-.272-.824-.81v-23.23c0-.545.279-.816.824-.816h15.208c8.42 0 13.447 5.027 13.447 12.498.001 7.47-5.027 12.358-13.447 12.358M250.191 252.785h-21.868c-5.432 0-8.686 3.533-8.686 8.825v74.843c0 5.3 3.253 8.693 8.686 8.693h21.868c19.69 0 31.923-6.249 36.81-21.324 1.76-5.3 2.723-11.681 2.723-24.857s-.964-19.557-2.723-24.856c-4.888-15.075-17.12-21.324-36.81-21.324m17.665 64.111c-2.318 7.331-8.965 10.459-18.21 10.459h-9.23c-.545 0-.824-.272-.824-.816v-55.146c0-.545.279-.817.824-.817h9.23c9.245 0 15.892 3.128 18.21 10.46.95 3.128 1.62 8.56 1.62 17.93s-.671 14.802-1.62 17.93M361.167 252.785h-44.812c-5.432 0-8.7 3.533-8.7 8.825v73.754c0 6.388 4.218 10.599 10.055 10.599 5.697 0 9.914-4.21 9.914-10.599v-26.351c0-.538.265-.81.81-.81h26.086c5.837 0 9.23-3.532 9.23-8.56s-3.393-8.553-9.23-8.553h-26.086c-.545 0-.81-.272-.81-.817v-19.425c0-.545.265-.816.81-.816h32.733c5.572 0 9.245-3.666 9.245-8.553-.001-5.029-3.674-8.694-9.245-8.694"
      className="pdf_svg__st0"
    />
  </svg>
);
const Memo = memo(SvgPdf);
export default Memo;
