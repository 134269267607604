import styled from "styled-components";
import * as C from "./spinner.constants";

interface CircleProps {
  $radius: number;
  $size: keyof typeof C.CIRCLE_SIZE_PROPERTIES;
  $variant: keyof typeof C.CIRCLE_VARIANT_PROPERTIES;
}

interface SvgProps {
  $radius: number;
}
interface ContainerProps {
  $auto?: boolean;
  $fullPage?: boolean;
}

export const Circle = styled.circle<CircleProps>`
  animation: fillIn 2000ms;
  fill: transparent;
  stroke-dasharray: ${(props) => `${2.5 * props.$radius * Math.PI}`};
  stroke-dashoffset: ${(props) => `${2 * props.$radius * Math.PI * 0.66}`};
  stroke-linecap: round;
  ${(props) => C.CIRCLE_SIZE_PROPERTIES[props.$size]}
  ${(props) => C.CIRCLE_VARIANT_PROPERTIES[props.$variant]};
  @keyframes fillIn {
    0% {
      stroke-dashoffset: ${(props) => `${2.5 * props.$radius * Math.PI}`};
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  display: inline-flex;
  ${(props) =>
    props.$auto &&
    `
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  `}
  ${(props) => props.$fullPage && "height: 100vh;"}
`;

// Composant styled pour Svg avec typage
export const Svg = styled.svg<SvgProps>`
  animation: rotate 550ms linear infinite;
  height: ${(props) => `${props.$radius * 4}px`};
  width: ${(props) => `${props.$radius * 4}px`};
  @keyframes rotate {
    0% {
      transform: rotate(-360deg);
    }
  }
`;
