import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { subMonths } from "date-fns";
import { SideSheetModal } from "./../../components/SideSheet";
import { LoadingIndicator } from "./../../components/LoadingIndicator";
import { Icon } from "./../../components/Icon";
import { IconButton } from "./../../components/Button";
import { CheersLayout } from "./../../components/CheersLayout";
import { useMobileSizeCheck } from "./../../components/utils/useMediaSizeCheck";

import { SelectedFilter } from "./../../components/Filter/SelectedFilter";
import { Summary } from "./Summary";
import { Winners } from "./Winners";
import { TrendsCharts } from "./TrendsCharts";
import { getCheers4analysis } from "./../../api/cheers";
// import { MAIN_COLOR } from "./../../config";

import { AnalysisFilter } from "./AnalysisFilter";
import {
  FullFilterProps,
  AnalysisPointProps,
  AnalysisDataProps,
  WinnerProps,
  WinnerType,
  GroupedByName,
  AnalysisPrimoProps,
  ChartLineProps,
} from "./../../types";

// import { trendsData } from "./trendData";
import styles from "./style.module.scss";

const showWinnersAuthors = (lst?: AnalysisPointProps[]): WinnerProps[] => {
  let result: WinnerProps[] = [];
  if (!!lst?.length) {
    // Group with from.name
    const groupedItems = lst.reduce(
      (acc: Record<string, WinnerProps>, item) => {
        const authorName = item.from.name;

        if (!acc[authorName]) {
          acc[authorName] = {
            name: authorName,
            count: 0,
            cheers: [],
            data: item.from,
          };
        }

        acc[authorName].count += 1;
        acc[authorName].cheers.push(item);

        return acc;
      },
      {}
    );

    // Convertir en tableau et trier par count décroissant
    result = Object.values(groupedItems).sort((a, b) => b.count - a.count);
  }
  return result;
};
const showWinnersPrimoRecipient = (
  lst?: AnalysisPointProps[]
): WinnerProps[] => {
  let result: WinnerProps[] = [];
  if (!!lst?.length) {
    // Group with from.name
    const groupedItems = lst.reduce(
      (acc: Record<string, WinnerProps>, item) => {
        const primos = item.toPrimo;
        if (!!primos?.length) {
          primos.forEach((primo) => {
            const recipientName = primo.name;

            if (!acc[recipientName]) {
              acc[recipientName] = {
                name: recipientName,
                count: 0,
                cheers: [],
              };
            }
            acc[recipientName].count += 1;
            acc[recipientName].cheers.push(item);
          });
        }
        return acc;
      },
      {}
    );

    // Convertir en tableau et trier par count décroissant
    result = Object.values(groupedItems).sort((a, b) => b.count - a.count);
  }
  return result;
};
const showWinnersExternalRecipient = (
  lst?: AnalysisPointProps[]
): WinnerProps[] => {
  let result: WinnerProps[] = [];
  if (!!lst?.length) {
    // Group with from.name
    const groupedItems = lst.reduce(
      (acc: Record<string, WinnerProps>, item) => {
        const exterals = item.toExternal;
        if (!!exterals?.length) {
          exterals.forEach((exteral) => {
            let recipientMail = exteral;

            const emailPattern =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const valide = emailPattern.test(recipientMail);
            if (!valide) {
              console.log(recipientMail);
              recipientMail = emailPattern.test(recipientMail)
                ? recipientMail
                : "Not Available";
            }
            if (valide) {
              if (!acc[recipientMail]) {
                acc[recipientMail] = {
                  name: recipientMail,
                  count: 0,
                  cheers: [],
                };
              }
              acc[recipientMail].count += 1;
              acc[recipientMail].cheers.push(item);
            }
          });
        }

        return acc;
      },
      {}
    );

    // Convertir en tableau et trier par count décroissant
    result = Object.values(groupedItems).sort((a, b) => b.count - a.count);
  }
  return result;
};
const showWinnersGroupedByName = (lst?: GroupedByName[]): WinnerProps[] => {
  let result: WinnerProps[] = [];
  if (!!lst?.length) {
    result = lst.map((x) => ({
      name: x.name === "null" ? "Not Available" : x.name ?? "Not Available",
      count: x?.items?.length ?? 0,
      cheers: x?.items,
    }));
  }
  result.sort((a, b) => b.count - a.count);
  return result;
};

const AnalysisPage: React.FC = () => {
  // const [cheers, setCheers] = useState<AnalysisPointProps[]>([]); // Items fetched from the server
  const [data, setData] = useState<AnalysisDataProps>({}); // Items fetched from the server
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [loadingCheers, setLoadingCheers] = useState<number>(0);
  const [filter, setFilter] = useState<FullFilterProps>({
    startDate: subMonths(new Date(), 2), //Only the "2 Months Ago" to avoid long loading time
  });
  const [winners, setWinners] = useState<WinnerProps[]>([]);
  const [winnersType, setWinnersType] = useState<WinnerType>(
    WinnerType.AllCheers
  );
  const [charts, setCharts] = useState<ChartLineProps[]>([]);
  const isMobile = useMobileSizeCheck();

  const winnersDiv = useRef<HTMLDivElement | null>(null);
  const chartsDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    switch (winnersType) {
      case WinnerType.AllCheers:
        setWinners(showWinnersAuthors(data?.cheers));
        break;
      case WinnerType.AtIndividual:
        setWinners(showWinnersAuthors(data?.cheersToIndividual));
        break;
      case WinnerType.AtGroup:
        setWinners(showWinnersAuthors(data?.cheersToGroup));
        break;
      case WinnerType.AtPrimos:
        setWinners(showWinnersAuthors(data?.cheersToPrimos));
        break;
      case WinnerType.AtExternal:
        setWinners(showWinnersAuthors(data?.cheersToExternals));
        break;
      case WinnerType.AtMixed:
        setWinners(showWinnersAuthors(data?.cheersToMixeds));
        break;
      case WinnerType.ManagerIncluded:
        setWinners(showWinnersAuthors(data?.cheersManagerIncluded));
        break;
      case WinnerType.PushedToFeed:
        setWinners(showWinnersAuthors(data?.cheersPushedToFeed));
        break;
      //
      case WinnerType.PrimosRecipients:
        setWinners(showWinnersPrimoRecipient(data?.cheers));
        break;
      case WinnerType.ExternalsRecipients:
        setWinners(showWinnersExternalRecipient(data?.cheers));
        break;
      //
      case WinnerType.Categories:
        setWinners(showWinnersGroupedByName(data?.groupedByCategories));
        break;
      case WinnerType.SourceCountries:
        setWinners(showWinnersGroupedByName(data?.groupedBySourceCountry));
        break;
      case WinnerType.SourceDepartments:
        setWinners(showWinnersGroupedByName(data?.groupedBySourceDepartment));
        break;
      case WinnerType.RecipientsCountries:
        setWinners(showWinnersGroupedByName(data?.groupedByTargetCountry));
        break;
      case WinnerType.RecipientsDepartments:
        setWinners(showWinnersGroupedByName(data?.groupedByTargetDepartment));
        break;
      default:
        setWinnersType(WinnerType.AllCheers);
        break;
    }
  }, [winnersType, data]);

  // Fetch cheers from the server when the component mounts
  useEffect(() => {
    const groupedByCategories = (items: AnalysisPointProps[]) => {
      if (!items?.length) return [];

      const _groupedByCountry = items.reduce<{
        [country: string]: AnalysisPointProps[];
      }>((acc, obj) => {
        const name = obj?.category;

        //ignore if from country is undefined
        if (name === undefined) {
          return acc; // Retourner l'accumulateur sans faire d'ajout
        }

        // Vérifie si le pays existe déjà dans l'accumulateur
        if (!acc[name]) {
          acc[name] = []; // Initialiser un tableau pour ce pays
        }

        // Ajouter l'objet entier à l'ensemble pour ce pays
        acc[name].push(obj);

        return acc;
      }, {});

      // Transformation de l'objet en tableau de blocs
      const resultArray = Object.entries(_groupedByCountry).map(
        ([name, items]) => ({
          name,
          items,
        })
      );
      return resultArray;
    };
    const groupedBySourceCountry = (items: AnalysisPointProps[]) => {
      if (!items?.length) return [];

      const _groupedByCountry = items.reduce<{
        [country: string]: AnalysisPointProps[];
      }>((acc, obj) => {
        const name = obj?.from?.country;

        //ignore if from country is undefined
        if (name === undefined) {
          return acc; // Retourner l'accumulateur sans faire d'ajout
        }

        // Vérifie si le pays existe déjà dans l'accumulateur
        if (!acc[name]) {
          acc[name] = []; // Initialiser un tableau pour ce pays
        }

        // Ajouter l'objet entier à l'ensemble pour ce pays
        acc[name].push(obj);

        return acc;
      }, {});

      // Transformation de l'objet en tableau de blocs
      const resultArray = Object.entries(_groupedByCountry).map(
        ([name, items]) => ({
          name,
          items,
        })
      );
      return resultArray;
    };
    const groupedByTargetCountry = (items: AnalysisPointProps[]) => {
      if (!items?.length) return [];

      const _groupedByCountry = items.reduce<{
        [country: string]: AnalysisPointProps[];
      }>((acc, obj) => {
        const countries = obj?.toPrimo?.map((recipient) => recipient.country); // Extraire les pays de toPrimo

        if (countries === undefined) {
          return acc; // Retourner l'accumulateur sans faire d'ajout
        }
        countries.forEach((name) => {
          // Vérifie si le pays existe déjà dans l'accumulateur
          if (!acc[name]) {
            acc[name] = []; // Initialiser un tableau pour ce pays
          }

          // Ajouter l'objet entier au pays correspondant
          acc[name].push(obj);
        });

        return acc;
      }, {});

      // Transformation de l'objet en tableau de blocs
      const resultArray = Object.entries(_groupedByCountry).map(
        ([name, items]) => ({
          name,
          items,
        })
      );
      return resultArray;
    };
    const groupedBySourceDepartment = (items: AnalysisPointProps[]) => {
      if (!items?.length) return [];

      const _groupedByCountry = items.reduce<{
        [department: string]: AnalysisPointProps[];
      }>((acc, obj) => {
        const name = obj?.from?.department;

        if (name === undefined) {
          return acc; // Retourner l'accumulateur sans faire d'ajout
        }
        // Vérifie si le pays existe déjà dans l'accumulateur
        if (!acc[name]) {
          acc[name] = []; // Initialiser un tableau pour ce pays
        }

        // Ajouter l'objet entier à l'ensemble pour ce pays
        acc[name].push(obj);

        return acc;
      }, {});

      // Transformation de l'objet en tableau de blocs
      const resultArray = Object.entries(_groupedByCountry).map(
        ([name, items]) => ({
          name,
          items,
        })
      );
      return resultArray;
    };
    const groupedByTargetDepartment = (items: AnalysisPointProps[]) => {
      if (!items?.length) return [];

      const _groupedByCountry = items.reduce<{
        [department: string]: AnalysisPointProps[];
      }>((acc, obj) => {
        const countries = obj?.toPrimo?.map(
          (recipient) => recipient.department
        ); // Extraire les pays de toPrimo

        if (countries === undefined) {
          return acc; // Retourner l'accumulateur sans faire d'ajout
        }
        countries.forEach((name) => {
          // Vérifie si le pays existe déjà dans l'accumulateur
          if (!acc[name]) {
            acc[name] = []; // Initialiser un tableau pour ce pays
          }

          // Ajouter l'objet entier au pays correspondant
          acc[name].push(obj);
        });

        return acc;
      }, {});

      // Transformation de l'objet en tableau de blocs
      const resultArray = Object.entries(_groupedByCountry).map(
        ([name, items]) => ({
          name,
          items,
        })
      );
      return resultArray;
    };
    const primoRecipients = (items: AnalysisPointProps[]) => {
      const concatenatedArray = items.flatMap(({ date, toPrimo }) =>
        toPrimo.map((x) => ({ date, ...x }))
      );
      return concatenatedArray;
    };
    const externalRecipients = (items: AnalysisPointProps[]) => {
      const concatenatedArray = items.flatMap(({ date, toExternal }) =>
        toExternal.map((x) => ({ date, mail: x }))
      );
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return concatenatedArray.filter((x) => emailPattern.test(x?.mail));
    };

    async function fetchCheersWithProgress() {
      let allItems: any[] = [];
      let page = 1;
      let totalPages = 1; // Valeur initiale temporaire
      const pageSize = 50;

      while (page <= totalPages) {
        const { items, pagesCount } = await getCheers4analysis(
          filter,
          page,
          pageSize
        );

        allItems = [...allItems, ...items]; // Ajoute les éléments récupérés à la liste totale
        totalPages = pagesCount; // Mets à jour le nombre total de pages si nécessaire

        const progress = Math.round((page / totalPages) * 100); // Calcul du pourcentage
        setLoadingCheers(progress);

        page++; // Passer à la page suivante
      }

      return allItems; // Retourne tous les items une fois toutes les pages récupérées
    }
    const fetchCheers = async () => {
      try {
        // const _items = await getCheers4analysis(filter);
        const _items = await fetchCheersWithProgress();

        setData({
          cheers: _items,
          cheersToIndividual: _items.filter(
            (x: AnalysisPointProps) => x.recipientType !== "Group"
          ),
          cheersToGroup: _items.filter(
            (x: AnalysisPointProps) => x.recipientType === "Group"
          ),
          cheersToPrimos: _items.filter(
            (x: AnalysisPointProps) => x.recipientScope === "Primo"
          ),
          cheersToExternals: _items.filter(
            (x: AnalysisPointProps) => x.recipientScope === "External"
          ),
          cheersToMixeds: _items.filter(
            (x: AnalysisPointProps) => x.recipientScope === "Mixed"
          ),
          cheersManagerIncluded: _items.filter(
            (x: AnalysisPointProps) => x.includedManager
          ),
          cheersPushedToFeed: _items.filter(
            (x: AnalysisPointProps) => x.publishedToFeed
          ),
          groupedByCategories: groupedByCategories(_items),
          groupedBySourceCountry: groupedBySourceCountry(_items),
          groupedByTargetCountry: groupedByTargetCountry(_items),
          groupedBySourceDepartment: groupedBySourceDepartment(_items),
          groupedByTargetDepartment: groupedByTargetDepartment(_items),
          primoRecipients: primoRecipients(_items),
          externalRecipients: externalRecipients(_items),
          // allRecipients:allRecipients(_items)
        });
      } catch (error) {
        console.error("Error fetching cheers:", error);
      } finally {
        setLoadingCheers(0);
      }
    };

    fetchCheers();
  }, [filter]);

  //when filter change, the charts are outdated
  useEffect(() => {
    setCharts([]);
  }, [filter]);
  const handleFilterChanged = (newFilter?: FullFilterProps) => {
    setFilter(newFilter ?? {});
    setFilterVisible(false);
  };

  const handleWinnersTypeChange = (v: WinnerType) => {
    setWinnersType(v);
    if (winnersDiv.current) {
      winnersDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // useEffect(() => {
  //   if (charts?.length && chartsDiv.current) {
  //     chartsDiv.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [charts]);

  useLayoutEffect(() => {
    if (charts?.length && chartsDiv.current) {
      // Ensure the element is fully rendered
      const observer = new ResizeObserver(() => {
        // Once the element is fully rendered, scroll into view
        chartsDiv.current!.scrollIntoView({ behavior: "smooth" });
      });

      // Observe size changes in the div
      observer.observe(chartsDiv.current);

      // Clean up the observer after scrolling
      return () => observer.disconnect();
    }
  }, [charts]);

  return (
    <CheersLayout>
      <div className={styles.pageWrap}>
        <div className={styles.pageHeader}>
          <img src="/img/header1.png" alt="Header" />
        </div>
        <div className={styles.pageBody}>
          <div className={styles.titleWrapper}>
            <h1>Trend Analysis Dashboard</h1>
            {!filterVisible && (
              <IconButton
                // corners="rounded"
                icon={<Icon name="Filter2" size={26} />}
                size="m"
                onClick={() => {
                  setFilterVisible(true);
                }}
                variant="tertiary"
                // color={MAIN_COLOR}
              />
            )}
          </div>
          <div className={styles.filterSection}>
            <SelectedFilter filter={filter} onChange={handleFilterChanged} />
            {!!loadingCheers && (
              <div className={styles.loading}>
                <LoadingIndicator />
                <div className={styles.loadingPercent}>
                  <div style={{ fontSize: "0.8em", color: "#888" }}>
                    Narrow filters for better performance.
                  </div>
                  <div>Loading: {loadingCheers}%</div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.summaryAndWinner}>
            <div className={`${styles.summary} ${styles.bodySection}`}>
              <Summary
                data={data}
                filter={filter}
                active={winnersType}
                onActiveWinnerChange={handleWinnersTypeChange}
                charts={charts}
                onChartsChange={setCharts}
              />
            </div>
            <div
              ref={winnersDiv}
              className={`${styles.winner} ${styles.bodySection}`}
            >
              <Winners
                data={winners}
                type={winnersType}
                charts={charts}
                onChartsChange={setCharts}
              />
            </div>
          </div>
          <div ref={chartsDiv}>
            {!!charts?.length && (
              <div className={styles.bodySection}>
                <TrendsCharts
                  charts={charts}
                  clearGraphs={() => setCharts([])}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SideSheetModal
        scrollableContent={false}
        open={!!filterVisible}
        closeMe={() => {
          setFilterVisible(false);
        }}
        fullscreen={isMobile}
        position="right"
        closeOnSwip={false}
      >
        {filterVisible && (
          <AnalysisFilter
            filter={filter}
            onFilterChange={handleFilterChanged}
            closeMe={() => setFilterVisible(false)}
          />
        )}
      </SideSheetModal>
    </CheersLayout>
  );
};

export default AnalysisPage;
