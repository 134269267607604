import React, { ReactNode } from "react";
import { Icon } from "./../Icon";
import { CheersHelpButton } from "./../CheersHelpButton";
import { Container } from "./style";

interface PageWithImageProps {
  children: ReactNode;
  imgUrl?: string;
  iconName?: string;
  helpButton?: boolean;
  onIconClick?: () => void;
}

export const PageWithImage: React.FC<PageWithImageProps> = ({
  children,
  imgUrl,
  iconName,
  helpButton = false,
  onIconClick,
}) => {
  return (
    <Container $imgUrl={imgUrl}>
      {iconName && (
        <div className="closeIcon" onClick={onIconClick}>
          <Icon name={iconName} size={32} />
        </div>
      )}
      {helpButton && <CheersHelpButton />}
      {children}
    </Container>
  );
};
