import * as CONST from "./../const";

const Types = {
  XSmall: "xs",
  XXSmall: "xxs",
};

export const CHECKBOX_CHECKED_SIZE_PROPERTIES = {
  [Types.XSmall]: `height: calc(${CONST.height_xs}/2);
    width: calc(${CONST.height_xs}/2);    
    `,
  [Types.XXSmall]: `height: calc(${CONST.height_xxs}/2);
  width: calc(${CONST.height_xxs}/2);    
  `,
};

export const CHECKBOX_INPUT_SIZE_PROPERTIES = {
  [Types.XSmall]: `height: ${CONST.height_xs};
  width: ${CONST.height_xs};
  `,
  [Types.XXSmall]: `height: ${CONST.height_xxs};
  width: ${CONST.height_xxs};
  `,
};

export const LABEL_SIZE_PROPERTIES = {
  [Types.XSmall]: `font-size: 16px;
  line-height: 1.4em;
    `,
  [Types.XXSmall]: `font-size: 14px;
  line-height: 1.2em;
    `,
};
