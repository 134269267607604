import React, { useEffect, useState, ReactNode } from "react";
import {
  MsalProvider,
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Spinner } from "./../components/Spinner";
import { fetchMeInfo } from "./utils";
import { getOrAddPrimoId, analysisAuthorisation } from "./../api/primos";
import { loginRequest } from "./authConfig";
import AuthErrorPageContent from "./../componentsCheers/error/AuthErrorPageContent";
// import { rbs, me } from "./2delete.fake";

interface UserProps {
  strapiId: string;
  analysisAuthorization: boolean; //if thrue will be authorised for trend analysis dashboard
  azureId: string;
  mail?: string;
  name: string;
  country?: string;
  department?: string;
  manager?: UserProps;
}
// interface MmmUser {
//   //   email: string;
//   //   userName: string;
//   //   locationCode?: string;
//   //   liked_stories?: string[];
//  }

export const AuthContext = React.createContext<UserProps | null>(null);
const AuthErrorComponent = () => <AuthErrorPageContent />;
const LoadingComponent = () => <Spinner size="xl" fullPage variant="primary" />;

interface MainContentProps {
  children: ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const msalResult = useMsal();
  const [meInfo, setMeInfo] = useState<UserProps | null>(null);
  const [authLoading, setAuthLoading] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchMe = async () => {
      setAuthLoading(msalResult.inProgress !== "none");

      if (msalResult?.accounts?.length) {
        try {
          const { id, mail, displayName, country, department } =
            await fetchMeInfo();
          const azureMeInfo = {
            azureId: id,
            mail,
            name: displayName,
            country,
            department,
          };
          const strapiId = await getOrAddPrimoId(azureMeInfo);
          const analysisAuthorization = await analysisAuthorisation(strapiId);
          setMeInfo({ ...azureMeInfo, strapiId, analysisAuthorization });
          // setMeInfo(me);
        } catch (error) {
          throw error;
        }
      } else {
        if (msalResult.inProgress === "none") {
          //There is a connection problem to Azure AD Application
          // setAuthError(true);
          console.error(
            "There seems to be a connection problem with the Azure AD application. Please check your internet connection and try again. If the issue persists, contact your system administrator or technical support."
          );
        }
        setMeInfo(null);
      }
    };
    fetchMe();
  }, [msalResult]);

  return authLoading || !meInfo ? (
    <LoadingComponent />
  ) : (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={AuthErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <AuthContext.Provider value={meInfo}>{children}</AuthContext.Provider>
    </MsalAuthenticationTemplate>
  );
};

interface AuthProviderProps {
  msalInstance: any; // You can replace 'any' with a more specific type if available
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({
  msalInstance,
  children,
}) => {
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent>{children}</MainContent>
    </MsalProvider>
  );
};

export default AuthProvider;
