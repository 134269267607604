import React, { useEffect, useState, useContext } from "react";
import { BasicButton } from "../../Button";
import { TextInput } from "../../TextInput";
import { TextArea } from "../../TextArea";
import { ISelectMenuOption } from "../../Select/interfaces";
import { Select } from "../../Select";
import { Switch } from "../../Switch";
import { RecipientScope } from "./../../../types";
import { getCategories } from "../../../api/categories";
import { getTemplates } from "../../../api/templates";
import { CheersPostcard } from "../../CheersPostcard";
import { PageWithImage } from "./../../CheersPageWithImage";
import { AuthContext } from "../../../auth/AuthProvider";
import {
  MAIN_COLOR,
  MAX_TITLE_LENGTH,
  MAX_MESSAGE_LENGTH,
} from "./../../../config";
import styles from "./stepsStyle.module.scss";

export type form2Props = {
  title: string;
  message: string;
  category?: ISelectMenuOption;
  template?: ISelectMenuOption; //We use this type to use the select component - but we can convert it to "CheersPostcardProps"
  publishToFeed: boolean;
};
export type form2SettersProps = {
  setTitle: (v: string) => void;
  setMessage: (v: string) => void;
  setCategory: (v?: ISelectMenuOption) => void;
  setTemplate: (v?: ISelectMenuOption) => void;
  setPublishToFeed: (v: boolean) => void;
};
interface SendStep4BodyProps extends form2Props, form2SettersProps {
  toGroup: boolean;
  recipientType: RecipientScope;
  goNext: () => void;
  goBack: () => void;
  closeMe: () => void;
}

export const SendStep4Body: React.FC<SendStep4BodyProps> = ({
  title,
  message,
  category,
  template,
  publishToFeed,
  //
  setTitle,
  setMessage,
  setCategory,
  setTemplate,
  setPublishToFeed,
  //
  toGroup,
  recipientType,
  goNext,
  goBack,
  closeMe,
}) => {
  const [categories, setCategories] = useState<ISelectMenuOption[]>([]);
  const [templates, setTemplates] = useState<ISelectMenuOption[]>([]);
  const [valideForm, setValideForm] = useState(false);
  const [longTitle, setLongTitle] = useState(false);
  const [longMessage, setLongMessage] = useState(false);

  const me = useContext(AuthContext);

  useEffect(() => {
    getCategories()
      .then((lst) => {
        setCategories(lst);
      })
      .catch((error) => {
        console.error(error);
      });
    getTemplates()
      .then((lst) => {
        setTemplates(lst);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setLongTitle(title?.length >= MAX_TITLE_LENGTH);
    setLongMessage(message?.length >= MAX_MESSAGE_LENGTH);
    if (title?.length > MAX_TITLE_LENGTH) {
      setTitle(title.substring(0, MAX_TITLE_LENGTH));
    }
    if (message?.length > MAX_MESSAGE_LENGTH) {
      setMessage(message.substring(0, MAX_MESSAGE_LENGTH));
    }

    const _valide =
      !!title?.length &&
      title.length <= MAX_TITLE_LENGTH &&
      !!category &&
      !!template &&
      !!message?.length &&
      message.length <= MAX_MESSAGE_LENGTH;

    setValideForm(_valide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, message, category, template]);

  const handleSend = () => {
    //TODO: verify all the information & if error => show errors
    goNext();
  };

  const handlCategoryChange = (lst: ISelectMenuOption[]) => {
    // onSelect={(lst) => setCategory(!lst?.length ? undefined : lst[0])}
    const _category = !lst?.length ? undefined : lst[0];
    setCategory(_category);
  };

  useEffect(() => {
    const _templateId = category?.data?.templateId;
    const _template = templates?.find((x) => x.value === _templateId);
    setTemplate(_template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, templates]);

  return (
    <PageWithImage iconName="ArrowLeft" onIconClick={goBack} helpButton>
      <div className={styles.formContainer}>
        <div className={styles.formCore}>
          <div className={styles.formTitle}>
            <div>
              {`${toGroup ? "Group" : "Individual"} `}
              {recipientType === "Mixed" && <b>Bacardi &</b>}
            </div>
            <div>
              <b>{`${recipientType === "Primo" ? "Bacardi" : "External"}`}</b>{" "}
              Cheers!
            </div>
          </div>
          <div className={styles.stepNumber}>Step 2</div>
          <b>What do you want to say?</b>

          <div>
            <TextInput
              size="xl"
              label="Title *"
              value={title}
              placeholder="Add a title..."
              onChange={(v) => setTitle(v)}
              alertState={longTitle ? "error" : undefined}
              helperMessage={
                longTitle
                  ? `Too long title! Must be under ${MAX_TITLE_LENGTH} characters.`
                  : undefined
              }
            />
          </div>
          <Select
            label="Category *"
            placeholder="Choose a category..."
            size="xl"
            options={categories}
            selected={!category ? [] : [category]}
            onSelect={handlCategoryChange}
          />
          <div>
            <TextArea
              label="Cheers message"
              placeholder="Add your cheers message..."
              value={message}
              onChange={(v) => setMessage(v)}
              maxHeight="200px"
              // minHeight="200px"
              // maxLength={MAX_MESSAGE_LENGTH}
              alertState={longMessage ? "error" : undefined}
              helperMessage={
                longMessage
                  ? `Too long title! Must be under ${MAX_MESSAGE_LENGTH} characters.`
                  : undefined
              }
            />
          </div>
          <Select
            label="Header Image *"
            placeholder="Choose an image..."
            size="xl"
            options={templates}
            selected={!template ? [] : [template]}
            onSelect={(lst) => {
              setTemplate(!lst?.length ? undefined : lst[0]);
            }}
          />
          {!!template && (
            <CheersPostcard
              template={template?.data}
              title={title}
              message={message}
              authorName={me?.name}
            />
          )}
          <div className={styles.flexH}>
            <Switch
              disabled={false}
              size="s"
              name="inputName"
              value={publishToFeed}
              onChange={(v) => setPublishToFeed(v)}
            />
            <div>Publish Cheers to feed?</div>
          </div>
        </div>
        <BasicButton
          onClick={handleSend}
          label="Send Cheers"
          variant="primary"
          size="xl"
          corners="squared"
          auto
          color={MAIN_COLOR}
          disabled={!valideForm}
        />
      </div>
    </PageWithImage>
  );
};
