import * as COLOR from "./../../color";

const Position = {
  Right: "right",
  Left: "left",
  Top: "top",
  Bottom: "bottom",
};

export const SS_OPEN_TRANSFORM = {
  [Position.Right]: {
    true: "translateX(0%)",
    false: "translateX(100%)",
  },
  [Position.Left]: {
    true: "translateX(0%)",
    false: "translateX(-100%)",
  },
  [Position.Top]: {
    true: "translateY(0%)",
    false: "translateY(-100%)",
  },
  [Position.Bottom]: {
    true: "translateY(0%)",
    false: "translateY(100%)",
  },
};
export const COMPLETE_FULLSCREEN = {
  [Position.Right]: `left:0;`,
  [Position.Left]: `right:0;`,
  [Position.Top]: `bottom:0;`,
  [Position.Bottom]: `top:0;`,
};
export const SS_SIZE = {
  [Position.Right]: `
    width: 320px;
    max-width: 83vw;
    `,
  [Position.Left]: `
    width: 320px;
    max-width: 83vw;
    `,
  [Position.Top]: `
    width: 100%;
    max-height: 83vh;`,
  [Position.Bottom]: `
    width: 100%;
    max-height: 83vh;`,
};
export const ROUNDED_BORDER = {
  [Position.Right]: `border-radius: 24px 0 0 24px;`,
  [Position.Left]: `border-radius: 0 24px 24px 0;`,
  [Position.Top]: `border-radius: 0 0 24px 24px;`,
  [Position.Bottom]: `border-radius: 24px 24px 0 0;`,
};
export const SS_MODAL_POSITION = {
  [Position.Right]: `
  top: 0;
  bottom: 0;
  right: 0;`,
  [Position.Left]: `
  top: 0;
  bottom: 0;
  left: 0;`,
  [Position.Top]: `
  top: 0;
  left: 0;
  right: 0;`,
  [Position.Bottom]: `
  bottom: 0;
  left: 0;
  right: 0;`,
};

const Types = {
  Dark: "dark",
  Light: "light",
};

export const SS_MODE_PROPERTIES = {
  [Types.Dark]: `
  background: ${COLOR.nightsky_650};
  color: ${COLOR.white};
  `,
  [Types.Light]: `background: ${COLOR.white};
  color: ${COLOR.carbon};`,
};
