export enum DateErrorType {
  Undefined = 99, //unknown : error with unrecognized type
  EarlierThanMin = 1, //the date should be later than a given min date
  LaterThanMax = 2, //the date should be earlier than a given max date
  FirstLaterThanSecond = 3, //When we have two dates and the first should be greater than the second
}

export const checkForMinMaxError = ({
  date,
  minDate = undefined,
  maxDate = undefined,
}: {
  date?: Date;
  minDate?: Date;
  maxDate?: Date;
}) => {
  if (date) {
    const _date = date.setHours(0, 0, 0, 0);
    if (minDate && _date < minDate.setHours(0, 0, 0, 0))
      return DateErrorType.EarlierThanMin;
    if (maxDate && _date > maxDate.setHours(0, 0, 0, 0))
      return DateErrorType.LaterThanMax;
  }
  //else
  return null;
};
export const checkForStartEndError = ({
  startDate = undefined,
  endDate = undefined,
}: {
  startDate?: Date;
  endDate?: Date;
}) => {
  if (
    startDate &&
    endDate &&
    startDate.setHours(0, 0, 0, 0) > endDate.setHours(0, 0, 0, 0)
  ) {
    return DateErrorType.FirstLaterThanSecond;
  }
  //else
  return null;
};

// Each application had to rely on the types of errors to edit its own messages.
// This method is more for testing reasons (to be shared between tests)
export const getErrorMessage = (error: DateErrorType) => {
  switch (error) {
    case DateErrorType.LaterThanMax:
      return "The date entered is later than the max date.";
    case DateErrorType.EarlierThanMin:
      return "The date entered is earlier than the min date.";
    case DateErrorType.FirstLaterThanSecond:
      return "The first date is later than the second.";
    case DateErrorType.Undefined:
      return "Invalid date.";
    default:
      return null;
  }
};
