//@ts-nocheck
import React from "react";
import {
  InputContainer,
  WithLabel,
  WithHelperMessage,
  WithInformation,
} from "./../utils/inputCommon";

import {
  ICommonInput,
  IWithInformation,
  IWithHelperMessage,
  IInputContainer,
  IWithLabel,
} from "./../utils/inputCommon/interfaces";

export interface ITextInput
  extends IWithLabel,
    IWithHelperMessage,
    IWithInformation,
    IInputContainer,
    ICommonInput {
  /**Fire on input change. */
  onChange?: (val: string) => void;
  /**The value of the input. */
  value?: string;
  /**The type of the input. */
  type?: "text" | "email" | "search" | "url";
  /**If true, the field will have the focus after loading. */
  autoFocus?: boolean;
  /**Fire on input click. */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /**Fire on input blur. */
  onBlur?: (event: React.FocusEvent<HTMLElement, Element>) => void;
}

export const TextInput = React.forwardRef<HTMLInputElement, ITextInput>(
  (
    {
      onChange,
      value = "",
      type = "text",
      autoFocus = false,
      name = null,
      //
      disabled = false, //InputContainer
      size = "l", //IWithLabel & InputContainer
      onClick,
      onBlur,
      //
      informationMessage = "", //IWithInformation
      informationPosition = "bottom", //IWithInformation
      //
      helperMessage = null, //IWithHelperMessage
      alertState = undefined, //IWithHelperMessage & IWithLabel & InputContainer
      label = undefined, //IWithLabel
      placeholder = undefined, //ItWithLabel
      rightSideNode = undefined, //InputContainer
      leftSideNode = undefined, //InputContainer
      stuckPlacement = undefined, //InputContainer
    },
    ref
  ) => {
    return (
      <WithHelperMessage alertState={alertState} helperMessage={helperMessage}>
        <WithInformation
          informationMessage={informationMessage}
          informationPosition={informationPosition}
        >
          <InputContainer
            alertState={alertState}
            size={size}
            disabled={disabled}
            rightSideNode={rightSideNode}
            leftSideNode={leftSideNode}
            stuckPlacement={stuckPlacement}
            onClick={onClick}
          >
            <WithLabel
              empty={!(value?.length > 0)}
              size={size}
              label={label}
              hasPlaceholder={placeholder?.length > 0}
              alertState={alertState}
            >
              <input
                ref={ref}
                type={type}
                autoFocus={autoFocus}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                placeholder={placeholder ?? label} //if no placeholder take the label if defined
                disabled={disabled}
                name={name}
              />
            </WithLabel>
          </InputContainer>
        </WithInformation>
      </WithHelperMessage>
    );
  }
);
