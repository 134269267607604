import React from "react";
import { BasicButton } from "../../Button";
import { PageWithImage } from "../../CheersPageWithImage";
import { MAIN_COLOR } from "./../../../config";
import styles from "./stepsStyle.module.scss";

interface CheersSentErrorProps {
  goBack: () => void;
  closeMe: () => void;
}

export const SentErrorOccured: React.FC<CheersSentErrorProps> = ({
  goBack,
  closeMe,
}) => {
  return (
    <PageWithImage iconName="ArrowLeft" onIconClick={goBack} helpButton>
      <div className={styles.formContainer}>
        <img
          src="/img/oops.jpeg"
          alt="Congrats!"
          className={`${styles.formCongratsHeaderImg} ${styles.round}`}
        />

        <div className={styles.formCore}>
          <div className={styles.formTitle}>Oops!</div>

          <div>
            We apologize for the inconvenience. It seems there was a glitch in
            our system. Please try submitting your data again. If the issue
            persists, please contact our support team.
          </div>
        </div>
        <BasicButton
          onClick={closeMe}
          label="Go back to My Cheers"
          variant="primary"
          size="xl"
          corners="squared"
          auto
          color={MAIN_COLOR}
        />
      </div>
    </PageWithImage>
  );
};
