import styled, { css } from "styled-components";
import * as C from "./checkbox.constants";
import * as COLOR from "./../color";
import * as CONST from "./../const";

// Define types for the props used in the styled components
interface WrapperProps {
  disabled?: boolean;
  $auto?: boolean;
  $inverted?: boolean;
  $size: keyof typeof C.CHECKBOX_INPUT_SIZE_PROPERTIES; // Adjust as needed
}

interface LabelProps {
  disabled?: boolean;
  size: keyof typeof C.LABEL_SIZE_PROPERTIES; // Adjust as needed
}

// Define the noselect CSS using InterpolationValue
export const noselect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

// Define the Wrapper styled component with type annotations
export const Wrapper = styled.label<WrapperProps>`
  align-items: flex-start;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  display: flex;
  flex-direction: row;
  width: 100%;
  ${(props) =>
    props.$auto &&
    `
    justify-content: space-between;
    `}
  input[type="checkbox"] {
    box-sizing: border-box;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
  }
  .checkbox__control {
    border: ${CONST.border_single_smoke};
    border-radius: 3px;
    box-sizing: border-box;
    color: ${COLOR.interaction};
    display: inline-block;
    position: relative;
    vertical-align: middle;
    opacity: ${(props) => props.disabled && "0.4"};
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    ${(props) => C.CHECKBOX_INPUT_SIZE_PROPERTIES[props.$size]};
  }
  .checkbox__control--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    ${(props) =>
      props.$auto &&
      `      
      justify-content: space-between;
      width: 100%;
    `}
  }

  .checkbox__input__checked.checkbox__control--wrapper
    .checkbox__control:after {
    background-color: ${COLOR.interaction};
    border-radius: 2px;
    box-sizing: border-box;
    content: "";
    display: block;
    position: absolute;
    ${(props) => C.CHECKBOX_CHECKED_SIZE_PROPERTIES[props.$size]};
  }
`;

// Define the Label styled component with type annotations
export const Label = styled.span<LabelProps>`
  ${noselect}
  color: ${COLOR.carbon};
  cursor: inherit;
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_regular};
  ${(props) => C.LABEL_SIZE_PROPERTIES[props.size]};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;
