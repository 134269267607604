import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasicButton } from "../components/Button";
import { Icon } from "../components/Icon";
import { MAIN_COLOR } from "./../config";
import styles from "./style.module.scss";

const Body1 = () => {
  return (
    <div>
      Use <span className={styles.redCheers}>Cheers!</span> to express your
      gratitude and appreciation to Prim@s and external partners in a very
      personal way.
    </div>
  );
};

const Body2 = () => {
  return (
    <div>
      Your <span className={styles.redCheers}>Cheers!</span> message is personal
      and can be related to Bacardi Culture and Values.
    </div>
  );
};

const Body3 = () => {
  return (
    <div>
      <span className={styles.redCheers}>Cheers!</span> postcards are emailed to
      the recipients, and you can copy their managers, other Prim@s and external
      partners.
    </div>
  );
};
interface DotsProps {
  stepsNbr: number;
  currentStep: number;
}
const Dots: React.FC<DotsProps> = ({ stepsNbr, currentStep }) => {
  return (
    <div className={styles.dotWrap}>
      {Array.from({ length: stepsNbr }, (_, index) => (
        <span
          className={styles.dot}
          key={index}
          style={{
            backgroundColor: index + 1 === currentStep ? "red" : "pink",
          }}
        />
      ))}
    </div>
  );
};
interface StartStepProps {
  title: string;
  subtitle?: string;
  body: ReactNode;
  stepsNbr: number;
  currentStep: number;
  onNext?: () => void;
  onPrev?: () => void;
}
const StartStep = ({
  title,
  subtitle,
  body,
  stepsNbr,
  currentStep,
  onNext,
  onPrev,
}: StartStepProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.pageWrap}>
      <div className={styles.pageHeader} style={{ justifyContent: "center" }}>
        <img
          className={styles.pageHeader}
          src="/img/header2.png"
          alt="Header"
        />
      </div>
      <div className={styles.startWrap}>
        <div className={styles.startPgTitleWrap}>
          <div className={styles.startPgTitle}>{title}</div>
          {subtitle && <div className={styles.startPgSubTitle}>{subtitle}</div>}
        </div>
        <div className={styles.startPgBody}>{body}</div>
        <div className={styles.startPgPrevNext}>
          {currentStep > 1 ? (
            <div
              className={styles.PrevNextButton}
              onClick={() => onPrev && onPrev()}
            >
              <Icon name="ChevronLeft" size={18} />
              <span>Prev</span>
            </div>
          ) : (
            <div className={styles.PrevNextButton} />
          )}
          <div className={styles.startPgDots}>
            <Dots stepsNbr={stepsNbr} currentStep={currentStep} />
          </div>
          {currentStep < stepsNbr ? (
            <div
              className={styles.PrevNextButton}
              onClick={() => onNext && onNext()}
            >
              <span>Next</span>
              <Icon name="ChevronRight" size={18} />
            </div>
          ) : (
            <div className={styles.PrevNextButton} />
          )}
        </div>
        <BasicButton
          onClick={() => navigate("/home", { replace: true })} //The use can not come back to this page with browser back buttom...
          label="Let's Start"
          variant="primary"
          size="xl"
          corners="squared"
          auto
          color={MAIN_COLOR}
        />
      </div>
    </div>
  );
};
export default function Start() {
  const [step, setStep] = useState<number>(1);
  return step === 1 ? (
    <StartStep
      title="Cheers!"
      subtitle="THE BACARDI APPRECIATION APP"
      body={<Body1 />}
      stepsNbr={3}
      currentStep={1}
      onNext={() => setStep(2)}
    />
  ) : step === 2 ? (
    <StartStep
      title="Choose Category"
      body={<Body2 />}
      stepsNbr={3}
      currentStep={2}
      onPrev={() => setStep(1)}
      onNext={() => setStep(3)}
    />
  ) : (
    //step === 3 ?
    <StartStep
      title="Send Postcard"
      body={<Body3 />}
      stepsNbr={3}
      currentStep={3}
      onPrev={() => setStep(2)}
    />
  );
}
