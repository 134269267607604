import * as CONST from "./../const";

export const Types = {
  xs: "xs",
  s: "s",
  m: "m",
  l: "l",
  xl: "xl",
} as const;

type TypesKeys = keyof typeof Types;

export const AVATAR_SIZE: Record<TypesKeys, string> = {
  xs: `height: ${CONST.height_xs}; width: ${CONST.height_xs};`,
  s: `height: ${CONST.height_s}; width: ${CONST.height_s};`,
  m: `height: ${CONST.height_m}; width: ${CONST.height_m};`,
  l: `height: ${CONST.height_l}; width: ${CONST.height_l};`,
  xl: `height: ${CONST.height_xl}; width: ${CONST.height_xl};`,
};

export const AVATAR_CONTAINER_SIZE: Record<TypesKeys, string> = {
  xs: `height: calc(${CONST.height_xs} + 3px); width: calc(${CONST.height_xs} + 3px);`,
  s: `height: calc(${CONST.height_s} + 3px); width: calc(${CONST.height_s} + 3px);`,
  m: `height: calc(${CONST.height_m} + 3px); width: calc(${CONST.height_m} + 3px);`,
  l: `height: calc(${CONST.height_l} + 3px); width: calc(${CONST.height_l} + 3px);`,
  xl: `height: calc(${CONST.height_xl} + 3px); width: calc(${CONST.height_xl} + 3px);`,
};

export const AVATAR_INITIALS_SIZE_PROPERTIES: Record<TypesKeys, string> = {
  xs: `font-size:12px;`,
  s: `font-size:14px;`,
  m: `font-size:16px;`,
  l: `font-size:18px;`,
  xl: `font-size:20px;`,
};
