import React from "react";
import * as ICONS from "./tsx";
import styled from "styled-components";

const IconContainer = styled.span<{ $size: number }>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.$size && `font-size: ${props.$size}px`};
`;

export interface IIcon {
  /** The name of the icon */
  name: string;
  /** The size of the icon in in number of pixels */
  size?: number;
  /** fire on icon click */
  onClick?: (event: MouseEvent) => void;
  /** A more flexible way to style the icon */
  style?: React.CSSProperties;
  /** A more flexible way to style the icon*/
  className?: string;
}

export const Icon = ({ name, size, onClick, style, className }: IIcon) => {
  //@ts-ignore
  const InnerIcon = ICONS[name];
  return !InnerIcon ? null : size ? (
    <IconContainer $size={size}>
      <InnerIcon onClick={onClick} style={style} className={className} />
    </IconContainer>
  ) : (
    <InnerIcon onClick={onClick} style={style} className={className} />
  );
};
