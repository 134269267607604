//@ts-nocheck
import React, { useImperativeHandle, useRef } from "react";
import { DateFormatInput } from "../dateFormatInput";
import {
  ICommonInputWrapper,
  CommonInputWrapper,
} from "./../../utils/inputCommon";
import IconCalendar from "./../../Icon/tsx/Calendar";
import { IconButton } from "./../../Button";
import { checkForMinMaxError } from "./../../utils/dateErrorsType";

export interface IDateInput extends ICommonInputWrapper {
  /** The date value */
  value: Date | null;
  /** Fires on input change. */
  onChange: (Date) => void;
  /** Fires on the element click. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** Fires when the element loses focus. */
  onBlur?: () => void;
  /** If true, an icon-button will appear on the right corner  */
  withPickerButton?: boolean;
  /** Fires on the picker-button click */
  onPickerClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /** The language culture name to consider */
  locale?: string;
  /** The minimum date */
  minDate?: Date | undefined;
  /** The maximum date */
  maxDate?: Date | undefined;
}

const RightSideIcon = ({ disabled, onClick }) => {
  return (
    <IconButton
      disabled={disabled}
      icon={<IconCalendar />}
      onClick={onClick}
      variant="tertiary"
      corners="rounded"
      noBorder
    />
  );
};

export const SingleDateInput = React.forwardRef(
  (
    {
      value,
      onChange,
      onClick,
      onBlur = undefined,
      withPickerButton = false,
      onPickerClick = undefined,
      locale = "en-US",
      minDate = undefined,
      maxDate = undefined,
      ...rest
    }: //
    IDateInput,
    ref
  ) => {
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      //...ref,
      focus: () => {
        inputRef.current.focus();
      },
      offsetWidth: inputRef.current.offsetWidth,
    }));

    const handleChange = (date) => {
      const errorType = checkForMinMaxError({ date, minDate, maxDate });
      onChange(date, errorType);
    };
    return (
      <CommonInputWrapper
        {...rest}
        onClick={onClick}
        hasPlaceholder={true}
        empty={!value}
        rightSideNode={
          withPickerButton && (
            <RightSideIcon disabled={rest.disabled} onClick={onPickerClick} />
          )
        }
      >
        <DateFormatInput
          ref={inputRef}
          value={value}
          locale={locale}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={rest.disabled}
        />
      </CommonInputWrapper>
    );
  }
);
