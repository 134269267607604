import * as COLOR from "./../color";
import * as CONST from "./../const";

// Define types for the different variants and sizes
export const Types = {
  XS: "xs",
  XXS: "xxs",
  Primary: "primary",
  PrimaryInverted: "primaryInverted",
  Important: "important",
} as const;

// Create a TypeScript type for Types
export type TypesKeys = keyof typeof Types;

export const CAPTION_SIZE_PROPERTIES: Record<
  typeof Types.XS | typeof Types.XXS,
  string
> = {
  [Types.XS]: `
    font-size: 14px;
    line-height: 16px;
    margin: ${CONST.spacing_xs} ${CONST.spacing_s};
  `,
  [Types.XXS]: `
    font-size: 10px;
    line-height: 10px;
    margin: ${CONST.spacing_xs};
  `,
};

export const CONTAINER_SIZE_PROPERTIES: Record<
  typeof Types.XS | typeof Types.XXS,
  string
> = {
  [Types.XS]: `min-width: 24.5px;`,
  [Types.XXS]: `min-width: 20px;`,
};

export const CAPTION_VARIANT_PROPERTIES: Record<
  typeof Types.Primary | typeof Types.PrimaryInverted | typeof Types.Important,
  string
> = {
  [Types.Primary]: `color: ${COLOR.white};`,
  [Types.PrimaryInverted]: `color: ${COLOR.caribe};`,
  [Types.Important]: `color: ${COLOR.white};`,
};

export const CONTAINER_VARIANT_PROPERTIES: Record<
  typeof Types.Primary | typeof Types.PrimaryInverted | typeof Types.Important,
  string
> = {
  [Types.Primary]: `background: ${COLOR.caribe};`,
  [Types.PrimaryInverted]: `background: ${COLOR.white};`,
  [Types.Important]: `background: ${COLOR.brand};`,
};
