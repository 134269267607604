export const TypographyVariant = {
  HERO: "hero",
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  SUBTITLE: "subtitle",
  BODY_LARGE: "body-large",
  BODY_MEDIUM: "body-medium",
  BODY_SMALL: "body-small",
  CAPTION: "caption",
  LABEL: "label",
} as const;

export type TypographyVariantType = typeof TypographyVariant[keyof typeof TypographyVariant];

export const TypographyFamily = {
  HEADING: "heading",
  BODY: "body",
  AKASANA: "akasana",
} as const;

export type TypographyFamilyType = typeof TypographyFamily[keyof typeof TypographyFamily];
