import * as COLOR from "../color";

// Semantic
export const border_single_danger = `0 0 0 1px ${COLOR.danger}`;
export const border_double_danger = `0 0 0 2px ${COLOR.danger}`;
//
export const border_single_success = `0 0 0 1px ${COLOR.caribe}`;
export const border_double_success = `0 0 0 2px ${COLOR.caribe}`;
//
export const border_single_warning = `0 0 0 1px ${COLOR.warning}`;
export const border_double_warning = `0 0 0 2px ${COLOR.warning}`;
//
export const border_single_information = `0 0 0 1px ${COLOR.information}`;
export const border_double_information = `0 0 0 2px ${COLOR.information}`;

// neutral
export const border_single_ashes = `1px solid ${COLOR.ashes}`;
export const border_double_ashes = `2px solid ${COLOR.ashes}`;
//
export const border_single_carbon = `1px solid ${COLOR.carbon}`;
export const border_double_carbon = `2px solid ${COLOR.carbon}`;
//
export const border_single_mercury = `1px solid ${COLOR.mercury}`;
export const border_double_mercury = `2px solid ${COLOR.mercury}`;
//
export const border_single_silver = `1px solid ${COLOR.silver}`;
export const border_double_silver = `1px solid ${COLOR.silver}`;
//
export const border_single_smoke = `1px solid ${COLOR.smoke}`;
export const border_double_smoke = `2px solid ${COLOR.smoke}`;
//
export const border_single_snow = `1px solid ${COLOR.snow}`;
export const border_double_snow = `2px solid ${COLOR.snow}`;
//
export const border_single_white = `1px solid ${COLOR.white}`;
export const border_double_white = `2px solid ${COLOR.white}`;

// primary
export const border_single_caribe = `1px solid ${COLOR.interaction}`;
export const border_double_caribe = `2px solid ${COLOR.interaction}`;

// secondary
export const border_single_bubbaloo = `1px solid ${COLOR.bubbaloo}`;
export const border_double_bubbaloo = `2px solid ${COLOR.bubbaloo}`;
//
export const border_single_daylight = `1px solid ${COLOR.daylight}`;
export const border_double_daylight = `2px solid ${COLOR.daylight}`;
//
export const border_single_grape = `1px solid ${COLOR.grape}`;
export const border_double_grape = `2px solid ${COLOR.grape}`;
//
export const border_single_mint = `1px solid ${COLOR.mint}`;
export const border_double_mint = `2px solid ${COLOR.mint}`;
//
export const border_single_nightsky = `1px solid ${COLOR.nightsky}`;
export const border_double_nightsky = `2px solid ${COLOR.nightsky}`;
//
export const border_single_yolk = `1px solid ${COLOR.yolk}`;
export const border_double_yolk = `2px solid ${COLOR.yolk}`;
//
export const border_single_peach = `1px solid ${COLOR.peach}`;
export const border_double_peach = `2px solid ${COLOR.peach}`;
//
export const border_single_pomelo = `1px solid ${COLOR.pomelo}`;
export const border_double_pomelo = `2px solid ${COLOR.pomelo}`;
