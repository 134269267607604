import styled, { css, createGlobalStyle } from "styled-components";
import { onHoverScrollBar } from "./../../utils/scrollBar.style";
import IconClose from "./../../Icon/tsx/RemoveCross";
import * as C from "./sideSheetModal.constants";
import * as COLOR from "./../../color";
import * as CONST from "./../../const";

export const Background = styled.div`
  background: ${COLOR.modal_background_dark};
  height: 100vh;
  width: 100%;
  z-index: ${CONST.zindex_side_sheet_backdrop};
  top: 0;
  right: 0;
  position: fixed;
  overflow: hidden;

  transition: all 0.35s ease-out;
  visibility: ${(props) => (props.$open ? "visible" : "hidden")};
  opacity: ${(props) => (props.$open ? "1" : "0")};
`;

//To avoid ouside scrolling when the side sheet is open
export const GlobalBodyStyle = createGlobalStyle`
    body {
        ${(props) => props.$open && "overflow: hidden;"}
    }
`;

export const CloseButton = styled(IconClose)`
  font-size: 32px;
  cursor: pointer;
  position: absolute;
  top: 8px;
  z-index: 1000;
  ${(props) => (props.$mdlPosition === "right" ? "left:8px;" : "right:8px;")}
`;

export const ModalSideSheetContainer = styled.div`
  position: fixed;
  ${(props) => C.SS_MODAL_POSITION[props.$position]}

  z-index: ${CONST.zindex_side_sheet};
  box-sizing: border-box;

  ${(props) => props.$open && `box-shadow: ${CONST.shadow_highlighted};`};
  ${(props) => C.SS_MODE_PROPERTIES[props.$mode]}

  ${(props) => C.SS_SIZE[props.$position]}
  
  //if mobile
  
    ${(props) =>
    props.$fullscreen &&
    `
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    ${C.COMPLETE_FULLSCREEN[props.$position]}
    `}
  

  transition: transform 0.35s ease-out;
  transform: ${(props) => C.SS_OPEN_TRANSFORM[props.$position][props.$open]};

  ${(props) => props.$rounded && C.ROUNDED_BORDER[props.$position]}

  ${(props) => props.$growSize && "height: 100%;"}

  ${(props) =>
    props.$scrollable &&
    css`
      overflow-y: auto;
      ${CONST.isNotMobile} {
        ${onHoverScrollBar}
      }
    `}
`;

export const ModalSideSheetContent = styled.div`
  height: 100%;
  outline: none;
  position: relative;
`;
