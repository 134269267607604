import React from "react";
//@ts-ignore
import * as S from "./inputContainer.style";
import { IInputContainer } from "./../interfaces";

export interface IWithChildren extends IInputContainer {
  children: React.ReactNode;
}

export const InputContainer = ({
  children,
  rightSideNode = undefined,
  leftSideNode = undefined,
  alertState = undefined,
  stuckPlacement = undefined,
  size = "l",
  rounded = false,
  noMaxHeight = false,
  textArea = false,
  minTextAreaHeight,
  maxTextAreaHeight,
  disabled = false,
  onClick,
  onBlur,
}: IWithChildren) => {
  return (
    <S.Container
      $size={size}
      $rounded={rounded}
      $error={alertState === "error"}
      disabled={disabled}
      $stuckPlacement={stuckPlacement}
      $noMaxHeight={noMaxHeight}
      $textArea={textArea}
      $minTextAreaHeight={minTextAreaHeight}
      $maxTextAreaHeight={maxTextAreaHeight}
      onClick={onClick}
      onBlur={onBlur}
    >
      {leftSideNode && <S.SideNode>{leftSideNode}</S.SideNode>}
      <S.Inner>{children}</S.Inner>
      {rightSideNode && <S.SideNode>{rightSideNode}</S.SideNode>}
    </S.Container>
  );
};
