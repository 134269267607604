import styled from "styled-components";

export const SideSheetAutoSuggestContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SideSheetMenuContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100%;
`;

export const SideSheetHeader = styled.div`
  padding: 16px 24px;
`;

export const ButtonsContainer = styled.div`
  padding: 16px 24px;
`;
