import styled, { css } from "styled-components";
import * as C from "./bit.constants";
import * as COLOR from "./../color";
import * as CONST from "./../const";

// Définir les types pour les props
interface BitContainerProps extends React.HTMLProps<HTMLDivElement> {
  $size: keyof typeof C.BIT_CONTAINER_SIZE_PROPERTIES;
  $optionSelected?: boolean;
  $menuIsOpen?: boolean;
  $multi?: boolean;
}

interface ChevronIconProps extends React.HTMLProps<HTMLDivElement> {
  $menuIsOpen?: boolean;
  $size?: keyof typeof C.IMG_SIZE;
}

interface BitTextProps extends React.HTMLProps<HTMLDivElement> {
  $size: C.SizeTypes;
}

interface ImgAsIconProps {
  $size: C.SizeTypes;
}

const noselect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export const BitContainer = styled.div<BitContainerProps>`
  ${noselect}
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  transition: all 0.2s ease-in-out;
  color: ${COLOR.carbon};
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  font-size: 14px;
  outline: none;
  ${(props) => C.BIT_CONTAINER_SIZE_PROPERTIES[props.$size]};

  ${(props) =>
    props.$optionSelected &&
    !props.$menuIsOpen &&
    `background: ${COLOR.interaction};`}

  // Default
  background: ${COLOR.white};

  // Empty and closed
  ${(props) =>
    (props.$multi || !props.$optionSelected) &&
    !props.$menuIsOpen &&
    `border: ${CONST.border_single_silver};`}

  // Default hovered
  &:hover {
    border: ${(props) =>
      !props.$menuIsOpen &&
      (props.$multi || !props.$optionSelected) &&
      CONST.border_single_ashes};
  }

  // menuIsOpened
  ${(props) => props.$menuIsOpen && `background: ${COLOR.snow};`}

  ${(props) =>
    props.$optionSelected &&
    !props.$multi &&
    !props.$menuIsOpen &&
    `background: ${COLOR.interaction};color:#fff;`}
`;

export const BitChoice = styled.div`
  display: flex;
  align-items: center;
`;

export const ChevronIcon = styled.div<ChevronIconProps>`
  transition: transform 0.3s ease-out;
  font-size: 0.7em;
  line-height: 0.7em;
  ${(props) => props.$menuIsOpen && `transform: rotate(180deg);`}
  padding: 8px;
`;

export const BitText = styled.div<BitTextProps>`
  ${(props) => C.BIT_TEXT_PADDING[props.$size]};
  white-space: pre;
`;

export const ImgAsIcon = styled.img<ImgAsIconProps>`
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
  height: ${(props) => C.IMG_SIZE[props.$size]};
  width: ${(props) => C.IMG_SIZE[props.$size]};
  margin-left: -4px;
`;

export const BtnIcon = styled.div`
  margin-left: 8px;
  display: flex;
`;
