import React, { useEffect, useRef, useState } from "react";
import { BasicButton } from "./../../../components/Button";
import { SummaryLine } from "./../SummaryLine";
import {
  AnalysisPointProps,
  AnalysisDataProps,
  FullFilterProps,
  WinnerProps,
  WinnerType,
  ChartLineProps,
} from "./../../../types";
import styles from "./styles.module.scss";
import globalStyles from "../style.module.scss";

export interface WinnersProps {
  data: WinnerProps[];
  type: WinnerType;
  charts: ChartLineProps[];
  onChartsChange: (v: ChartLineProps[]) => void;
}

const PAGE_SIZE = 10;

export const Winners: React.FC<WinnersProps> = ({
  data,
  type,
  charts = [],
  onChartsChange = (v) => {},
}) => {
  const [visibleItems, setVisibleItems] = useState(PAGE_SIZE);

  const handleShowMore = () => {
    setVisibleItems((prev) => prev + PAGE_SIZE); // Augmenter de 10 à chaque fois
  };
  const handleDownloadCSV = () => {
    const csvRows = [
      ["Rank", "Name", "Count"],
      ...data.map((item, i) => [i + 1, item.name, item.count]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvRows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const scrollRef = useRef<HTMLDivElement>(null);

  // //This will transform the vertical scrolling on horizental scrolling (when the mouse is on this zone)
  // useEffect(() => {
  //   const handleWheel = (event: WheelEvent) => {
  //     const div = scrollRef.current;

  //     if (div) {
  //       // If the mouse wheel scrolls vertically (deltaY)
  //       if (event.deltaY !== 0) {
  //         // Translate vertical scroll to horizontal scroll
  //         div.scrollLeft += event.deltaY;

  //         // Prevent vertical scrolling while maintaining horizontal scrolling
  //         event.preventDefault();
  //       }
  //     }
  //   };

  //   const div = scrollRef.current;
  //   if (div) {
  //     div.addEventListener("wheel", handleWheel);
  //   }

  //   // Cleanup the event when the component unmounts
  //   return () => {
  //     if (div) {
  //       div.removeEventListener("wheel", handleWheel);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    setVisibleItems(PAGE_SIZE);
  }, [type]);

  const cheersDates = (cheers?: AnalysisPointProps[]): Date[] => {
    return !cheers?.length ? [] : cheers.map((x) => x.date);
  };

  return (
    <div className={styles.winnersContainer}>
      <div className={globalStyles.sectionTopBar}>
        <div className={globalStyles.sectionTitle}>{type}</div>
      </div>
      <div ref={scrollRef} className={styles.winnerLinesContainer}>
        {data.slice(0, visibleItems).map((item, index) => (
          <SummaryLine
            key={index}
            rank={index + 1}
            label={item.name}
            count={item.count}
            dates={cheersDates(item?.cheers)}
            charts={charts}
            onChartsChange={onChartsChange}
            width={270}
          />
        ))}
      </div>

      <div className={styles.sectionActions}>
        {visibleItems < data.length && (
          <BasicButton
            onClick={handleShowMore} //The use can not come back to this page with browser back buttom...
            label="Show more"
            variant="secondary"
            size="s"
            corners="squared"
            // color={MAIN_COLOR}
          />
        )}
        {!!data?.length && (
          <div className={styles.winnersNumber}>
            {Math.min(visibleItems, data.length)} / {data?.length}
          </div>
        )}
        <BasicButton
          onClick={handleDownloadCSV} //The use can not come back to this page with browser back buttom...
          label="&#11015; CSV"
          variant="secondary"
          size="s"
          corners="squared"
          // color={MAIN_COLOR}
        />
      </div>
    </div>
  );
};
