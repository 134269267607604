import React, { useState } from "react";
import { LabelButton } from "./../Button";
import { Icon } from "./../Icon";
import { HelpSheet } from "./HelpSheet";

export const CheersHelpButton = () => {
  const [helpPageVisible, setHelpPageVisible] = useState<boolean>(false);

  return (
    <div style={{ position: "absolute", top: "32px", right: "32px" }}>
      <LabelButton
        icon={<Icon name="QuestionHelpCircle" size={18} />}
        label="Help"
        onClick={() => setHelpPageVisible(true)}
        variant="tertiary"
        size="m"
        corners="rounded"
      />
      {helpPageVisible && (
        <HelpSheet
          open={helpPageVisible}
          closeMe={() => setHelpPageVisible(false)}
        />
      )}
    </div>
  );
};
