import styled from "styled-components";
import { TransitionType } from "./interfaces";

const TOP_ANIMATION = (trans: string) => {
  switch (trans) {
    case "top-down":
      return "0%";
    case "down-top":
      return "100%";
    default:
      return "50%";
  }
};

// export const ModalContainer = styled.div`
export const ModalContainer = styled.div<{
  tabIndex: any;
  $transition: TransitionType;
}>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 95%; //usefull when mobile or narrow screen
  max-height: 95%; //usefull when mobile is turned for example
  overflow: auto;
  width: 500px;

  @keyframes rollout {
    0% {
      top: ${(props) => TOP_ANIMATION(props.$transition)};
    }
    100% {
      top: 50%;
    }
  }

  animation: rollout 0.3s;

  &:focus-visible {
    outline: none;
  }
`;

export const WithCloseBtn = styled.div`
  position: relative;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

/* @keyframes fadein {
    0% {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transform: scale(0.5);
      opacity: 0.2;
    }
    100% {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transform: scale(1);
      opacity: 1;
    }
  } */
// transition: all ease 3s;
// transition: all 0.2s ease-out;
