import React, { useEffect, useState } from "react";
import { Icon } from "./../Icon";
import { IDropElement, ISelectMenuOption } from "./interfaces";
//@ts-ignore
import * as B from "./selectButton.style";

type RefType = HTMLElement;
export const SelectButton = React.forwardRef<RefType, IDropElement>(
  (
    {
      label,
      multi,
      size = "l",
      selectedOptions,
      menuIsOpen,
      onClick,
      onKeyDown,
    },
    ref
  ) => {
    const [_label, setLabel] = useState(label);
    const [firstOption, setFirstOption] = useState<ISelectMenuOption>();

    useEffect(() => {
      setFirstOption(selectedOptions?.length ? selectedOptions[0] : undefined);
    }, [selectedOptions]);

    useEffect(() => {
      const getLabelFromSelected = () => {
        if (!selectedOptions?.length) return null;
        //else
        let label = selectedOptions[0].label;
        if (selectedOptions.length > 1) {
          label += ` + ${selectedOptions.length - 1}`;
        }
        return label;
      };

      const labelFromSelected = getLabelFromSelected();
      setLabel(labelFromSelected ?? label);
    }, [selectedOptions, label]);

    return (
      <B.BitContainer
        ref={ref as React.RefObject<HTMLDivElement>}
        tabIndex={-1}
        onClick={onClick}
        onKeyDown={onKeyDown}
        $size={size}
        $optionSelected={!!selectedOptions?.length}
        $menuIsOpen={menuIsOpen}
        $multi={multi}
      >
        <B.BitChoice>
          {firstOption?.imgSrc && (
            <B.ImgAsIcon
              $size={size}
              alt={firstOption.label}
              src={firstOption.imgSrc}
            />
          )}
          {!firstOption?.imgSrc && firstOption?.icon && (
            <B.BtnIcon>{firstOption?.icon}</B.BtnIcon>
          )}
          <B.BitText
            $size={size}
            // menuIsOpen={menuIsOpen}
            // optionSelected={selectedOptions?.length}
          >
            {_label}
          </B.BitText>
        </B.BitChoice>

        <B.ChevronIcon $menuIsOpen={menuIsOpen}>
          <Icon name="InterfaceArrowsButtonDown" />
        </B.ChevronIcon>
      </B.BitContainer>
    );
  }
);
