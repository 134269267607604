import styled from "styled-components";
import IconClose from "./../Icon/tsx/RemoveCross";
import IconArrowsDown from "./../Icon/tsx/InterfaceArrowsButtonDown";
import * as COLOR from "./../color";
import * as CONST from "./../const";

const MARGIN_PROPERTY = (shortMedia?: boolean): string => {
  return shortMedia ? "1.5em" : "3.5em";
};

export const Background = styled.div`
  background: ${COLOR.modal_background_dark};
  height: 100vh;
  width: 100%;
  z-index: 99;
  top: 0;
  right: 0;
  position: fixed;
  overflow: hidden;
`;

interface MenuContainerProps {
  menuOpen?: boolean;
}

export const MenuContainer = styled.div<MenuContainerProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  max-width: 83vw;
  background: ${COLOR.white};
  transition: transform 0.35s ease-out;
  ${(props) => props.menuOpen && `box-shadow: ${CONST.shadow_highlighted};`}
  transform: ${(props) =>
    props.menuOpen ? "translateX(0)" : "translateX(-100%)"};
  z-index: 100;
`;

interface MenuProps {
  $bottomRail?: boolean;
  $rail?: boolean;
}

export const Menu = styled.div<MenuProps>`
  box-sizing: border-box;
  padding: ${(props) =>
    props.$bottomRail ? "10px 24px" : props.$rail ? "24px 10px" : "24px"};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

interface MenuHeaderProps {
  $rail?: boolean;
  shortMedia?: boolean;
}

export const MenuHeader = styled.div<MenuHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$rail ? "center" : "space-between")};
  width: 100%;
  margin-bottom: ${(props) => MARGIN_PROPERTY(props.shortMedia)};
`;

interface LogoProps {
  $rail?: boolean;
}

export const Logo = styled.img<LogoProps>`
  height: ${(props) => (props.$rail ? "55px" : "160px")};
  width: ${(props) => (props.$rail ? "55px" : "160px")};
`;

export const LogoTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 0.5em;
`;

export const CloseButton = styled(IconClose)`
  font-size: 32px;
  cursor: pointer;
`;

interface ExpandRailButtonProps {
  $rail?: boolean;
}

export const ExpandRailButton = styled(IconArrowsDown)<ExpandRailButtonProps>`
  position: absolute;
  font-size: 9px;
  right: -17px;
  top: 36px;
  padding: 3px 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transform: ${(props) => (props.$rail ? "rotate(-90deg)" : "rotate(90deg)")};
`;

interface MenuBodyProps {
  $bottomRail?: boolean;
  $shortMedia?: boolean;
}

export const MenuBody = styled.div<MenuBodyProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${(props) => (props.$bottomRail ? "row" : "column")};
  flex-grow: 1;
  justify-content: ${(props) =>
    props.$shortMedia ? "space-around" : "flex-start"};

  & > div {
    margin-bottom: ${(props) =>
      props.$bottomRail ? "0" : MARGIN_PROPERTY(props.$shortMedia)};
  }
`;

export const Section = styled.div`
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  font-size: 14px;
  width: 100%;
`;

export const SectionLabel = styled.div`
  color: ${COLOR.ashes};
  margin: 0.5em 0;
  text-transform: uppercase;
  line-height: 1.14;
  letter-spacing: 1.8px;
`;

interface SectionOptionsProps {
  $bottomRail?: boolean;
}

export const SectionOptions = styled.div<SectionOptionsProps>`
  color: ${COLOR.carbon};
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.$bottomRail ? "row" : "column")};
  gap: 1.14em;
`;

export const ExternalLinks = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* gap: 1em; */
`;

interface ExternalLinkProps {
  $rail?: boolean;
  $primaryColor?: string;
}

export const ExternalLink = styled.a<ExternalLinkProps>`
  color: ${(props) =>
    props.$primaryColor
      ? COLOR.colorVariants(props.$primaryColor)
      : COLOR.interaction};
  display: flex;
  align-items: center;
  gap: 1em;
  ${(props) => props.$rail && "justify-content: center;"}
  cursor: pointer;
  box-sizing: border-box;
  background: transparent;
  padding: ${(props) => (props.$rail ? "0" : "0 1.15em")};
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  height: 40px;
  width: 100%;
  text-decoration: none;
`;

interface ExternalLinkIconProps {
  selected?: boolean;
  $primaryColor?: string;
}

export const ExternalLinkIcon = styled.div<ExternalLinkIconProps>`
  display: flex;
  align-items: center;
  font-size: 24px;
  ${(props) =>
    props.selected &&
    `color: ${
      props.$primaryColor
        ? COLOR.colorVariants(props.$primaryColor)
        : COLOR.interaction
    };`}
`;

export const ExternalLinkLabel = styled.div`
  display: flex;
  align-items: center;
`;

interface SectionOptionProps {
  $rail?: boolean;
  $bottomRail?: boolean;
  $primaryColor?: string;
  $stateOption?: boolean;
  selected?: boolean;
}

export const SectionOption = styled.div<SectionOptionProps>`
  display: flex;
  align-items: center;
  gap: 1em;
  ${(props) => props.$rail && "justify-content: center;"}
  padding: ${(props) => (props.$rail ? "0" : "0 1.15em")};
  width: 100%;
  ${(props) => props.$rail && !props.$bottomRail && "width:40px;"}

  ${(props) => props.$stateOption && "cursor: pointer;"}
  
  height: 40px;
  box-sizing: border-box;
  background: transparent;
  ${(props) => props.$stateOption && "opacity: 0.6;"}
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-radius: 4px;
  ${(props) =>
    props.selected &&
    props.$stateOption &&
    `background: ${
      props.$primaryColor
        ? COLOR.colorVariants(props.$primaryColor, "opacity8")
        : COLOR.interaction_opacity8
    }; opacity: 1;`}
  ${(props) =>
    props.selected &&
    !props.$rail &&
    `border-left-color: ${
      props.$primaryColor
        ? COLOR.colorVariants(props.$primaryColor)
        : COLOR.interaction
    };`}
  &:focus {
    outline: none;
  }
  &:hover {
    ${(props) =>
      props.$stateOption &&
      `background: 
      ${
        props.$primaryColor
          ? COLOR.colorVariants(props.$primaryColor, "opacity8")
          : COLOR.interaction_opacity8
      };`}
    opacity: 1;
  }
  ${CONST.isTablet} {
    &:hover {
      ${(props) =>
        props.$stateOption &&
        `background: ${
          props.$primaryColor
            ? COLOR.colorVariants(props.$primaryColor, "opacity8")
            : COLOR.interaction_opacity8
        };`}
    }
  }
  ${CONST.isLargeDesktop} {
    &:hover {
      ${(props) =>
        props.$stateOption &&
        `background: ${
          props.$primaryColor
            ? COLOR.colorVariants(props.$primaryColor, "opacity8")
            : COLOR.interaction_opacity8
        };`}
  }
`;

interface SectionOptionIconProps {
  selected?: boolean;
  $primaryColor?: string;
}

export const SectionOptionIcon = styled.div<SectionOptionIconProps>`
  display: flex;
  align-items: center;
  font-size: 24px;
  ${(props) =>
    props.selected &&
    `color: ${
      props.$primaryColor
        ? COLOR.colorVariants(props.$primaryColor)
        : COLOR.interaction
    };`}
  ${SectionOption}:hover & {
    color: ${(props) =>
      props.$primaryColor
        ? COLOR.colorVariants(props.$primaryColor)
        : COLOR.interaction};
  }
`;

export const SectionOptionLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuFooter = styled.div``;

interface ProfileAvatarProps {
  shortMedia?: boolean;
  $rail?: boolean;
}

export const ProfileAvatar = styled.div<ProfileAvatarProps>`
  width: 100%;
  margin-top: ${(props) => MARGIN_PROPERTY(props.shortMedia)};
  display: flex;
  ${(props) => props.$rail && "justify-content: center;"}
`;
