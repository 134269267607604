import React, { useState, useEffect } from "react";
import { SideSheetContainer, SideSheetModal } from "./../SideSheet";
import { useMediaCheck } from "./../utils/useMediaSizeCheck";
import { IDrawerContainer } from "./interfaces";
import { DrawerBody } from "./DrawerBody";

export const DrawerContainer = ({
  children,
  open = false,
  closeDrawer,
  largeScreenDesign = "standard",
  mediumScreenDesign = "modal",
  mobileScreenDesign = "modal",
  mobilePosition = "left",
  expandableRail = false,
  showLabelOnRail = false,
  scrollableContent = true,
  onOptionChange,
  ...rest
}: IDrawerContainer) => {
  const { mobile, tabletAndMore, laptopAndMore, largeDesktopAndMore } =
    useMediaCheck();

  const designToUse = largeDesktopAndMore
    ? largeScreenDesign
    : laptopAndMore
    ? "rail"
    : tabletAndMore
    ? mediumScreenDesign
    : mobileScreenDesign;

  const positionToUse = tabletAndMore ? "left" : mobilePosition;

  const [design, setDesign] = useState(designToUse); // "modal" | "standard" | "rail"
  const [position, setPosition] = useState(positionToUse); //"left" | "bottom"

  const toggleRailDesign = () => {
    if (design === "rail") setDesign("standard");
    else if (design === "standard") setDesign("rail");
  };

  useEffect(() => {
    setPosition(positionToUse);
  }, [positionToUse]);

  useEffect(() => {
    setDesign(designToUse);
  }, [designToUse]);

  const tryCloseDrower = () => {
    if (design === "modal") {
      doCloseDrower();
    }
  };
  const handleOptionChanged = (v: string) => {
    onOptionChange(v);
    tryCloseDrower();
  };
  const handleLinkClicked = () => {
    tryCloseDrower();
  };

  const doCloseDrower = () => {
    !!closeDrawer && closeDrawer();
  };

  return design === "modal" ? (
    <div>
      <div>{children}</div>
      <SideSheetModal
        open={open}
        closeMe={doCloseDrower}
        position={position}
        closeOnSwip={false}
      >
        <DrawerBody
          {...rest}
          menuOpen={open}
          mobile={mobile}
          hideProfile={!laptopAndMore}
          onClose={doCloseDrower}
          onOptionChange={handleOptionChanged}
          onLinkClick={handleLinkClicked}
        />
      </SideSheetModal>
    </div>
  ) : (
    <SideSheetContainer
      position={position}
      scrollableContent={scrollableContent}
      sideSheet={
        <DrawerBody
          {...rest}
          menuOpen={open}
          rail={design === "rail"}
          mobile={mobile}
          bottomRail={design === "rail" && position === "bottom"}
          expandableRail={expandableRail}
          onToggleRailDesing={toggleRailDesign}
          onOptionChange={handleOptionChanged}
          onLinkClick={handleLinkClicked}
        />
      }
      open={open}
      closeSideSheet={doCloseDrower}
      modalOnMobile={false} //disable the sidesheet self-decision about mobile design
      rail={design === "rail"}
    >
      {children}
    </SideSheetContainer>
  );
};
