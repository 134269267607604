import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgAlertCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12 0a12.24 12.24 0 0 0-8.53 3.65A11.85 11.85 0 0 0 0 12.21 11.78 11.78 0 0 0 11.8 24h.2a12.11 12.11 0 0 0 12-12.21A11.77 11.77 0 0 0 12 0m-1.5 16.54A1.48 1.48 0 0 1 12 15a1.53 1.53 0 0 1 1.52 1.47A1.47 1.47 0 0 1 12.05 18a1.53 1.53 0 0 1-1.55-1.46m.5-4v-6a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0" />
  </svg>
);
const Memo = memo(SvgAlertCircle);
export default Memo;
