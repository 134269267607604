import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgRemoveCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 32 32"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M17.533 16.118a.167.167 0 0 1 0-.236l6.174-6.175a1 1 0 0 0-1.415-1.413l-6.175 6.171a.167.167 0 0 1-.236 0L9.707 8.294a1 1 0 1 0-1.414 1.413l6.173 6.175a.167.167 0 0 1 0 .236l-6.173 6.175a1 1 0 0 0 1.414 1.414l6.174-6.175a.167.167 0 0 1 .236 0l6.175 6.175a1 1 0 1 0 1.414-1.414z" />
  </svg>
);
const Memo = memo(SvgRemoveCross);
export default Memo;
