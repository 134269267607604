import { loginRequest } from "./authConfig";
import { msalInstance } from "./../index";
import { ISelectMenuOption } from "./../components/Select/interfaces";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
const graphApiUrl = "https://graph.microsoft.com/v1.0";

export interface userProps extends ISelectMenuOption {
  // label: string;
  // value: string;
  // imgSrc?: string;
  email: string;
  country: string;
  department: string;
}

export interface IAzureUser {
  id: string;
  displayName: string;
  mail?: string;
  country?: string;
  department?: string;
  updatedDate: string;
}
interface IAzureADResponse {
  value: IAzureUser[];
}

export const fetchManagerByEmail = async (email: string) => {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
    const accessToken = tokenResponse.accessToken;

    const userResponse = await fetch(
      `${graphApiUrl}/users/${email}?$select=mail,displayName,manager&$expand=manager($select=id,mail,displayName)`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!userResponse.ok) {
      throw new Error(`HTTP error! status: ${userResponse.status}`);
    }

    const user = await userResponse.json();

    return user;
  } catch (error) {
    console.error("Error fetching manager details:", error);
    throw error;
  }
};
export const fetchManager = async (
  azureId: string
): Promise<ISelectMenuOption> => {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
    const accessToken = tokenResponse.accessToken;

    const userResponse = await fetch(
      `${graphApiUrl}/users/${azureId}?$id,manager&$expand=manager($select=id,mail,displayName,country,department,updatedDate)`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!userResponse.ok) {
      throw new Error(`HTTP error! status: ${userResponse.status}`);
    }

    const user = await userResponse.json();

    return {
      value: user.manager.id,
      label: user.manager.displayName,
      subLabel: user.manager.mail,
      data: {
        country: user.manager.country,
        department: user.manager.department,
      },
    };
  } catch (error) {
    console.error("Error fetching manager details:", error);
    throw error;
  }
};

export const fetchUsersByPrefix = async (
  prefix: string,
  limit: number = 10
): Promise<ISelectMenuOption[]> => {
  try {
    if (!prefix?.length) return [];

    // Obtain an access token
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read.All"],
    });
    const accessToken = tokenResponse.accessToken;

    // Convert the prefix to lowercase for case-insensitive search
    const lowercasedPrefix = prefix.trim().toLowerCase();
    const words = lowercasedPrefix.split(" ");

    // Limit the results if a limit is defined
    const limitQuery = limit ? `&$top=${limit}` : "";

    // Construct the filter query
    const filterEnableQuery = `accountEnabled eq true`;
    const hasCountry = `country ge '!'`;

    const encodedPrefix = encodeURIComponent(lowercasedPrefix);
    const prefixFilter =
      words.length === 1
        ? `(startswith(displayName,'${encodedPrefix}') or startswith(mail,'${encodedPrefix}'))`
        : `startswith(displayName,'${encodedPrefix}')`;

    const filters = `${filterEnableQuery} and ${hasCountry} and ${prefixFilter}`;
    const url = `${graphApiUrl}/users?$filter=${filters}&$select=id,mail,displayName,country,department${limitQuery}`;

    // Make the request with fetch
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        ConsistencyLevel: "eventual",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Extract the data and map the results
    const data = await response.json();
    const dbUsers = data.value.map((user: any) => ({
      id: user.id,
      email: user.mail,
      displayName: user.displayName,
      country: user.country,
      department: user.department,
    }));

    let users = dbUsers
      ? dbUsers.map(
          (x: {
            id: string;
            displayName: string;
            email: string;
            country: string;
            department: string;
          }) => ({
            value: x.id,
            label: x.displayName,
            subLabel: x.email,
            imgSrc: null,
            data: {
              country: x.country,
              department: x.department,
            },
          })
        )
      : [];

    users = await addPhotos(users); // Add user photos
    return users;
  } catch (error) {
    console.error("Error fetching users by prefix:", error);
    throw new Error("Failed to fetch users.");
  }
};

// In-memory cache for user photos
const photoCache: { [userId: string]: string } = {};

export const fetchUserPhoto = async (userId: string): Promise<string> => {
  // Check if the photo is already in the cache
  if (photoCache[userId]) {
    return photoCache[userId];
  }

  try {
    // Obtain an access token
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read.All"],
    });
    const accessToken = tokenResponse.accessToken;

    // Request to get the user's photo
    const photoResponse = await fetch(
      `${graphApiUrl}/users/${userId}/photo/$value?size=48`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!photoResponse.ok) {
      // If the request fails, use the default photo URL
      const defaultAvatarUrl = "/img/default-avatar.jpg";
      photoCache[userId] = defaultAvatarUrl;
      return defaultAvatarUrl;
    }

    // Create an object URL from the blob and store it in the cache
    const photoUrl = URL.createObjectURL(await photoResponse.blob());
    photoCache[userId] = photoUrl;
    return photoUrl;
  } catch (error) {
    console.error("Error fetching user photo:", error);
    // In case of an error, use the default photo URL
    const defaultAvatarUrl = "/img/unknown-avatar.jpg";
    photoCache[userId] = defaultAvatarUrl;
    return defaultAvatarUrl;
  }
};

export const fetchSetOfPhotos = async (ids: string[]) => {
  await Promise.all(ids.map((x) => fetchUserPhoto(x)));
};

export const getFetchedUser = (id: string) => {
  if (photoCache.hasOwnProperty(id)) {
    return photoCache[id];
  }
  return "/img/unknown-avatar.jpg";
};
const addPhotos = async (users: userProps[]) => {
  const updatedUsers = await Promise.all(
    users.map(async (user) => {
      let photoUrl = photoCache[user.value];
      if (!photoUrl) {
        photoUrl = await fetchUserPhoto(user.value);
        photoCache[user.value] = photoUrl;
      }
      return {
        ...user,
        imgSrc: photoUrl || "/img/default-avatar.jpg",
      };
    })
  );
  return updatedUsers;
};

export const fetchMeInfo = async (): Promise<IAzureUser> => {
  try {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      throw new Error("User not authentified...");
      //Logout
    }

    try {
      // Try acquiring token silently
      const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      const accessToken = tokenResponse.accessToken;

      const response = await fetch(
        `${graphApiUrl}/me?$select=id,mail,displayName,country,department,updatedDate`, //jobTitle
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        // if (response.status === 401 || response.status === 403) {
        //   // Redirect to re-authenticate if necessary
        //   await msalInstance.acquireTokenRedirect(loginRequest);
        // }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const infosUser = await response.json();
      return infosUser;
    } catch (tokenError) {
      // Handle MFA expiration or other interaction-required errors
      if (tokenError instanceof InteractionRequiredAuthError) {
        // Re-authenticate using redirect (no popup)
        await msalInstance.acquireTokenRedirect(loginRequest);
        //return fetchMeInfo();
        window.location.href = "/home"; // Redirection without return
        return Promise.reject("Re-authentication required, redirecting..."); // Return here to avoid TypeScript error
      } else {
        throw tokenError;
      }
    }
  } catch (error) {
    if (!navigator.onLine) {
      //TODO: ask to fix internet connexion
    }
    console.error("Error while retrieving user information:", error);
    throw error;
  }
};

function cleanDisplayName(str: string) {
  // Use a regular expression to remove everything from "(" to the end
  // return str.replace(/\(.*\)$/, "").trim();
  return str.replace(/'.*$/, "").trim();
}

export const fetchUserByDisplayName = async (
  displayName: string,
  country?: string,
  department?: string
): Promise<IAzureUser> => {
  try {
    // Obtain an access token
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read.All"],
    });
    const accessToken = tokenResponse.accessToken;

    // const encodedDisplayName = encodeURIComponent(displayName);

    // Initial request by displayName
    const response = await fetch(
      `${graphApiUrl}/users?$filter=startswith(displayName,'${cleanDisplayName(
        displayName
      )}')&$select=id,mail,displayName,country,department,updatedDate`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: IAzureADResponse = await response.json();
    let filteredUsers = data.value;

    // Filter by country and department if provided
    if (filteredUsers.length > 1 && (country || department)) {
      const filteredUsers2 = filteredUsers.filter((user) => {
        return (
          (!country || user.country === country) &&
          (!department || user.department === department)
        );
      });

      filteredUsers =
        filteredUsers2?.length > 0 ? filteredUsers2 : filteredUsers;
    }

    // If exactly one user is found after filtering, return it
    if (filteredUsers.length === 1) {
      return filteredUsers[0];
    } else if (filteredUsers.length > 1) {
      //if multiple take the last modified
      filteredUsers = filteredUsers.sort((a, b) => {
        const dateA = new Date(a.updatedDate);
        const dateB = new Date(b.updatedDate);
        return dateB.getTime() - dateA.getTime(); // Descending order
      });
      return filteredUsers[0];
      // throw new Error(
      //   "Multiple users found. Please refine your search criteria."
      // );
    } else {
      throw new Error("No user found with the provided displayName.");
    }
  } catch (error) {
    //console.error("Error while retrieving user:", error);
    throw error;
  }
};
