import * as CONST from "./../const";

// Définir les types pour les tailles
export type SizeTypes = "s" | "m" | "l" | "xl";

// Propriétés de taille pour le conteneur
export const BIT_CONTAINER_SIZE_PROPERTIES: Record<SizeTypes, string> = {
  s: `height: ${CONST.height_s};
  border-radius: calc(${CONST.height_s}/2);
  padding: 6px;
  `,
  m: `height: ${CONST.height_m};
  border-radius: calc(${CONST.height_m}/2);
  padding: 8px;
  `,
  l: `height: ${CONST.height_l};
  border-radius: calc(${CONST.height_l}/2);
  padding: 10px;
  `,
  xl: `height: ${CONST.height_xl};
  border-radius: calc(${CONST.height_xl}/2);
  padding: 12px;
  `,
};

// Padding du texte
export const BIT_TEXT_PADDING: Record<SizeTypes, string> = {
  s: "padding: 0 6px;",
  m: "padding: 0 8px;",
  l: "padding: 0 10px;",
  xl: "padding: 0 12px;",
};

// Taille des images
export const IMG_SIZE: Record<SizeTypes, string> = {
  s: `calc(${CONST.height_s} - 8px)`,
  m: `calc(${CONST.height_m} - 8px)`,
  l: `calc(${CONST.height_l} - 8px)`,
  xl: `calc(${CONST.height_xl} - 8px)`,
};
