import React from "react";
// @ts-ignore
import * as S from "./spinner.style";
import * as C from "./spinner.constants";

export interface ISpinner {
  /** THe size of the spinner. */
  size: "xxs" | "xs" | "s" | "m" | "l" | "xl";
  /** THe variant of the spinner. */
  variant: "carbon" | "inverted" | "primary";
  /** If true will be centred in its container */
  auto?: boolean;
  /**If true the heigh that will be considered is the full page height */
  fullPage?: boolean;
}

export const Spinner = ({
  size,
  variant,
  auto = true,
  fullPage = false,
}: ISpinner) => {
  const radius = C.RADIUS_SIZE_PROPERTIES[size];
  return (
    <S.Container $auto={auto} $fullPage={fullPage}>
      <S.Svg $radius={radius}>
        <S.Circle
          $size={size}
          strokeDasharray={2 * radius * Math.PI}
          strokeDashoffset={2 * radius * Math.PI * 0.66}
          cx={radius * 2}
          cy={radius * 2}
          r={radius}
          $radius={radius}
          $variant={variant}
        />
      </S.Svg>
    </S.Container>
  );
};
