import React from "react";
import { Icon } from "./../../../components/Icon";
import { IconButton } from "./../../../components/Button";
import {
  AnalysisPointProps,
  AnalysisDataProps,
  FullFilterProps,
  WinnerProps,
  WinnerType,
  ChartLineProps,
} from "./../../../types";
import { SummaryLine } from "./../SummaryLine";
import getColor from "./../colors";
import styles from "./styles.module.scss";
import globalStyles from "../style.module.scss";

export interface SummaryProps {
  data: AnalysisDataProps;
  filter: FullFilterProps;
  active: WinnerType;
  onActiveWinnerChange: (v: WinnerType) => void;
  charts: ChartLineProps[];
  onChartsChange: (v: ChartLineProps[]) => void;
}

export const Summary: React.FC<SummaryProps> = ({
  data,
  filter,
  active,
  onActiveWinnerChange = (v) => {},
  charts = [],
  onChartsChange = (v) => {},
}) => {
  const cheersDates = (cheers?: AnalysisPointProps[]): Date[] => {
    return !cheers?.length ? [] : cheers.map((x) => x.date);
  };

  return (
    <div className={styles.summaryContainer}>
      <div className={globalStyles.sectionTopBar}>Details</div>
      <div className={styles.summaryBody}>
        <div className={styles.summarySuperSection}>
          <div className={styles.summarySection}>
            <SummaryLine
              label="Cheers Sent"
              count={data?.cheers?.length ?? 0}
              active={active === WinnerType.AllCheers}
              setActive={() => onActiveWinnerChange(WinnerType.AllCheers)}
              dates={cheersDates(data?.cheers)}
              charts={charts}
              onChartsChange={onChartsChange}
            />

            <SummaryLine
              label="Sent @Primos"
              count={data?.cheersToPrimos?.length ?? 0}
              active={active === WinnerType.AtPrimos}
              setActive={() => onActiveWinnerChange(WinnerType.AtPrimos)}
              dates={cheersDates(data?.cheersToPrimos)}
              charts={charts}
              onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Sent @External"
              count={data?.cheersToExternals?.length ?? 0}
              active={active === WinnerType.AtExternal}
              setActive={() => onActiveWinnerChange(WinnerType.AtExternal)}
              dates={cheersDates(data?.cheersToExternals)}
              charts={charts}
              onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Sent @Mixed"
              count={data?.cheersToMixeds?.length ?? 0}
              active={active === WinnerType.AtMixed}
              setActive={() => onActiveWinnerChange(WinnerType.AtMixed)}
              dates={cheersDates(data?.cheersToMixeds)}
              charts={charts}
              onChartsChange={onChartsChange}
            />
          </div>
          <div className={styles.summarySection}>
            <SummaryLine
              label="Sent @Individual"
              count={data?.cheersToIndividual?.length ?? 0}
              active={active === WinnerType.AtIndividual}
              setActive={() => onActiveWinnerChange(WinnerType.AtIndividual)}
              dates={cheersDates(data?.cheersToIndividual)}
              charts={charts}
              onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Sent @Groupe"
              count={data?.cheersToGroup?.length ?? 0}
              active={active === WinnerType.AtGroup}
              setActive={() => onActiveWinnerChange(WinnerType.AtGroup)}
              dates={cheersDates(data?.cheersToGroup)}
              charts={charts}
              onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Sent Manager Included"
              count={data?.cheersManagerIncluded?.length ?? 0}
              active={active === WinnerType.ManagerIncluded}
              setActive={() => onActiveWinnerChange(WinnerType.ManagerIncluded)}
              dates={cheersDates(data?.cheersManagerIncluded)}
              charts={charts}
              onChartsChange={onChartsChange}
            />

            <SummaryLine
              label="Pushed To Feed"
              count={data?.cheersPushedToFeed?.length ?? 0}
              active={active === WinnerType.PushedToFeed}
              setActive={() => onActiveWinnerChange(WinnerType.PushedToFeed)}
              dates={cheersDates(data?.cheersPushedToFeed)}
              charts={charts}
              onChartsChange={onChartsChange}
            />
          </div>
        </div>

        <div className={styles.summarySuperSection}>
          <div className={styles.summarySection}>
            <SummaryLine
              label="Received by Primos"
              count={data?.primoRecipients?.length ?? 0}
              active={active === WinnerType.PrimosRecipients}
              setActive={() =>
                onActiveWinnerChange(WinnerType.PrimosRecipients)
              }
              dates={
                !data?.primoRecipients?.length
                  ? []
                  : data?.primoRecipients.map((x) => x.date)
              }
              charts={charts}
              onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Received by Externals"
              count={data?.externalRecipients?.length ?? 0}
              active={active === WinnerType.ExternalsRecipients}
              setActive={() =>
                onActiveWinnerChange(WinnerType.ExternalsRecipients)
              }
              dates={
                !data?.externalRecipients?.length
                  ? []
                  : data?.externalRecipients.map((x) => x.date)
              }
              charts={charts}
              onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Cheers Received"
              count={
                (data?.primoRecipients?.length ?? 0) +
                (data?.externalRecipients?.length ?? 0)
              }
              // active={active === WinnerType.AllRecipients}
              // setActive={() => onActiveWinnerChange(WinnerType.AllRecipients)}
              // dates={cheersDates(data?.cheersPushedToFeed)}
              // charts={charts}
              // onChartsChange={onChartsChange}
            />
            <SummaryLine
              label="Categories"
              count={data?.groupedByCategories?.length ?? 0}
              active={active === WinnerType.Categories}
              setActive={() => onActiveWinnerChange(WinnerType.Categories)}
            />
          </div>
          <div className={styles.summarySection}>
            <SummaryLine
              label="Source Countries"
              count={data?.groupedBySourceCountry?.length ?? 0}
              active={active === WinnerType.SourceCountries}
              setActive={() => onActiveWinnerChange(WinnerType.SourceCountries)}
            />
            <SummaryLine
              label="Source Departments"
              count={data?.groupedBySourceDepartment?.length ?? 0}
              active={active === WinnerType.SourceDepartments}
              setActive={() =>
                onActiveWinnerChange(WinnerType.SourceDepartments)
              }
            />

            <SummaryLine
              label="Recipients Countries"
              count={data?.groupedByTargetCountry?.length ?? 0}
              active={active === WinnerType.RecipientsCountries}
              setActive={() =>
                onActiveWinnerChange(WinnerType.RecipientsCountries)
              }
            />
            <SummaryLine
              label="Recipients Departments"
              count={data?.groupedByTargetDepartment?.length ?? 0}
              active={active === WinnerType.RecipientsDepartments}
              setActive={() =>
                onActiveWinnerChange(WinnerType.RecipientsDepartments)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
