import styled from "styled-components";

interface ContainerProps {
  $imgUrl?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 100vh;
  padding: 90px 24px 24px 24px;
  box-sizing: border-box;
  overflow-y: auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    max-width: 308px;
    height: 100%;

    background-image: url(${(props) =>
      props.$imgUrl ? props.$imgUrl : "/img/header1.png"});
    background-size: contain; /* pour que l'image soit contenue dans le div */
    background-repeat: no-repeat;
    background-position: top right;
    z-index: -1; /* pour que l'image soit derrière le contenu du div */
  }
  & > .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 0;
    padding: 16px;
  }
`;
