import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgInterfaceArrowsLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 10 10"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M9.25 4.25H2.561L3.78 3.03a.75.75 0 0 0-1.06-1.06l-2.5 2.5a.75.75 0 0 0 0 1.06l2.5 2.5a.75.75 0 1 0 1.06-1.06L2.561 5.75H9.25a.75.75 0 0 0 0-1.5" />
  </svg>
);
const Memo = memo(SvgInterfaceArrowsLeft);
export default Memo;
