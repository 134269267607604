//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { registerLocale } from "react-datepicker";
import { enUS } from "date-fns/locale";
//@ts-ignore
import * as S from "./../calendar.style";
import { localeDsc } from "../locales";
import { getYear, getMonth, format } from "date-fns";
import CalendarType from "../calendarType";
import { MAX_CALENDAR_NO_WRAP } from "../config";

export interface IDateSelector {
  /** The date value */
  value: Date | null;
  /** Fires on date change. */
  onChange: (Date) => void;
  /** The language culture name to consider */
  locale?: string;
  /** The minimum date */
  minDate?: Date;
  /** The maximum date */
  maxDate?: Date;
  /** Set of dates to excude - can not be selected */
  excludeDates?: Date[];
  /** The number of months shown at the same time */
  monthsShown?: number;
  /** To handle the starting page. If not given and selected is undefined, it will be today */
  openToDate?: Date;
  /** if true, the width of the component will be the same as its container */
  auto?: boolean;
}

export const DateSelector = ({
  value,
  onChange,
  //
  locale = "en-US",
  minDate,
  maxDate,
  excludeDates,
  monthsShown = 1,
  openToDate = value ?? new Date(),
  auto = false,
}: IDateSelector) => {
  const [localeShortName, setLocaleShortName] = useState<string>("en");
  const [localChange, setLocalChangeHandler] = useState(null);
  const [localeModule, setLocaleModule] = useState(null);
  const [calendarType, setCalendarType] = useState<CalendarType>(
    CalendarType.DAY
  );

  //////// SWIPING LEFT AND RIGHT ON MOBILE ////////
  const [increaseMonthFunc, setIncreaseMonthFunc] = useState(null);
  const [decreaseMonthFunc, setDecreaseMonthFunc] = useState(null);
  const swipeHandlers = useSwipeable({
    onSwipedLeft: increaseMonthFunc,
    onSwipedRight: decreaseMonthFunc,
    //
    onSwipedDown: decreaseMonthFunc,
    onSwipedUp: increaseMonthFunc,
  });
  ////////////////

  // //TODO: If we need to use it with multiple languages
  // useEffect(() => {
  //   const loadLocale = async (localName) => {
  //     const { name, fileName } = localeDsc(localName);
  //     const module = await import(`date-fns/locale/${fileName}`);
  //     registerLocale(name, module);
  //     setLocaleModule(module);
  //     setLocaleShortName(name);
  //   };
  //   loadLocale(locale);
  // }, [locale]);

  useEffect(() => {
    const loadLocale = async () => {
      registerLocale("en", enUS); // Register the locale
      setLocaleModule(enUS);
      setLocaleShortName("en");
    };

    loadLocale();
  }, []);

  /////////////////
  function CustomHeader({
    date,
    monthDate,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) {
    useEffect(() => {
      setIncreaseMonthFunc(() => increaseMonth);
      setDecreaseMonthFunc(() => decreaseMonth);
    }, []);

    return (
      <S.CalendarHeader>
        <S.PrevMonth
          id="prevMonthButton"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        />
        <S.HeaderButton
          onClick={() => {
            setCalendarType(CalendarType.MONTH);
            setLocalChangeHandler(() => changeMonth);
          }}
        >
          {format(monthDate, "LLLL", { locale: localeModule })}
        </S.HeaderButton>
        <S.HeaderButton
          onClick={() => {
            setCalendarType(CalendarType.YEAR);
            setLocalChangeHandler(() => changeYear);
          }}
        >
          {getYear(monthDate)}
        </S.HeaderButton>
        <S.NextMonth
          id="nextMonthButton"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        />
      </S.CalendarHeader>
    );
  }
  const handleChange = (date) => {
    if (calendarType === CalendarType.YEAR) {
      setCalendarType(CalendarType.DAY);
      localChange(getYear(date));
    } else if (calendarType === CalendarType.MONTH) {
      setCalendarType(CalendarType.DAY);
      localChange(getMonth(date));
    } else {
      onChange(date);
    }
  };

  const handleInputChangeRaw = (e) => {
    const date = e.target.value;
  };
  /////////////////

  return !localeShortName ? null : (
    <S.CalendarContainer
      {...swipeHandlers}
      $auto={auto}
      $wrap={monthsShown > MAX_CALENDAR_NO_WRAP}
    >
      <S.Calendar
        selected={value}
        onChange={handleChange}
        onChangeRaw={handleInputChangeRaw}
        //
        locale={localeShortName}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={excludeDates}
        openToDate={openToDate}
        monthsShown={calendarType === CalendarType.DAY ? monthsShown : 1}
        inline
        //
        showFourColumnMonthYearPicker={calendarType === CalendarType.MONTH} //if month picker
        showMonthYearPicker={calendarType === CalendarType.MONTH} //if month picker
        showYearPicker={calendarType === CalendarType.YEAR} //if year picker
        calendarStartDay={1}
        //
        renderCustomHeader={
          calendarType !== CalendarType.DAY
            ? undefined
            : (renderCustomHeaderProps) => (
                <CustomHeader {...renderCustomHeaderProps} />
              )
        }
      />
    </S.CalendarContainer>
  );
};
