import styled from "styled-components";
import * as C from "./typography.constants";
import * as CONST from "./../const";
import * as COLOR from "./../color";

type FontWeight = "normal" | "semibold" | "bold" | "bolder" | "inherit";

interface TypoContainerProps {
  $align?: string;
  $variant?: C.TypographyVariantType;
  $family?: C.TypographyFamilyType;
  $color?: string;
  $mode?: string;
  $weight?: FontWeight;
  $colored?: boolean;
}

const forcedFontWeight = (weight?: FontWeight): string => {
  switch (weight) {
    case "normal":
      return `font-weight:${CONST.weight_regular};`;
    case "semibold":
      return `font-weight:${CONST.weight_semi_bold};`;
    case "bold":
      return `font-weight:${CONST.weight_bold};`;
    case "bolder":
      return `font-weight:${CONST.weight_black};`;
    case "inherit":
      return `font-weight:inherit;`;
    default:
      return "";
  }
};

const fontFamily = (
  variant?: C.TypographyVariantType,
  family?: C.TypographyFamilyType
): string => {
  if (!variant) return "";

  let fmly = "";
  switch (variant) {
    case C.TypographyVariant.HERO:
    case C.TypographyVariant.H1:
    case C.TypographyVariant.H2:
      fmly = family === C.TypographyFamily.AKASANA ? "Aksana" : "Oswald";
      break;
    case C.TypographyVariant.H3:
    case C.TypographyVariant.H4:
    case C.TypographyVariant.H5:
    case C.TypographyVariant.H6:
      fmly =
        family === C.TypographyFamily.AKASANA
          ? "Aksana"
          : family === C.TypographyFamily.HEADING
          ? "Oswald"
          : "SourceSans";
      break;
    default:
      fmly = "SourceSans";
      break;
  }

  let result = `font-family:${fmly};`;
  if (fmly === "Oswald") {
    result += "text-transform: uppercase;";
  }
  return result;
};

const variantFontWeight = (
  variant?: C.TypographyVariantType,
  family?: C.TypographyFamilyType
): string => {
  switch (family) {
    case C.TypographyFamily.HEADING:
      return `font-weight:${CONST.weight_semi_bold}`;
    case C.TypographyFamily.AKASANA:
      return "font-weight:normal;";
    default:
      switch (variant) {
        case C.TypographyVariant.H3:
        case C.TypographyVariant.H4:
        case C.TypographyVariant.H5:
        case C.TypographyVariant.H6:
          return `font-weight:${CONST.weight_bold};`;
        case C.TypographyVariant.SUBTITLE:
          return `font-weight:${CONST.weight_semi_bold};`;
        case C.TypographyVariant.BODY_LARGE:
        case C.TypographyVariant.BODY_MEDIUM:
        case C.TypographyVariant.BODY_SMALL:
        case C.TypographyVariant.CAPTION:
        case C.TypographyVariant.LABEL:
          return `font-weight:${CONST.weight_regular};`;
        default:
          return "font-weight:inherit;";
      }
  }
};

const variantStyle = (
  variant?: C.TypographyVariantType,
  family?: C.TypographyFamilyType,
  color?: string,
  mode?: string
): string => {
  switch (variant) {
    case C.TypographyVariant.HERO:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 72px;
        line-height: 1.11;
        `;
    case C.TypographyVariant.H1:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 56px;
        line-height: 1.14;      
        `;
    case C.TypographyVariant.H2:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 40px;
        line-height: 1.2;
        `;
    case C.TypographyVariant.H3:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 32px;
        line-height: 1.25;  
        `;
    case C.TypographyVariant.H4:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 24px;
        line-height: 1.33;      
        `;
    case C.TypographyVariant.H5:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 20px;
        line-height: 1.4;      
        `;
    case C.TypographyVariant.H6:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 16px;
        line-height: 1.5;  
        `;
    case C.TypographyVariant.SUBTITLE:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.ashes};
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: 1.8px;
       `;
    case C.TypographyVariant.BODY_LARGE:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 18px;
        line-height: 1.44;  
        `;
    case C.TypographyVariant.BODY_MEDIUM:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 16px;
        line-height: 1.5;  
        `;
    case C.TypographyVariant.BODY_SMALL:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.carbon};
        font-size: 14px;
        line-height: 1.43;      
        `;
    case C.TypographyVariant.CAPTION:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.mercury};
        font-size: 12px;
        line-height: 1.33;      
        `;
    case C.TypographyVariant.LABEL:
      return `
        color: ${mode === "dark" ? COLOR.white : COLOR.mercury};
        font-size: 14px;
        line-height: 1.43;  
        `;
    default:
      return "";
  }
};

export const TypoContainer = styled.div<TypoContainerProps>`
  display: ${(props) => (props.$align ? "block" : "inline-block")};
  ${(props) => fontFamily(props.$variant, props.$family)}

  ${(props) =>
    variantStyle(props.$variant, props.$family, props.color, props.$mode)}

  ${(props) => props.$align && `text-align:${props.$align};`}

  ${(props) =>
    props.$variant && variantFontWeight(props.$variant, props.$family)}

  ${(props) => props.$weight && forcedFontWeight(props.$weight)}

  ${(props) => props.$colored && `color:${COLOR.caribe}`}
`;
