import styled from "styled-components";
import ReactLoading from "react-loading";
import * as CONST from "./../../const";
import * as COLOR from "./../../color";
import * as C from "./button.constants";
import { LinkOrButton } from "./LinkOrButton";

interface ButtonProps {
  $loading?: boolean;
  $variant?: "primary" | "secondary" | "tertiary";
  $corners?: "rounded" | "squared" | "default";
  disabled?: boolean;
  $iconbutton?: boolean;
  $auto?: boolean;
  $size?: "s" | "m" | "l" | "xl";
  $noBorder?: boolean;
  $transparent?: boolean;
  $success?: boolean;
  $color?: string;
}

const isPrimaryLoading = (props: ButtonProps) =>
  props.$loading && props.$variant === "primary";
const isSecondaryLoading = (props: ButtonProps) =>
  props.$loading && props.$variant === "secondary";
const isTertiaryLoading = (props: ButtonProps) =>
  props.$loading && props.$variant === "tertiary";

export const IconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
`;

export const StyledLinkOrButton = styled(LinkOrButton)<ButtonProps>`
  align-items: center;
  border: none;
  border-radius: ${(props) =>
    props.$corners === "rounded" ? CONST.radius_rounded : "4px"};
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  ${(props) =>
    props.disabled &&
    `opacity: 0.4;
     pointer-events: none;
    `}

  ${(props) =>
    props.$iconbutton ? C.WIDTH[props.$size!] : props.$auto && "width: 100%;"}

  ${(props) => C.SIZE_PROPERTIES[props.$size!]};
  ${(props) => !props.$iconbutton && C.PADDING[props.$size!]};
  ${(props) => C.VARIANT_PROPERTIES(props.$variant!, props.$color)};

  ${(props) => props.$noBorder && "border:none; &:hover{border:none;}"}
  ${(props) => props.$transparent && "background-color:transparent;}"}

  ${(props) =>
    isPrimaryLoading(props) &&
    `
background: ${
      props.$color ? COLOR.colorVariants(props.$color) : COLOR.interaction
    };
&:hover {
  background: ${
    props.$color
      ? COLOR.colorVariants(props.$color, 600)
      : COLOR.interaction_600
  };
    }`}
  ${(props) =>
    isSecondaryLoading(props) &&
    `
    background: ${
      props.$color ? COLOR.colorVariants(props.$color) : COLOR.interaction
    };
    &:hover {
      background: ${
        props.$color
          ? COLOR.colorVariants(props.$color, 600)
          : COLOR.interaction_600
      };
    }`}
    ${(props) =>
    isTertiaryLoading(props) &&
    `
    background-color: ${props.$transparent ? "transparent" : COLOR.silver};
    &:hover {
      background: ${COLOR.smoke};
    }`}
  :focus {
    outline: none;
  }
  span.ripple {
    animation: ripple 0.4s ease-in-out;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    position: absolute;
    transform: scale(0);
  }
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

export const CaptionWrapper = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: ${(props) => C.FONT_SIZE[props.$size!]};
  color: ${(props) => (props.$loading ? "transparent" : "inherit")};
  font-family: ${CONST.font_family_body};
  font-weight: ${CONST.weight_semi_bold};
  visibility: ${(props) => (props.$success ? "hidden" : "visible")};
  flex: 1;
  width: 100%;
  justify-content: center;
`;

export const Caption = styled.span`
  white-space: nowrap;
  width: 100%;
`;

export const Container = styled.div<ButtonProps>`
  ${(props) => props.$auto && "width: 100%;"}
  display: inline-flex;
  ${CONST.isTablet} {
    width: auto;
    ${(props) => props.$auto && "width: 100%;"}
  }
`;

export const Icon = styled(ReactLoading)<ButtonProps>`
  height: 24px !important;
  position: absolute;
  width: 24px !important;
  svg {
    height: 24px;
    width: 24px;
    ${(props) => C.ICON_VARIANT_PROPERTIES[props.$variant!]};
  }
`;

export const IconLineLong = styled.span`
  background-color: ${COLOR.white};
  border-radius: ${CONST.radius_px4};
  display: block;
  height: 2px;
  position: absolute;
  top: 25.3px;
  transform: rotate(-50deg);
  right: 5px;
  width: 15px;
  z-index: 10;
`;

export const IconLineTip = styled.span`
  background-color: ${COLOR.white};
  border-radius: ${CONST.radius_px4};
  display: block;
  height: 2px;
  left: 6.3px;
  position: absolute;
  top: 28px;
  transform: rotate(40deg);
  width: 8px;
  z-index: 10;
`;

export const SuccessIcon = styled.div`
  animation: icon-line-container 0.4s;
  height: 50px;
  position: absolute;
  width: 30px;
  @keyframes icon-line-container {
    0% {
      clip-path: polygon(0 17%, 19% 20%, 0 17%, 0 47%, 0 100%, 0% 50%);
    }
    25% {
      clip-path: polygon(0 17%, 19% 20%, 57% 100%, 43% 100%, 0 100%, 0% 50%);
    }
    50% {
      clip-path: polygon(0 17%, 19% 20%, 100% 100%, 100% 100%, 0 100%, 0% 50%);
    }
    75% {
      clip-path: polygon(0 0, 34% 0, 100% 50%, 100% 100%, 0 100%, 0% 50%);
    }
    100% {
      clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0% 50%);
    }
  }
`;
