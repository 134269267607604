//@ts-nocheck
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useSwipeable } from "react-swipeable";
import { useEscape } from "./../../UIProviders/EscapeContext";
import * as S from "./sideSheetModal.styles";
import * as I from "./interfaces";

export const SideSheetModal = ({
  children,
  position,
  open = false,
  closeMe,
  fullscreen = false,
  growSize = false,
  closeOnSwip = true,
  mode = "light",
  sidSheetInnerRef,
  withCloseButton = false,
  scrollableContent = true,
  roundedCorners = false,
}: I.SideSheetProps) => {
  const { register, unregister } = useEscape();
  const modalRef = useRef(null);

  const swipeHandlers = useSwipeable({
    // onSwiped: (eventData) => {
    //   console.log("User Swiped!", eventData);
    // },
    // onTouchStartOrOnMouseDown: (eventData) => {
    //   setSwiping(true);
    // },
    // onTouchEndOrOnMouseUp: (eventData) => {
    //   setSwiping(false);
    // },
    onSwipedLeft: (eventData) => {
      if (closeOnSwip && position === "left") {
        closeMe();
      }
    },
    onSwipedRight: (eventData) => {
      if (closeOnSwip && position === "right") {
        closeMe();
      }
    },
    onSwipedUp: (eventData) => {
      if (closeOnSwip && position === "top") {
        closeMe();
      }
    },
    onSwipedDown: (eventData) => {
      if (closeOnSwip && position === "bottom") {
        closeMe();
      }
    },
  });

  // This is very useful for handling the Escape key across the entire application.
  // Otherwise, if we have overlapping modals, pressing Escape would close them all at once.
  // Instead, we need to close them one by one, like a stack (pop from the top).
  useEffect(() => {
    const closeHandler = () => {
      closeMe();
    };
    open ? register(closeHandler) : unregister(closeHandler);
    return () => {
      unregister(closeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // useEffect(() => {
  //   const onKeyPressed = (e: any) => {
  //     if (e.key === "Escape") {
  //       e.stopPropagation();
  //       e.preventDefault();
  //       closeMe();
  //     }
  //   };
  //   if (open) {
  //     document.addEventListener("keydown", onKeyPressed, false);
  //   } else {
  //     document.removeEventListener("keydown", onKeyPressed, false);
  //   }
  //   return () => {
  //     document.removeEventListener("keydown", onKeyPressed, false);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);

  useEffect(() => {
    if (open) {
      if (sidSheetInnerRef?.current) {
        sidSheetInnerRef.current.focus();
      } else if (modalRef?.current) {
        modalRef.current.focus();
      }
    }
  }, [open, modalRef, sidSheetInnerRef]);

  const handleOverlayClick = (e: any) => {
    e.stopPropagation();
    closeMe();
  };

  return ReactDOM.createPortal(
    <div>
      <S.Background onClick={handleOverlayClick} $open={open} />
      <S.GlobalBodyStyle $open={open} />

      <S.ModalSideSheetContainer
        {...swipeHandlers}
        $position={position}
        $open={open}
        $mode={mode}
        $fullscreen={fullscreen}
        $growSize={growSize}
        $scrollable={scrollableContent}
        $rounded={roundedCorners}
      >
        <S.ModalSideSheetContent ref={modalRef} tabIndex={-1}>
          {withCloseButton && (
            <S.CloseButton onClick={closeMe} $mdlPosition={position} />
          )}
          {children}
        </S.ModalSideSheetContent>
      </S.ModalSideSheetContainer>
    </div>,
    document.body
  );
};
