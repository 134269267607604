import * as CONST from "../const";

const Size = {
  S: "s",
  M: "m",
  L: "l",
};

export const CONTAINER_SIZE_PROPERTIES = {
  [Size.S]: `
  height: ${CONST.height_s};
  padding: 1.5px;
`,
  [Size.M]: `
  height: ${CONST.height_m};
  padding: 2px;
`,
  [Size.L]: `
  height: ${CONST.height_l};
  padding: 3px;
`,
};
export const BUTTON_HEIGHT = {
  [Size.S]: `calc(${CONST.height_s} - 5px)`,
  [Size.M]: `calc(${CONST.height_m} - 6px)`,
  [Size.L]: `calc(${CONST.height_l} - 8px)`,
};
export const BUTTON_PADDING = {
  [Size.S]: "0 4px",
  [Size.M]: "0 8px",
  [Size.L]: "0 12px",
};
