export const formatDate = (date: Date): string => {
  //   const date = new Date(dateString);
  try {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  } catch (error) {
    throw error;
  }
};
