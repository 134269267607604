import React from "react";
import { Icon } from "../../Icon";
import { BasicButton } from "../../Button";
import { MAIN_COLOR } from "./../../../config";
import styles from "./stepsStyle.module.scss";

interface SendStep1BodyProps {
  toGroup: boolean;
  closeMe: () => void;
  goNext: () => void;
  onChange: (toGroup: boolean) => void;
}
export const SendStep1Body: React.FC<SendStep1BodyProps> = ({
  toGroup,
  closeMe,
  goNext,
  onChange,
}) => {
  return (
    <div className={styles.firstStepWrapper}>
      <div className={styles.closeIcon} onClick={closeMe}>
        <Icon name="Cross" size={32} />
      </div>
      <div className={styles.title}>
        <div>I want to</div>
        <div>
          send <b>Cheers!</b> to...
        </div>
      </div>
      <div className={styles.step1type}>
        <div
          onClick={() => onChange(false)}
          className={`${styles.imageSquare} ${styles.individualImg} ${
            toGroup === false ? styles.active : ""
          }`}
        ></div>
        <div
          onClick={() => onChange(true)}
          className={`${styles.imageSquare} ${styles.groupeImg} ${
            toGroup === true ? styles.active : ""
          }`}
        ></div>
      </div>
      <BasicButton
        onClick={goNext}
        label="Next"
        variant="primary"
        size="xl"
        corners="squared"
        auto
        color={MAIN_COLOR}
      />
    </div>
  );
};
