import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgQuestionHelpCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0m0 19a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 19m1.6-6.08a1 1 0 0 0-.6.92 1 1 0 0 1-2 0 3 3 0 0 1 1.8-2.75A2 2 0 1 0 10 9.25a1 1 0 0 1-2 0 4 4 0 1 1 5.6 3.67" />
  </svg>
);
const Memo = memo(SvgQuestionHelpCircle);
export default Memo;
